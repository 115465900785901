import React from 'react';
import ShareIconsComp from '../form/share';

const PortShareCom = (props) => {
    return (
        <>
        <ShareIconsComp
            title={props.type === 'own'
                    ? `🌟 Proud to share my PORTfolio! 🌟\n📂 Check it out: https://${window.location.hostname}/port/${props.data.user_name}\nDiscover my journey of achievements, experiences, and growth. Explore how PORT helps students stand out!`
                    : `🌟 📂Check out this PORTfolio:🌟\n https://${window.location.hostname}/port/${props.data.user_name} \nExplore how PORT helps students stand out!`
                }
            // \uD83C\uDF1F
            link={`${window.location.hostname}/port/${props.data.user_name}`}
            subject={`Check out my Portfolio: ${props.data.first_name} ${props.data.last_name ? props.data.last_name: ''}`}
            showAsItIs={true}
        />
        </>
    );
  }

export {
    PortShareCom
};