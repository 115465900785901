import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { PortShareCom } from '../../portfolio/share';
// import { LoaderMedium } from '../../loader';

function ModalSharePort(props) {
    return (
      <> 
        <Modal show={props.isShow||false} onHide={(e) => props.toggleModal(e, false)} size="md" className="mod-custom team-detail student-sec" backdrop="static" enforceFocus={false}>
            <Modal.Header closeButton>
                <Modal.Title>Share Portfolio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 col-12 col-sm-12">
                    {/* {props.loader
                        ?
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                        : */}
                        <div className="ms-2 me-2">
                            {/* <div className="card"> */}
                                <div className="row justify-content-center">
                                <div className={`col-md-8 col-12 col-sm-10`}>
                                    <div className="">
                                        {props.data.logo && (
                                            <div className="img-container">
                                                <img src={props.data.logo} alt={`${props.data.first_name} ${props.data.last_name}`} />
                                                <div className="share-overlay mod-share-overlay">
                                                <div className="share-content d-inline">
                                                    <h2 className="mb-1 text-white one-line">
                                                        <strong>{`${props.data.first_name} ${props.data.last_name || ''}`}</strong>
                                                    </h2>
                                                    {props.data.position && (<span className="position text-white one-line">{props.data.position}</span>)}
                                                </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="row mt-2 mb-3">
                                        <div className="col-md-12 col-12 col-sm-12 text-center">
                                            <p className="m-2 mb-3"><b>Share this Portfolio with your Social Community</b></p>
                                            <p className="mb-0 m-2">
                                                {/* Share via:&nbsp; */}
                                                <PortShareCom
                                                    data={props.data}
                                                    type={props.display_type}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="team-detail">
                <button className="btn btn-secondary" onClick={(e) => props.toggleModal(e, false)}>Close</button>
            </Modal.Footer>
        </Modal>
      </>
    );
}
  
export default ModalSharePort;