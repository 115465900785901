import React from 'react';
import { useSelector} from 'react-redux';
import moment from 'moment';
import { Footer } from '../footer';
import Header from '../header';
import Chatbot from '../askeva/chatbot';

function WrapMaster(props) {
    var userReduxData = useSelector(state => state.auth);
    userReduxData = (localStorage.getItem("token") && userReduxData && userReduxData.user_info) ? JSON.parse(userReduxData.user_info) : null;
    var userReduxValidity = useSelector(state => state.stateUpdate);
    userReduxValidity = userReduxValidity && userReduxValidity.validity_data ? JSON.parse(userReduxValidity.validity_data) : null;
    const windowPath = window.location.pathname.split('/')[1];

    return (
      <>
        {(typeof props.showHeader === "undefined" || props.showHeader === true) && (
            <Header />
        )}
        <div className="wrapper" style={{minHeight: '70vh'}}>
        {(typeof props.showLogo === "undefined" || props.showLogo === true) && (
        <section>
        <div className="container">
            <div className="row mt-4 mb-1">
            <div className="col-md-3 col-12 col-sm-12 text-center">
                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
            </div>
            <div className="col-md-9 col-12 col-sm-12 text-center">
                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/img-ifslogan-eng.png')} title="International Foundation for Sustainable Peace and Development" />
                {(typeof props.showLogin !== "undefined" && props.showLogin === true) && !(localStorage.getItem("token") && userReduxData) && (
                    <div className="btn-initiate mt-4 text-end">
                        <button className="btn btn-primary bg-color-blue btn-glow"><a href={`/login${props.url ? `redirect_uri=/${props.url}` : ''}`}>Login</a></button>
                    </div>
                )}
            </div>
            </div>
        </div>
        </section>)}
        {/* <div className="site grid-container container hfeed grid-parent" id="page"> */}
        {/* <div className="site grid-container hfeed grid-parent" id="page"> */}
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">
                        {(typeof props.showValidity !== "undefined"
                            && (windowPath === 'port' || windowPath === 'social-startup')
                            && props.showValidity
                            && localStorage.getItem("token")
                            && userReduxData
                            && userReduxValidity
                            && (userReduxValidity.port < 7 || userReduxValidity.ss < 7))
                            ?
                                (windowPath === 'port' && userReduxValidity.port < 7)
                                ?
                                    <div className={`mb-4 ${(typeof props.showLogo === "undefined" && props.showLogo === false) ? 'mt-60' : ''}`}>
                                        <div className={`alert alert-primary text-center`} role="alert">
                                            <p className="mb-1">Your Subscription is about to expire. Please renew it. <a href="/pay">Click Here</a></p>
                                            {(userReduxValidity.port_date || userReduxValidity.ss_date) && (
                                            <p className="mb-1">
                                                {userReduxValidity.port_date && (
                                                    <>Port: <span className="fw-bold">{moment(userReduxValidity.port_date).format("Do MMM YYYY")}</span></>
                                                )}
                                                {(userReduxValidity.port_date && userReduxValidity.ss_date) && (<span>&nbsp; | &nbsp;</span>)}
                                                {/* | */}
                                                {userReduxValidity.ss_date && (
                                                    <>Social Start-up: <span className="fw-bold">{moment(userReduxValidity.ss_date).format("Do MMM YYYY")}</span></>
                                                )}
                                            </p>
                                            )}
                                        </div>
                                    </div>
                                :   (windowPath === 'social-startup' && userReduxValidity.ss < 7)
                                    ?
                                        <div className={`mb-4 ${(typeof props.showLogo === "undefined" && props.showLogo === false) ? 'mt-60' : ''}`}>
                                            <div className={`alert alert-primary text-center`} role="alert">
                                                <p className="mb-1">Your Subscription is about to expire. Please renew it. <a href="/pay">Click Here</a></p>
                                                {(userReduxValidity.port_date || userReduxValidity.ss_date) && (
                                                <p className="mb-1">
                                                    {userReduxValidity.ss_date && (
                                                        <>Social Start-up: <span className="fw-bold">{moment(userReduxValidity.ss_date).format("Do MMM YYYY")}</span></>
                                                    )}
                                                    {(userReduxValidity.port_date && userReduxValidity.ss_date) && (<span>&nbsp; | &nbsp;</span>)}
                                                    {userReduxValidity.port_date && (
                                                        <>Port: <span className="fw-bold">{moment(userReduxValidity.port_date).format("Do MMM YYYY")}</span></>
                                                    )}
                                                </p>
                                                )}
                                            </div>
                                        </div> 
                                    :
                                    <></>
                            :
                            <></>
                        }
                            {/* <div className="mb-4">
                                <div className={`alert alert-primary text-center`} role="alert">
                                    <p className="mb-1">Your Subscription is about to expire. Please renew it. <a href="/pay">Click Here</a></p>
                                    {(userReduxValidity.port_date || userReduxValidity.ss_date) && (
                                    <p className="mb-1">
                                        {userReduxValidity.port_date && (
                                            <>Port: <span className="fw-bold">{moment(userReduxValidity.port_date).format("Do MMM YYYY")}</span></>
                                        )}
                                        |
                                        {userReduxValidity.ss_date && (
                                            <>Social Start-up: <span className="fw-bold">{moment(userReduxValidity.ss_date).format("Do MMM YYYY")}</span></>
                                        )}
                                    </p>
                                    )}
                                </div>
                            </div> */}
                        {/* <Navbar /> */}
                        {/* <div className="container"> */}
                            {props.children}
                        {/* </div> */}
                    </main>
			    </div>
		    </div>
	    </div>
        </div>
        {(typeof props.showFooter === "undefined" || props.showFooter === true) && (
            <Footer
                showPartner={props.showPartner}
                footerClass={props.footerClass||''}
                trimFooter={props.trimFooter||''}
            />
        )}
        {(typeof props.showBot === "undefined"
            || props.showBot !== false
        ) && (
        //  {userReduxData && 
        //     (userReduxData.user_name === 'jagannath-girisaballa'
        //     || userReduxData.user_name === 'akshay-panchavati'
        //     || userReduxData.user_name === 'zac-sangeeth'
        //     || userReduxData.user_name === 'anna-jacob'
        //     || userReduxData.user_name === 'test-change'
        //     || userReduxData.user_name === 'ritika-kriti-singh')
        //     && (
        //         typeof props.showBot === "undefined"
        //             || props.showBot !== false
                
        //     )
        //     && (
            <Chatbot
                isCustom={false}
            />
        )}
        {/* )} */}
      </>
    );
  }
  
  export default WrapMaster;