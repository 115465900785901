import React, {useState, useEffect } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import FormDataPortfolio from "../../data/formData/portfolio";
import {LoaderMedium} from './../../components/loader';
import {toggleBodyLoader} from './../../helper/custom-function';//copyClipBoard
import {CompletedSecUI} from './../../components/portfolio/common';
import WrapTooltip from "../../components/wrapper/tooltip";
import {CharacterCount} from "../../components/form/count";
import { yesNoOption, instituteType, instituteTypeObj } from "../../components/select/option";
import './../../assets/css/pages/team.css';
import './../../assets/css/pages/portfolio.css';
import {SetMetaData} from './../../helper/page-title';
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import { RSstandardStyle } from "../../config/react-select";
import {update_user_info} from './../../actions/action-creators/state-update';
import Chatbot from "../../components/askeva/chatbot";
// Modal
import ModalMaster from "../../components/modal/portfolio/master";
// import ModalHubInfo from "../../components/modal/portfolio/hub-info";
// import ShareIconsComp from "../../components/form/share";
import HubListScrollComp from "../../components/hub/list-scroll";
import { EvaPortButton } from "../../components/askeva/help-button";
import { PortPublicBtn } from "../../components/portfolio/button";
// const animatedComponents = makeAnimated();
const totalSections = 15;
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startYear = currentYear - 102;
const askEvaContext = {
    EduNew: "Education, Diplomas and Certifications Section",
    LifeExpNew: "Life Altering Experiences and Defining Moments Section",
    ExpNew: "Work Experience, Volunteering, Clubs, Organisations, Internships, Training and Passion/Personal Projects Section",
    ResearchNew: "Projects, Assignments, Papers, Essays, Researches, Stories, Poems, Videos, Podcasts Section",
    SportNew: "Sports, Games, Art, Design, Tech, Science, Humanities, and all other Competitions Section", 
    AwardNew: "Leadership, Honours and Awards Section",
    Achieve: "IFSPD Europe Global Sustainability Council Participation and Achievements Section",
    Headline: "Headline Section",
    Profile: "Profile Section",
    CareerSkills: "Career Skills Section",
    PersonalSkills: "Personal Skills Section"
};
const masterTitle = {
    // Edu
    EduNew: 'Add Education, Diplomas and Certifications',
    EduEdit: 'Edit Education/Diplomas',
    EduDoc: 'Certifications: Education/Diplomas',
    // Exp
    ExpNew: 'Add Work Experience, Volunteering, Clubs, Organisations...',
    ExpEdit: 'Edit Work Experience, Volunteering, Clubs, Organisations...',
    ExpDoc: 'Certifications: Work Experience, Volunteering, Clubs, Organisations...',
    // Research
    ResearchNew: 'Add Projects, Assignments, Papers, Essays, Researches...',
    ResearchEdit: 'Edit Projects, Assignments, Papers, Essays, Researches...',
    ResearchDoc: 'Certifications: Projects, Assignments, Papers, Essays, Researches...',
    //Sport
    SportNew: 'Add Sports, Games, Art, Design, Tech, Science, Humanities...',
    SportEdit: 'Edit Sports, Games, Art, Design, Tech, Science, Humanities...',
    SportDoc: 'Certifications: Sports, Games, Art, Design, Tech, Science, Humanities...',
    //Award
    AwardNew: 'Add Leadership, Honours and Awards',
    AwardEdit: 'Edit Leadership, Honours and Awards',
    AwardDoc: 'Certifications: Leadership, Honours and Awards',
    //Life Exp
    LifeExpNew: 'Add Life Altering Experiences and Defining Moments',
    LifeExpEdit: 'Edit Life Altering Experiences and Defining Moments',
    LifeExpDoc: 'Add photos and images, if you have taken any, from these life altering experiences.',
    //Social
    SocialNew: 'Add Social Start-up Projects',
    SocialEdit: 'Edit Social Start-up Projects',
    //Headline
    Headline: 'Headline',
    //Profile
    Profile: 'Short Profile',
    PersonalSkills: 'Personal Skills',
    CareerSkills: 'Career Skills',
    Achieve: "IFSPD Europe Global Sustainability Council Participation and Achievements",
    General: "General Details",
    School: "General Details",
};
const certLabelKeys = {
    EduDoc: 'program_name',
    ExpDoc: 'organization',
    SportDoc: 'name',
    AwardDoc: 'award_name',
    LifeExpDoc: 'experience',
    ResearchDoc: 'title'
};
// const commonFormData = {
//     LifeExpDoc: {
//         doc_type: 'image'
//     },
//     LifeExpEdit: {
//         doc_type: 'image'
//     }
// };
const commSecCertData = {
    cert: true,
    doc_type: 'all'
};
const {
    //General
    fieldEditGen,
    fieldValidationGen,
    fieldValuesGen,
    //School
    fieldEditSchool,
    fieldValidationShl,
    fieldValuesSchool,
    //Head
    fieldValidationHead,
    //Desc
    fieldValuesDesc,
    fieldValidationDesc,
    //Achieve
    // fieldAchieve,
    //Passion
    fieldPassion,
    //Edu
    fieldNewEdu,
    fieldValuesNewEdu,
    fieldValidationNewEdu,
    //Exp
    fieldValuesNewExp,
    fieldNewExp,
    fieldValidationNewExp,
    //Research
    fieldValuesNewResearch,
    fieldNewResearch,
    fieldValidationNewResearch,
    //Award
    fieldValuesNewAward,
    fieldNewAward,
    fieldValidationNewAward,
    //Sport
    fieldValuesNewSport,
    fieldNewSport,
    fieldValidationNewSport,
    //Life Exp
    fieldValuesNewLifeExp,
    fieldNewLifeExp,
    fieldValidationNewLifeExp,
    fieldValidationEditLifeExp,
    fieldEditLifeExp,
    //Social
    fieldValuesNewSocial,
    fieldNewSocial,
    fieldValidationNewSocial,
    //Recommend
    fieldValuesRecom,
    fieldRecom,
    fieldValidationRecom,
    docTypeIds,
    //Glosco
    fieldValidationGlosco,
    //Form Description
    formDescription,
    fieldNewHeadline,
    fieldNewProfile,
    fieldNewAchieve
} = FormDataPortfolio;
const initialGloscoData = {session_id: "", role_id: "", award_id: {id: 0, label: "No Award"}, is_team_award: "", team_award_id: "", rating: "", learning: "", year: "", cohort_number: ""};
const ratingOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const teamAwardOptions = [{id: 8, label: "Best Team"}, {id: 15, label: "Best Runner-up"}];
const initialContext = {
    currentContext: {
        // currentlyViewing: "General",
        currentlyViewing: "",
        form: {},
    }
};
const excludedTerm = ['other', 'others', 'na', 'none', 'not applicable', 'missing', 'othr'];

function PortfolioEditForm(){
    SetMetaData("PORT – Student Portfolio Management System", "", "");
    const cropperRef = React.createRef(null);
    // const accorLifeRef = useRef(null);
    const [pageLoading, setPageLoader] = useState(true);
    const [allApiData, setAllApiData] = useState(null);
    // const [hubLoading, setHubLoader] = useState(true);
    // const [hubInfoLoading, setHubInfoLoader] = useState(true);
    // const [passionData, setPassionData] = useState([]);
    const [completedSection, setCompletedSec] = useState([1]);
    // const [referralCode, setReferralCode] = useState('');
    const [careerSkillData, setCareerSkillData] = useState([]);
    const [personalSkillData, setPersonalSkillData] = useState([]);
    // const [countryData, setCountryData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [classData, setClassData] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [themeData, setThemeData] = useState([]);
    const [emotionData, setEmotionData] = useState([]);
    const [competeData, setCompeteData] = useState([]);
    //Classify Data
    const [classifyDataEdu, setClassifyEdu] = useState({classify: [], subject: [], rank: []});
    const [classifyDataExp, setClassifyExp] = useState({classify: [], work_type: [], company_type: []});
    const [classifyDataResearch, setClassifyResearch] = useState({classify: [], work_type: [], subject: [], com_level: [], rank: []});
    const [classifyDataAward, setClassifyAward] = useState({classify: [], domain: [], com_level: [], rank: []});
    const [classifyDataSport, setClassifySport] = useState({classify: [], work_type: [], domain: [], com_level: [], rank: []});
    const [sportDomainData, setSportDomain] = useState([]);
    // const [indiAwardData, setIndiAwardData] = useState([]);
    // const [teamAwardData, setTeamAwardData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [cohortData, setCohortData] = useState([]);
    const [userUrl, setUserUrl] = useState("");
    const [modalToggle, setModalToggle] = useState({Master: false,
                                                    passion: false,
                                                    glosco: false,
                                                    hub_info: false
                                                });
    const [masterFieldData, setMasterFieldData] = useState([]);
    const [activeEditForm, setActiveForm] = useState("");
    const [modalSecShow, setModalSection] = useState({form: true, cert: false, doc_type: 'all'});
    // const [toggleAccor, SetToggleAccor] = useState({lifeExp: 0, socialSS: 0});
    // Images: Preview
    const [previewDocData, setPreviewDoc] = useState([]);
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [croppedImg, setCroppedImg] = useState(null);
    const [previewPicBlob, setPreviewPicBlob] = useState(null);
    const [docUrlData, setDocUrl] = useState(`https://ifspd.s3.ap-south-1.amazonaws.com${ process.env.REACT_APP_ENV === 'local' ? '/dev' : ''}/uploads/portfolio/`);
    //Forms
    const [formDataGeneral, setFormDataGeneral] = useState({...fieldValuesGen});
    const [formDataSchool, setFormDataSchool] = useState({...fieldValuesSchool});
    const [formDataHeadline, setFormDataHeadline] = useState('');
    const [formDataDesc, setFormDataDesc] = useState({...fieldValuesDesc});
    const [formDataUnique, setFormDataUnique] = useState(fieldPassion);
    const [formDataPassion, setFormDataPassion] = useState({email: ''});                  
    const [formDataEdu, setFormDataEdu] = useState([]);
    // const [formDataAchieve, setFormDataAchieve] = useState(fieldAchieve);
    const [formDataAchieve, setFormDataAchieve] = useState(initialGloscoData);
    const [formDataExp, setFormDataExp] = useState([]);
    const [formDataResearch, setFormDataResearch] = useState([]);
    const [formDataLifeExp, setFormDataLifeExp] = useState([]);
    const [formDataSport, setFormDataSport] = useState([]);
    const [formDataAward, setFormDataAward] = useState([]);
    const [formDataCareer, setFormDataCareer] = useState([]);
    const [formDataPersonal, setFormDataPersonal] = useState([]);
    const [passionFetchData, setPassionFetchData] = useState([]);
    const [formDataRecom, setFormDataRecom] = useState({...fieldValuesRecom});
    const [recomFetchData, setRecomFetchData] = useState([]);
    const [formDataSocial, setFormDataSocial] = useState([]);
    const [formDataEnroll, setFormDataEnroll] = useState([]);
    const [rankDocData, setRankDocData] = useState([]);
    const [serviceAccessData, setAccessData] = useState([]);
    //Glosco
    const [sessionOptions, setSessionOption] = useState([]);
    const [roleOptions, setRoleOption] = useState([]);
    const [awardOptions, setAwardOption] = useState([]);
    //Data
    const [gloscoUserData, setGloscoData] = useState([]);
    //Hub
    // const [hubMasterList, setHubMasterData] = useState([]);
    // const [hubModalDetails, setHubModalData] = useState({});
    // const [hubInfoDetails, setHubInfoData] = useState({});
    // const [formDataOption, setFormDataOption] = useState({});
    // Edit Form Data
    const [editFormData, setEditFormData] = useState({});
    const [errorData, setErrorData] = useState({Master: [],
                                                // General: [],
                                                Headline: [],
                                                Pic: [],
                                                Desc: [],
                                                Unique: [],
                                                // Edu: [],
                                                // Exp: [],
                                                Achieve: [],
                                                // Research: [],
                                                // Sport: [],
                                                // Award: [],
                                                Career: [],
                                                Personal: [],
                                                Recom: [],
                                                UpdateDoc: []
                                                // Passion: []
                                            });
    const [isProcess, setIsProcess] = useState({Master: false,
                                                // General: false,
                                                Headline: false,
                                                Pic: false,
                                                Desc: false,
                                                Unique: false,
                                                // Edu: false,
                                                // Exp: false,
                                                Achieve: false,
                                                // Research: false,
                                                // Sport: false,
                                                // Award: false,
                                                Career: false,
                                                Personal: false,
                                                Recom: false,
                                                UpdateDoc: false
                                            });
    const [isBtnDisabled, setBtnDisabled] = useState({Master: true,
                                                // General: true,
                                                Headline: true,
                                                Pic: true,
                                                Desc: true,
                                                Unique: true,
                                                // Edu: true,
                                                // Exp: true,
                                                Achieve: true,
                                                // Research: true,
                                                // Sport: true,
                                                // Award: true,
                                                Career: true,
                                                Personal: true,
                                                Recom: true,
                                                UpdateDoc: true
                                            });
    // AskEVA Payload
    const [isEvaProcess, setEvaProcess] = useState(false);
    const [askevaPayload, setEvaPayload] = useState({userInformation: {},
                                                    context: initialContext,
                                                    call_direct_api: 0});
    // const [isFirstEdit, setIsFirstEdit] = useState(false);
    const dispatch = useDispatch();
    // Get Year List
    const yearDataList = (type) => {
        const yearItems = [];
        for (let i = currentYear; i >= startYear; i--) {
            yearItems.push({id: i, year: i});
        }
        setYearData(yearItems);
    };
    //Get Data from APIs
    const fetchSelectList = async(type, url, show_loader=false) => {
        try{
            if(show_loader){
                toggleBodyLoader('load');
            }
            const selectApiData = await get_api_call(url, {});
            var data = [];
            if(selectApiData.status === 200){
                data = selectApiData.data;
                switch (type) {
                    case 'edu_classify':
                        setClassifyEdu({classify: [...data.classify, {id: 0, label: 'Other'}],
                                    subject: [...data.subject, {id: 0, label: 'Other'}],
                                    rank: [{id: -1, label: 'None'}, ...data.rank]});
                        break;
                    case 'exp_classify':
                        setClassifyExp({classify: [...data.classify, {id: 0, label: 'Other'}],
                                        work_type: data.work_type,
                                        company_type: [...data.company_type, {id: 0, label: 'Other'}]});
                        break;
                    case 'research_classify':
                        setClassifyResearch({classify: [...data.classify, {id: 0, label: 'Other'}],
                                            work_type: data.work_type,
                                            subject: [...data.subject, {id: 0, label: 'Other'}],
                                            com_level: data.com_level,
                                            rank: [{id: -1, label: 'None'}, ...data.rank]});
                        break;
                    case 'award_classify':
                        setClassifyAward({classify: data.classify,
                                        domain: [...data.domain, {id: 0, label: 'Other'}],
                                        com_level: data.com_level,
                                        rank: [{id: -1, label: 'None'}, ...data.rank]});
                        break;
                    case 'sport_classify':
                        setClassifySport({classify: [...data.classify, {id: 0, label: 'Other'}],
                                        work_type: data.work_type,
                                        domain: data.domain,
                                        com_level: data.com_level,
                                        rank: [{id: -1, label: 'None'}, ...data.rank]});
                        break;
                    // case 'passion':
                    //     setPassionData(selectApiData.data);
                    //     break;
                    case 'career_skill':
                        setCareerSkillData(selectApiData.data);
                        break;
                    case 'personal_skill':
                        setPersonalSkillData(selectApiData.data);
                        break;
                    // case 'indi_award':
                    //     setIndiAwardData(selectApiData.data);
                    //     break;
                    // case 'team_award':
                    //     setTeamAwardData(selectApiData.data);
                    //     break;
                    // case 'school':
                    //     setSchoolData(selectApiData.data);
                    //     break;
                    case 'themes':
                        setThemeData(data);
                        break;
                    case 'emotions':
                        setEmotionData(data);
                        break;
                    // case 'cohort':
                    //     setCohortData(selectApiData.data);
                    //     break;
                    case 'competitions':
                        setCompeteData(data);
                        break;
                    case 'school':
                        setSchoolData([...data, {id: 0, school_name: "Other"}]);
                        break;
                    case 'branch':
                        setBranchData([{id: -1, branch_name: "N/A (Not Applicable)"}, ...data, {id: 0, branch_name: "Other"}]);
                        break;
                    case 'classes':
                        setClassData([...data]);
                        setCourseData([...selectApiData.course_data, {id: 0, class_name: "Other"}]);
                        break;
                    default: 
                }
            }
            if(show_loader){
                toggleBodyLoader('remove');
            }
            return {
                data
            };
        }catch(e){
			error_api_call({error: `portfolio|edit|fetchSelectList|${e}`});
            return {data: []};
        }
    }
    //Get Data for Country
    // const fetchCountryList = async() => {
    //     try{
	// 		const countryApiData = await get_api_call('countries', {});
	// 		if(countryApiData.status === 200){
	// 			setCountryData(countryApiData.data);
	// 		}
    //     }catch(e){
	// 		error_api_call({error: `portfolio|edit|fetchCountryList|${e}`});
    //     }
    // }
    //Get Data for Hub
    // const fetchHubList = async() => {
    //     try{
	// 		const hubApiData = await post_api_call('opportunities', {});
	// 		if(hubApiData.status === 200){
	// 			setHubMasterData(hubApiData.data);
	// 		}
    //         setHubLoader(false);
    //     }catch(e){
	// 		error_api_call({error: `portfolio|edit|fetchHubList|${e}`});
    //     }
    // }
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        // setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        setCroppedImg(cropper.getCroppedCanvas({
            fillColor: '#fff',
          }).toDataURL('image/jpeg'));
        cropper.getCroppedCanvas({
            fillColor: '#fff',
          }).toBlob((blob) => {
            // console.log(blob, 'blobbb');
            setPreviewPicBlob(blob);
        }, 'image/jpeg');
    };
    //Modify Input
    const updateInpState = (identifier_key, data) => {
        try{
            if(
                identifier_key === 'Edu'
                || identifier_key === 'EduNew'
                || identifier_key === 'EduEdit'
                || identifier_key === 'EduDoc'
            ){
                // if(data.length > 0){
                    var eduData = [];
                    data.forEach(edu => {
                        eduData.push({institute_name: edu.institute_name || '',
                                        program_name: edu.program_name || '',
                                        achievement: edu.achievement || '',
                                        start_year : {id: edu.start_year, year: edu.start_year},
                                        end_year: edu.end_year ? {id: edu.end_year, year: edu.end_year}: '',
                                        is_present: edu.end_year ? false : true,
                                        // id: edu.user_education_id,
                                        id: edu.id,
                                        doc_data: [...edu.doc_data],
                                        doc_url: docUrlData+'doc/'+docTypeIds.edu+'/',
                                        edu_classify_id: edu.classify_id ? {id: edu.classify_id, label: edu.classify_name} : '',
                                        classify_name: '',
                                        edu_subject_id: edu.subject_id ? {id: edu.subject_id, label: edu.subject_name} : '',
                                        subject_name: '',
                                        edu_rank_id: edu.rank_id ? {id: edu.rank_id, label: edu.rank_name} : {id: -1, label: 'None'},
                                        rank_id: edu.rank_id ? edu.rank_id : -1
                                    });
                    });
                    // if(eduData.length > 0
                    //     && identifier_key === 'EduEdit'
                    //     && formDataEdu.length === eduData.length
                    //     && formDataEdu[formDataEdu.length-1].id !== 0
                    // ){
                    //     eduData.push({id: 0});
                    // }
                    setFormDataEdu([...eduData]);
                    updateAskevaData("education_details", eduData);
                    // setFormDataEdu([eduData]);
                // }
            }else if(
                identifier_key === 'Exp'
                || identifier_key === 'ExpNew'
                || identifier_key === 'ExpEdit'
                || identifier_key === 'ExpDoc'
            ){
                // if(data.length > 0){
                    var expData = [];
                    data.forEach(exp => {
                        expData.push({designation: exp.designation || '',
                                        organization: exp.organization || '',
                                        achievement: exp.achievement || '',
                                        start_year: moment(exp.start_date).format("YYYY-MM"),
                                        end_year: exp.end_date ? moment(exp.end_date).format("YYYY-MM") : '',
                                        is_present: exp.end_date ? false : true,
                                        // id: exp.user_experience_id,
                                        id: exp.id,
                                        doc_data: [...exp.doc_data],
                                        doc_url: docUrlData+'doc/'+docTypeIds.exp+'/',
                                        exp_classify_id: exp.classify_id ? {id: exp.classify_id, label: exp.classify_name} : '',
                                        exp_work_type_id: exp.work_type_id ? {id: exp.work_type_id, label: exp.work_type_name} : '',
                                        exp_company_type_id: exp.company_type_id ? {id: exp.company_type_id, label: exp.company_type_name} : '',
                                        exp_classify_name: '',
                                        exp_firm_name: '',
                                    });
                    });
                    setFormDataExp([...expData]);
                    updateAskevaData("experience_details", expData);
                // }
            }else if(
                identifier_key === 'Research'
                || identifier_key === 'ResearchNew'
                || identifier_key === 'ResearchEdit'
                || identifier_key === 'ResearchDoc'
            ){
                // if(data.length > 0){
                    var researchData = [];
                    data.forEach(research => {
                        researchData.push({title: research.title || '',
                                        domain: research.domain || '',
                                        link: research.link || '',
                                        year: {id: research.year, year: research.year},
                                        achievement: research.achievement || '',
                                        // id: research.user_research_id,
                                        id: research.id,
                                        doc_data: [...research.doc_data],
                                        doc_url: docUrlData+'doc/'+docTypeIds.research+'/',
                                        research_classify_id:  research.classify_id ? {id: research.classify_id, label: research.classify_name} : '',
                                        research_work_type_id:  research.work_type_id ? {id: research.work_type_id, label: research.work_type_name} : '',
                                        research_subject_id:  research.subject_id ? {id: research.subject_id, label: research.subject_name} : '',
                                        research_com_level_id:  research.level_id ? {id: research.level_id, label: research.level_name} : '',
                                        research_rank_id: research.rank_id ? {id: research.rank_id, label: research.rank_name} : {id: -1, label: 'None'},
                                        rank_id: research.rank_id ? research.rank_id : -1,
                                        research_classify_name: '',
                                        research_subject_name: '',
                                    });
                    });
                    setFormDataResearch([...researchData]);
                    updateAskevaData("research_details", researchData);
                // }
            }else if(
                identifier_key === 'Sport'
                || identifier_key === 'SportNew'
                || identifier_key === 'SportEdit'
                || identifier_key === 'SportDoc'
            ){
                // if(data.length > 0){
                    var sportData = [];
                    data.forEach(sport => {
                        sportData.push({name: sport.name || '',
                                        venue: sport.venue || '',
                                        achievement: sport.achievement || '',
                                        start_year : {id: sport.start_year, year: sport.start_year},
                                        end_year: sport.end_year ? {id: sport.end_year, year: sport.end_year}: '',
                                        is_present: sport.end_year ? false : true,
                                        // id: sport.user_sport_id,
                                        id: sport.id,
                                        doc_data: sport.doc_data,
                                        doc_url: docUrlData+'doc/'+docTypeIds.sport+'/',
                                        sport_classify_id: sport.classify_id ? {id: sport.classify_id, label: sport.classify_name} : '',
                                        sport_work_type_id: sport.work_type_id ? {id: sport.work_type_id, label: sport.work_type_name} : '',
                                        sport_domain_id: sport.domain_id ? {id: sport.domain_id, label: sport.domain_name} : '',
                                        sport_level_id: sport.level_id ? {id: sport.level_id, label: sport.level_name} : '',
                                        sport_rank_id: sport.rank_id ? {id: sport.rank_id, label: sport.rank_name} : {id: -1, label: 'None'},
                                        rank_id: sport.rank_id ? sport.rank_id : -1,
                                        sport_classify_name: '',
                                        sport_domain_name: '',
                                    });
                    });
                    setFormDataSport([...sportData]);
                    updateAskevaData("sport_details", sportData);
                // }
            }else if(
                identifier_key === 'Award'
                || identifier_key === 'AwardNew'
                || identifier_key === 'AwardEdit'
                || identifier_key === 'AwardDoc'
            ){
                var awardData = [];
                // if(data.length > 0){
                data.forEach(award => {
                    awardData.push({institute_name: award.institute_name || '',
                                    award_name: award.award_name || '',
                                    why_awarded: award.why_awarded || '',
                                    year : {id: award.year, year: award.year},
                                    // id: award.user_award_id,
                                    id: award.id,
                                    doc_data: award.doc_data,
                                    doc_url: docUrlData+'doc/'+docTypeIds.award+'/',
                                    award_classify_id: award.classify_id ? {id: award.classify_id, label: award.classify_name} : '',
                                    award_domain_id: award.domain_id ? {id: award.domain_id, label: award.domain_name} : '',
                                    award_level_id: award.level_id ? {id: award.level_id, label: award.level_name} : '',
                                    award_domain_name: '',
                                    award_rank_id: award.rank_id ? {id: award.rank_id, label: award.rank_name} : {id: -1, label: 'None'},
                                    rank_id: award.rank_id ? award.rank_id : -1
                                });
                });
                setFormDataAward([...awardData]);
                updateAskevaData("award_details", awardData);
                // }
            }else if(
                identifier_key === 'LifeExp'
                || identifier_key === 'LifeExpNew'
                || identifier_key === 'LifeExpEdit'
                || identifier_key === 'LifeExpDoc'
            ){
                var lifeExpData = [];
                data.forEach(exp_d => {
                    lifeExpData.push({experience: exp_d.experience || '',
                                    impact: exp_d.impact || '',
                                    learning: exp_d.learning || '',
                                    // id: exp_d.user_life_exp_id,
                                    id: exp_d.id,
                                    doc_url: docUrlData+'doc/'+docTypeIds.lifeExp+'/',
                                    doc_data: [...exp_d.doc_data],
                                    theme_id: [...exp_d.theme_data],
                                    emotion_id: [...exp_d.emotion_data],
                                    // comp_details: exp_d.comp_details
                                });
                });
                setFormDataLifeExp([...lifeExpData]);
                updateAskevaData("life_exp_details", lifeExpData);
            }else if(
                identifier_key === 'Social'
                || identifier_key === 'SocialNew'
                || identifier_key === 'SocialEdit'
            ){
                var socialData = [];
                data.forEach(social_d => {
                    socialData.push({name: social_d.name || '',
                                    kpi: social_d.kpi || '',
                                    achievement: social_d.achievement || '',
                                    learning: social_d.learning || '',
                                    // date: social_d.end_date || '',
                                    date: social_d.end_date ? moment(social_d.end_date).format("YYYY-MM") : '',
                                    id: social_d.user_social_id
                                });
                });
                
                setFormDataSocial([...socialData]);
                updateAskevaData("social_details", socialData);
            }else if(identifier_key === 'Recom'){
                var recomData = [];
                data.forEach(recom_d => {
                    recomData.push({name: recom_d.name || '',
                                    email: recom_d.email || '',
                                    // relation: recom_d.relation || '',
                                    id: recom_d.recom_id
                                });
                });
                setRecomFetchData([...recomData]);
            }else if(identifier_key === 'School'){
                setFormDataSchool({...formDataSchool, ...editFormData});
            }
        }catch(e){
            console.error(e);
        }
    }
    useEffect(() => {
        //Get Portfolio Details
        const fetchPortfolioData = async() => {
            try{
                const portfolioApiData = await post_api_call(`portfolio-details`, {fetch_type: 'edit'});
                // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
                if(portfolioApiData.status === 200){
                    // if(portfolioApiData.data.headline){
                    //     window.location.href = `/port/${portfolioApiData.data.url}`;
                    // }else{
                        // if(!portfolioApiData.data.headline){
                        //     setIsFirstEdit(true);
                        // }
                        setAllApiData(portfolioApiData.data);
                        //GeneralDetails
                        const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo', 'image', 'position'];
                        const generalFieldData = {...formDataGeneral};
                        var schoolFieldData = {...formDataSchool};
                        fieldKeys.forEach(key => {
                            generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
                        });
                        generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
                        generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
                        // generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
                        setFormDataGeneral({...generalFieldData});
                        //School Details
                        if(typeof portfolioApiData.data.institute_type_id !== "undefined"
                            &&  portfolioApiData.data.institute_type_id
                        ){
                            schoolFieldData.institute_type_id = {id: portfolioApiData.data.institute_type_id, type: instituteTypeObj[portfolioApiData.data.institute_type_id]};
                            if(portfolioApiData.data.institute_type_id === 1){
                                schoolFieldData.school_id = portfolioApiData.data.school_id
                                                        ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : {id: 0, school_name: 'Other'};
                                schoolFieldData.class_id = portfolioApiData.data.class_id
                                                        ? {id: portfolioApiData.data.class_id, class_name: portfolioApiData.data.class_name}
                                                        : '';
                                schoolFieldData.branch_id = portfolioApiData.data.branch_id
                                                        ? {id: portfolioApiData.data.branch_id, branch_name: portfolioApiData.data.branch_name} : {id: -1, branch_name: 'N/A (Not Applicable)'};
                            }else if(portfolioApiData.data.institute_type_id === 2){
                                schoolFieldData.course_id = portfolioApiData.data.class_id
                                                            ? {id: portfolioApiData.data.class_id, class_name: portfolioApiData.data.class_name}
                                                            : {id: 0, class_name: 'Other'};
                                schoolFieldData.college_name = portfolioApiData.data.school_name || '';
                            }else if(portfolioApiData.data.institute_type_id === 3){
                                schoolFieldData.org_name = portfolioApiData.data.school_name || '';
                            }
                        }else{
                            schoolFieldData.institute_type_id = {id: 0, type: 'Other'};
                        }
                        // schoolFieldData.course_name = portfolioApiData.data.class_name || '';
                        // schoolFieldData.class_name = portfolioApiData.data.class_name || '';
                        // schoolFieldData.school_name = portfolioApiData.data.school_name || '';
                        // schoolFieldData.branch_name = portfolioApiData.data.branch_name || '';
                        setFormDataSchool({...schoolFieldData});
                        setAccessData(portfolioApiData.data.services_access);
                        // setReferralCode(portfolioApiData.data.referral_code);
                        setDocUrl(portfolioApiData.data.doc_url);
                        if(portfolioApiData.data.form_details){
                            const sectArray = portfolioApiData.data.form_details.section_ids.split(",");
                            setCompletedSec(sectArray);
                            // setCompletedSec(sectArray.length);
                        }
                        setFormDataPassion({...formDataPassion, email: portfolioApiData.data.email});
                        setUserUrl(portfolioApiData.data.user_name);
                        //Headline
                        setFormDataHeadline(portfolioApiData.data.position || '');
                        //Short Description
                        setFormDataDesc({short_description: portfolioApiData.data.headline || '',
                                        basic_achievement: portfolioApiData.data.achievement || '',
                                        basic_passion: portfolioApiData.data.passion || ''});
                        //Unique Skills
                        if(portfolioApiData.data.unique_skills.length > 0){
                            var uniqueData = [];
                            portfolioApiData.data.unique_skills.forEach(skill => {
                                // uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
                                uniqueData.push({passion_id : {id: skill.passion_id, title: skill.title, desc: skill.unique_ability}});
                            });
                            setFormDataUnique(uniqueData);
                        }
                        //Enroll Data
                        // const enrollData = typeof portfolioApiData.data.enroll_data
                        // setFormDataEnroll(portfolioApiData.data.enroll_data);
                        setFormDataEnroll(portfolioApiData.data.subs_service_details);
                        //Education
                        updateInpState('Edu', portfolioApiData.data.education_details);
                        //Achievements
                        if(portfolioApiData.data.glosco_data.length > 0){
                            // var gloscoData = formDataAchieve;
                            // var gloscoData = [];
                            // portfolioApiData.data.glosco_data.forEach(glosco => {
                                // gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
                                //                                 id: glosco.level_id,
                                //                                 level: glosco.level,
                                //                                 year: {id: glosco.year, year: glosco.year},
                                //                                 indi_award: glosco.indi_award,
                                //                                 team_award: glosco.team_award};

                            // });
                            setGloscoData(portfolioApiData.data.glosco_data);
                            // var existingSessIds = [];
                            // // if(existingSessIds.indexOf(13) !== -1){
                            //     var gloscoData = [];
                            //     portfolioApiData.data.glosco_data.forEach(glosco => {
                            //         // if(glosco.indi_award.length > 5){
                            //             var levelSessIds = [];
                            //             var levelIndData = [];
                            //             glosco.indi_award.forEach(glosco_indi => {
                            //                 if(glosco.level_id === 1){//Only for level 1
                            //                     existingSessIds.push(glosco_indi.session_id);
                            //                 }
                            //                 if(glosco_indi.session_id){
                            //                     levelSessIds.push(glosco_indi.session_id);
                            //                 }
                            //                 if(glosco_indi.id){
                            //                     levelIndData.push(glosco_indi);
                            //                 }
                            //             });
                            //             // if(levelSessIds.indexOf(13) !== -1){
                            //                 var gloscoDetails = {...glosco};
                            //                 gloscoDetails.indi_award = levelIndData;
                            //                 gloscoData.push(gloscoDetails);
                            //             // }
                            //         // }
                                    
                                    
                            //         // gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
                            //         //                                 id: glosco.level_id,
                            //         //                                 level: glosco.level,
                            //         //                                 year: {id: glosco.year, year: glosco.year},
                            //         //                                 indi_award: glosco.indi_award,
                            //         //                                 team_award: glosco.team_award};

                            //     });
                            //     setGloscoData(gloscoData);
                            // // }
                            // portfolioApiData.data.glosco_data.forEach(glosco => {
                            //     glosco.indi_award.forEach(glosco_indi => {
                            //         existingSessIds.push(glosco_indi.session_id);
                            //     });
                            //     glosco.team_award.forEach(glosco_team => {
                            //         existingSessIds.push(glosco_team.session_id);
                            //     });
                            // });
                        }
                        //Glosco Session
                        if(portfolioApiData.data.glosco_session.length > 0){
                            generateSessionOption('remove', portfolioApiData.data.glosco_session[0].session_ids);
                        }else{
                            generateSessionOption('remove', []);
                        }
                        //Session Options
                        // setSessionOption([
                        //     {id: 1, label: "Induction 1"},
                        //     {id: 2, label: "Induction 2"},
                        //     {id: 3, label: "Speaker’s Council 1"},
                        //     {id: 4, label: "Speaker’s Council 2"},
                        //     {id: 5, label: "Speaker’s Council 3"},
                        //     {id: 6, label: "Speaker’s Council 4"},
                        //     {id: 7, label: "Speaker’s Council 5"},
                        //     {id: 8, label: "Speaker’s Council 6"},
                        //     {id: 9, label: "Team Induction 1"},
                        //     {id: 10, label: "Team Induction 2"},
                        //     {id: 11, label: "Team Council"},
                        //     {id: 12, label: "Diplomatic Council"},
                        //     {id: 13, label: "Debrief"},
                        //     {id: 14, label: "Excellence Summit"}
                        // ]);
                        //Experience
                        updateInpState('Exp', portfolioApiData.data.experience_details);
                        //Research
                        updateInpState('Research', portfolioApiData.data.research_details);
                        //Sport
                        updateInpState('Sport', portfolioApiData.data.sport_details);
                        //Award
                        updateInpState('Award', portfolioApiData.data.award_details);
                        //Life Exp
                        updateInpState('LifeExp', portfolioApiData.data.life_exp_details);
                        //Social
                        updateInpState('Social', portfolioApiData.data.social_details);
                        //Recom
                        updateInpState('Recom', portfolioApiData.data.recommend_details);

                        //Career Skills
                        if(portfolioApiData.data.carrer_skill_details.length > 0){
                            setFormDataCareer(portfolioApiData.data.carrer_skill_details);
                        }
                        //Personal Skills
                        if(portfolioApiData.data.personal_skill_details.length > 0){
                            setFormDataPersonal(portfolioApiData.data.personal_skill_details);
                        }
                        // setFormData({...fieldData});
                        setPageLoader(false);
                        //AskEVA
                        setEvaPayload({...askevaPayload, userInformation: portfolioApiData.data})
                    // }
                }
            }catch(e){
                console.error(e);
                alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
                error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
            }
        }
        fetchPortfolioData();
		//Initial API Data
        // fetchCountryList();
        // fetchHubList();
        fetchSelectList('passion', 'passion-scores?fetch_type=2');
        fetchSelectList('career_skill', 'skills/career');
        fetchSelectList('personal_skill', 'skills/personal');
        // fetchSelectList('school', 'schools');
        // fetchSelectList('cohort', 'cohorts');
        fetchSelectList('indi_award', 'awards/individual');
        fetchSelectList('team_award', 'awards/team');
        // fetchSelectList('program');
        yearDataList('year');
        // yearDataList('future');
        // futureYearDataList('asc');
        // futureYearDataList('dsc');
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            // console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            // if(identifier_key === 'Achieve'
            //     && field === 'rating'
            // ){
            //     field_type.preventDefault();
            // }
            if(identifier_key === 'Passion'){
                setErrorData({...errorData, Unique: []});
            }else{
                if(errorData[identifier_key].length){
                    setErrorData({...errorData, [identifier_key]: []});
                }
            }
            
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            // setViewDisabled({...isViewDisabled, [identifier_key]: true});
            if(field === 'mobile_number'){
                if(value.length < 16){
                    value = value.replace(/[^0-9]/g, '');
                }else{
                    return false;
                }
            }else if(input_type === 'input'
                || input_type === 'textarea'
            ){
                // value = value.replace(/ /g, '');
                value = value.trimStart();
                // value = value.replace(/  /g, '');
                if(field === 'first_name'
                    || field === 'last_name'
                ){
                    value = value.replace(/[^a-zA-Z. ]/g, '')
                      .replace('  ', ' ');
                }
            }
            // const fieldData = {...formData};
            // var fieldData = identifier_type === 'object' ? {...eval('formData'+identifier_key)} : eval('formData'+identifier_key);
            // var fieldData = eval('formData'+identifier_key);
            var fieldData = identifier_type === 'array' 
                            ? [] 
                            : identifier_type === 'object'
                                ? {}
                                : '';
            switch (identifier_key) {
                case 'Master':
                    fieldData = {...editFormData};
                    break;
                // case 'General':
                //     fieldData = {...formDataGeneral};
                //     break;
                case 'Headline':
                    fieldData = formDataHeadline;
                    break;
                case 'Desc':
                    fieldData = {...formDataDesc};
                    break;
                // case 'Unique':
                //     setFormDataUnique([...fieldData]);
                //     break;
                // case 'Edu':
                //     fieldData = {...editDataEdu};
                //     break;
                case 'Achieve':
                    // fieldData = [...formDataAchieve];
                    fieldData = {...formDataAchieve};
                    break;
                // case 'Exp':
                //     fieldData = [...formDataExp];
                //     break;
                // case 'Research':
                //     fieldData = [...formDataResearch];
                //     break;
                // case 'Sport':
                //     fieldData = [...formDataSport];
                //     break;
                // case 'Award':
                //     fieldData = [...formDataAward];
                //     break;
                case 'Career':
                    fieldData = [...formDataCareer];
                    break;
                case 'Personal':
                    fieldData = [...formDataPersonal];
                    break;
                case 'Passion':
                    fieldData = {...formDataPassion};
                    break;
                case 'Recom':
                    fieldData = {...formDataRecom};
                    break;
                default:
            }
            // if(identifier_type === 'object'){
            //     fieldData = {...fieldData}
            // }else if(identifier_type === 'array'){
            //     fieldData = [...fieldData]
            // }
            // console.log(fieldData, 'fieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldData')
            if(field_type === 'array'){
                // const fieldData = eval('formData'+identifier_key);
                // console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                // fieldData[form_field][index][field] = value;
                fieldData[index][field] = value;
                // setFormData({...fieldData});
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
                // setFormData({
                //     ...formData,
                //     [field]: value
                // });
            }
            switch (identifier_key) {
                case 'Master':
                    if(field === 'institute_type_id'){
                        setEditFormData({...fieldData});
                        setMasterFieldData(fieldEditSchool[fieldData.institute_type_id.id]);
                        formatSchoolFields(fieldData);
                    }else if(field === 'sport_classify_id'){
                        fieldData.sport_domain_id = (fieldData.sport_classify_id
                                                    && fieldData.sport_classify_id.id === 0)
                                                    ? {id: 0, label: 'Other'}
                                                    : '';
                        setEditFormData({...fieldData});
                        loadSportDomain(fieldData.sport_classify_id && fieldData.sport_classify_id.id ? fieldData.sport_classify_id.id : 0);
                    }else{
                        setEditFormData({...fieldData});
                    }
                    break;
                // case 'General':
                //     setFormDataGeneral({...fieldData});
                //     break;
                case 'Headline':
                    setFormDataHeadline(fieldData);
                    break;
                case 'Desc':
                    setFormDataDesc({...fieldData});
                    break;
                // case 'Unique':
                //     setFormDataUnique([...fieldData]);
                //     break;
                // case 'Edu':
                //     // setFormDataEdu([...fieldData]);
                //     console.log('ssssss', fieldData)
                //     setEditDataEdu({...fieldData});
                //     break;
                case 'Achieve':
                    // setFormDataAchieve([...fieldData]);
                    if(field === "session_id"){
                        // setFormDataAchieve({...fieldData, ...initialGloscoData});
                        setFormDataAchieve({...fieldData,
                                            role_id: "",
                                            award_id: {id: 0, label: "No Award"},
                                            is_team_award: "",
                                            team_award_id: "",
                                            year: "",
                                            cohort_number: ""});
                        getGloscoRole(value);
                    }else if(field === "role_id"){
                        setFormDataAchieve({...fieldData,
                                            award_id: {id: 0, label: "No Award"},
                                            is_team_award: "",
                                            team_award_id: "",
                                            year: "",
                                            cohort_number: ""});
                    }else if(field === "award_id"){
                        setFormDataAchieve({...fieldData,
                                            is_team_award: "",
                                            team_award_id: "",
                                            year: "",
                                            cohort_number: ""});
                    }else if(field === "is_team_award"){
                        setFormDataAchieve({...fieldData,
                                            team_award_id: "",
                                            year: "",
                                            cohort_number: ""});
                    }else{
                        setFormDataAchieve({...fieldData});
                    }
                    break;
                // case 'Exp':
                //     setFormDataExp([...fieldData]);
                //     break;
                // case 'Research':
                //     setFormDataResearch([...fieldData]);
                //     break;
                // case 'Sport':
                //     setFormDataSport([...fieldData]);
                //     break;
                // case 'Award':
                //     setFormDataAward([...fieldData]);
                //     break;
                case 'Career':
                    setFormDataCareer([...fieldData]);
                    break;
                case 'Personal':
                    setFormDataPersonal([...fieldData]);
                    break;
                case 'Passion':
                    setFormDataPassion({...fieldData});
                    break;
                case 'Recom':
                    setFormDataRecom({...fieldData});
                    break;
                default:
            }
        }catch(e){
            console.error(e);
            error_api_call({error: `portfolio|edit|updateInput|${e}`});
        }
    }
    //Check if Url is Valid
    const isValidUrl = (string) => {
        try {
            // console.log(string, 'stringstringstringstringstring')
        //   new URL(string);
            // const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
            if(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(string)) {// eslint-disable-line
                return true;
            }else{
                return false;
            }
        } catch (err) {
          return false;
        }
    }
    //Add Fellow on Submit Click
    const editPortfolio = async(e, form_type='', active_form='') => {
        try{
            // console.log(form_type, active_form)
            e.preventDefault();
            // generateSessionOption('remove', [null, 1, 3, 4, 9, 11])
            // return;
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                Master: [],
                General: [],
                Headline: [],
                Pic: [],
                Desc: [],
                Unique: [],
                // Edu: [],
                // Exp: [],
                Achieve: [],
                // Research: [],
                // Sport: [],
                // Award: [],
                Career: [],
                Personal: [],
                Recom: [],
                UpdateDoc: []
                // Passion: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            var fieldFormDataScl = {};
            var validator = {};
            switch (form_type) {
                // case 'General':
                    // const fieldFormDataGeneral = {...formDataGeneral,
                    //     country_id: formDataGeneral.country_id.id,
                    //     phone_code_id: formDataGeneral.phone_code_id.id,
                    //     // school_name_id: formDataGeneral.school_name_id.id,
                    // };
                    // validationRules = {...fieldValidationGen};
                    // validation = ValidationHelper.validation(fieldFormDataGeneral, validationRules).error;
                    // if(validation.length){
                    //     errorDetails[form_type] = validation;
                    // }else{
                    //     const basicDataKeys = ['first_name',
                    //                             'last_name',
                    //                             // 'country_id',
                    //                             // 'school_name_id',
                    //                             'dob',
                    //                             'language'
                    //                         ];
                    //     for (var key in basicDataKeys) {
                    //         if (fieldFormDataGeneral.hasOwnProperty(basicDataKeys[key])) {
                    //             apiFormData.append(`general_data[${basicDataKeys[key]}]`, fieldFormDataGeneral[basicDataKeys[key]]);
                    //         }
                    //     }
                    // }
                    // break;
                case 'Pic':
                    //Image
                    if(previewPicImg === null){
                        errorDetails[form_type] = ['Photograph is required'];
                    }else{
                        apiFormData.append('profile_pic', previewPicBlob, 'Profile Pic');
                        apiFormData.append('is_first', formDataGeneral.image ? false : true);
                    }
                    break;
                case 'Headline':
                    const fieldFormDataHeadline = {
                        position: formDataHeadline
                    };
                    validationRules = {...fieldValidationHead};
                    validation = ValidationHelper.validation(fieldFormDataHeadline, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        apiFormData.append('headline_data', formDataHeadline);
                        apiFormData.append('is_first', formDataGeneral.position ? false : true);
                    }
                    break;
                case 'Desc':
                    validationRules = {...fieldValidationDesc};
                    validation = ValidationHelper.validation(formDataDesc, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        apiFormData.append('desc_data', JSON.stringify(formDataDesc));
                    }
                    break;
                case 'Unique':
                    // Unique Skills
                    var passionData = [];
                    passionFetchData.forEach((passion, passion_indx) => {
                        if(passionData.indexOf(passion.passion_id) !== -1){
                            errorDetails[form_type] = [`2 Passion Scores cannot be same`];
                        }
                        passionData.push(passion.passion_id);
                        apiFormData.append(`passion[${passion_indx}]`, passion.passion_id);
                    });
                    if(passionData.length < 3){
                        errorDetails[form_type] = [`Atleast 3 Passion Scores Required`];
                    }
                    break;
                case 'Achieve':
                    validationRules = {...fieldValidationGlosco};
                    validation = ValidationHelper.validation(formDataAchieve, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }
                    if(formDataAchieve.session_id
                        && (formDataAchieve.session_id.id === 12
                            || formDataAchieve.session_id.id === 14)
                        && formDataAchieve.role_id
                        // && formDataAchieve.role_id.id !== 15
                        && !formDataAchieve.is_team_award
                    ){
                        validation.push("Did your team win any award? is Required");
                    }
                    //If Team Award Confirmed, Check Award selected or not
                    if(formDataAchieve.is_team_award
                        && formDataAchieve.is_team_award.id === 1
                        && !formDataAchieve.team_award_id
                    ){
                        validation.push("Team Award is Required");
                    }
                    if(formDataAchieve.session_id
                        && formDataAchieve.session_id.id === 13
                        && !formDataAchieve.year
                    ){
                        validation.push("Year of completion is Required");
                    }
                    if(formDataAchieve.session_id
                        && formDataAchieve.session_id.id === 13
                        && !formDataAchieve.cohort_number
                    ){
                        validation.push("Cohort Number is Required");
                    }
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }
                    if(!errorDetails[form_type].length){
                        apiFormData.append('achievement[glosco_level_id]', 1);
                        apiFormData.append('achievement[session_id]', formDataAchieve.session_id.id);
                        apiFormData.append('achievement[role_id]', formDataAchieve.role_id.id);
                        if(formDataAchieve.award_id
                            && formDataAchieve.award_id.id !== 0
                        ){
                            apiFormData.append('achievement[award_id]', formDataAchieve.award_id.id);
                        }
                        if(formDataAchieve.is_team_award && formDataAchieve.is_team_award.id === 1){
                            apiFormData.append('achievement[team_award_id]', formDataAchieve.team_award_id.id);
                        }
                        if(formDataAchieve.session_id.id === 13){
                            apiFormData.append('achievement[year]', formDataAchieve.year.id);
                            apiFormData.append('achievement[cohort_number]', formDataAchieve.cohort_number.id);
                        }
                        apiFormData.append('achievement[learning]', formDataAchieve.learning);
                        apiFormData.append('achievement[rating]', formDataAchieve.rating);
                    }
                    break;

                    // formDataAchieve.forEach((achieve, achieve_indx) => {
                    //     var achieveDetails = achieve;
                    //     if(achieveDetails.year || achieveDetails.team_award || achieveDetails.indi_award){
                    //         var validationAchieve = [];
                    //         if(achieveDetails.year === ''){
                    //             validationAchieve.push(`IFSPD Europe Glo... ${achieveDetails.level}: Year of completion is Required`);
                    //         }else if(achieveDetails.indi_award === '' && achieveDetails.team_award === ''){
                    //             validationAchieve.push(`IFSPD Europe Glo... ${achieveDetails.level}: Individual/Team Awards is Required`);
                    //         }
                    //         if(validationAchieve.length){
                    //             errorDetails[form_type] = errorDetails[form_type].concat(validationAchieve);
                    //         }
                    //         if(!errorDetails[form_type].length){
                    //             const indiAwardIds = [];
                    //             const teamAwardIds = [];
                    //             if(achieveDetails.indi_award.length){
                    //                 const indiAward = achieveDetails.indi_award;
                    //                 indiAward.forEach(award => {
                    //                     indiAwardIds.push(award.id);
                    //                 });
                    //             }
                    //             if(achieveDetails.team_award.length){
                    //                 const teamAward = achieveDetails.team_award;
                    //                 teamAward.forEach(award => {
                    //                     teamAwardIds.push(award.id);
                    //                 });
                    //             }
                    //             apiFormData.append(`achievement[${achieve_indx}][id]`, achieveDetails.id);
                    //             apiFormData.append(`achievement[${achieve_indx}][year]`, achieveDetails.year.id);
                    //             apiFormData.append(`achievement[${achieve_indx}][indi_award]`, indiAwardIds);
                    //             apiFormData.append(`achievement[${achieve_indx}][team_award]`, teamAwardIds);
                    //         }
                    //     }
                    // });
                    // break;
                case 'Career':
                    //Career Skills
                    if(formDataCareer.length > 3){
                        errorDetails[form_type].push(`Select only top three Career Skills`);
                    }else{
                        const careerSkillIds = [];
                        formDataCareer.forEach(award => {
                            careerSkillIds.push(award.id);
                        });
                        apiFormData.append(`career_skills`, careerSkillIds);
                    }
                    break;
                case 'Personal':
                    //Personal Skills
                    if(formDataPersonal.length > 3){
                        errorDetails[form_type].push(`Select only top three Personal Skills`);
                    }else{
                        const personalSkillIds = [];
                        formDataPersonal.forEach(award => {
                            personalSkillIds.push(award.id);
                        });
                        apiFormData.append(`personal_skills`, personalSkillIds);
                    }
                    break;
                case 'Recom':
                    validationRules = {...fieldValidationRecom};
                    validation = ValidationHelper.validation(formDataRecom, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        apiFormData.append('recom_data', JSON.stringify(formDataRecom));
                    }
                    break;
                
                default:
                    break;
            }
            if(form_type === 'Master'
                || form_type === 'UpdateDoc'
            ){
                if(active_form === 'EduNew'
                    || active_form === 'EduEdit'
                    || active_form === 'EduDoc'
                ){
                    const fieldFormDataEdu = {...editFormData,
                        start_year: editFormData.start_year ? editFormData.start_year.id : '',
                        end_year: !editFormData.is_present && editFormData.end_year ? editFormData.end_year.id : ''
                    };
                    if(active_form !== 'EduDoc'){
                        validationRules = {...fieldValidationNewEdu};
                        if(fieldFormDataEdu.is_present !== true){
                            validationRules.end_year = `required`;
                        }
                        validation = ValidationHelper.validation(fieldFormDataEdu, validationRules).error;
                    }
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        if (active_form !== 'EduDoc' && fieldFormDataEdu.is_present === false && fieldFormDataEdu.start_year > fieldFormDataEdu.end_year){
                            errorDetails[form_type].push(`Start Year cannot be greater than End Year`);
                        }else if(active_form !== 'EduEdit' && previewDocData.length > process.env.REACT_APP_UPLOAD_FILE_QTY){
                            errorDetails[form_type].push(`Maximum ${process.env.REACT_APP_UPLOAD_FILE_QTY} Images/pdf Allowed`);
                        }else if(active_form === 'EduDoc' && previewDocData.length === 0){
                            errorDetails[form_type].push(`Upload atleast 1 Image/pdf`);
                        }else{
                            //Validate Classify
                            if(active_form !== 'EduDoc'){
                                validator = {};
                                if(fieldFormDataEdu.edu_classify_id
                                    && typeof fieldFormDataEdu.edu_classify_id.id !== "undefined"
                                    && fieldFormDataEdu.edu_classify_id.id === 0
                                ){
                                    validator['classify_name'] = 'required|max:100||How do you classify this';
                                }
                                if(fieldFormDataEdu.edu_subject_id
                                    && typeof fieldFormDataEdu.edu_subject_id.id !== "undefined"
                                    && fieldFormDataEdu.edu_subject_id.id === 0
                                ){
                                    validator['subject_name'] = 'required|max:100||Subject/Course/Stream';
                                }
                                validation = ValidationHelper.validation(fieldFormDataEdu, validator).error;
                                if(validation.length){
                                    errorDetails[form_type] = validation;
                                }else{
                                    if(fieldFormDataEdu.edu_classify_id
                                        && typeof fieldFormDataEdu.edu_classify_id.id !== "undefined"
                                        && fieldFormDataEdu.edu_classify_id.id === 0
                                        && excludedTerm.includes(fieldFormDataEdu.classify_name.toLowerCase())
                                    ){
                                        validation.push("'How do you classify this' is Invalid");
                                    }
                                    if(fieldFormDataEdu.edu_subject_id
                                        && typeof fieldFormDataEdu.edu_subject_id.id !== "undefined"
                                        && fieldFormDataEdu.edu_subject_id.id === 0
                                        && excludedTerm.includes(fieldFormDataEdu.subject_name.toLowerCase())
                                    ){
                                        validation.push('Subject/Course/Stream is Invalid');
                                    }
                                    if(fieldFormDataEdu.edu_rank_id
                                        && typeof fieldFormDataEdu.edu_rank_id.id !== "undefined"
                                        && fieldFormDataEdu.edu_rank_id.id > 0
                                        && (active_form === 'EduNew'
                                            || (active_form === 'EduEdit' && fieldFormDataEdu.rank_id !== fieldFormDataEdu.edu_rank_id.id))
                                        && rankDocData.length === 0
                                    ){
                                        validation.push("Please upload Achievement Certificate or Select option 'None' under 'Any Achievement'");
                                    }
                                }
                            }
                            
                            if(validation.length){
                                errorDetails[form_type] = validation;
                            }else{
                                //Send Data
                                if(active_form !== 'EduDoc'){
                                    apiFormData.append(`education[edu_classify_id]`, fieldFormDataEdu.edu_classify_id.id);
                                    apiFormData.append(`education[edu_subject_id]`, fieldFormDataEdu.edu_subject_id.id);
                                    apiFormData.append(`education[edu_rank_id]`, fieldFormDataEdu.edu_rank_id ? fieldFormDataEdu.edu_rank_id.id : '');
                                    apiFormData.append(`education[classify_name]`, fieldFormDataEdu.edu_classify_id.id === 0 ? fieldFormDataEdu.classify_name : '');
                                    apiFormData.append(`education[subject_name]`, fieldFormDataEdu.edu_subject_id.id === 0 ? fieldFormDataEdu.subject_name : '');
                                    const basicDataKeys = ['institute_name',
                                                            'program_name',
                                                            'achievement',
                                                            'start_year',
                                                            'end_year',
                                                            'is_present'
                                                        ];
                                    if(active_form === 'EduEdit'){
                                        basicDataKeys.push('id');
                                    }
                                    for (var keyEdu in basicDataKeys) {
                                        if (fieldFormDataEdu.hasOwnProperty(basicDataKeys[keyEdu])) {
                                            apiFormData.append(`education[${basicDataKeys[keyEdu]}]`, fieldFormDataEdu[basicDataKeys[keyEdu]]);
                                        }
                                    }
                                    rankDocData.forEach(rank_cert => {
                                        apiFormData.append('rank_cert[]', rank_cert.data, rank_cert.title);
                                    });
                                }
                                //Image or Pdf Upload
                                if(active_form !== 'EduEdit' && previewDocData.length > 0){
                                    previewDocData.forEach(edu_cert => {
                                        apiFormData.append('edu_cert[]', edu_cert.data, edu_cert.title);
                                    });
                                    apiFormData.append('id', fieldFormDataEdu.id);
                                }
                            }
                        }
                    }
                }else if(active_form === 'ExpNew'
                    || active_form === 'ExpEdit'
                    || active_form === 'ExpDoc'
                ){
                    const fieldFormDataExp = {...editFormData};
                    if(active_form !== 'ExpDoc'){
                        validationRules = {...fieldValidationNewExp};
                        if(fieldFormDataExp.is_present !== true){
                            validationRules.end_year = `required`;
                        }
                        validation = ValidationHelper.validation(fieldFormDataExp, validationRules).error;
                    }
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        if(active_form !== 'ExpDoc' && fieldFormDataExp.is_present === false && fieldFormDataExp.end_year  === "Invalid date"){
                            errorDetails[form_type].push(`End Date is Required`);
                        }else if (active_form !== 'ExpDoc' && fieldFormDataExp.is_present === false && fieldFormDataExp.start_year > fieldFormDataExp.end_year){
                            errorDetails[form_type].push(`Start Date cannot be greater than End Date`);
                        }else if(active_form !== 'EduEdit' && previewDocData.length > process.env.REACT_APP_UPLOAD_FILE_QTY){
                            errorDetails[form_type].push(`Maximum ${process.env.REACT_APP_UPLOAD_FILE_QTY} Images/pdf Allowed`);
                        }else if(active_form === 'ExpDoc' && previewDocData.length === 0){
                            errorDetails[form_type].push(`Upload atleast 1 Image/pdf`);
                        }else{
                            //Validate Classify
                            if(active_form !== 'ExpDoc'){
                                validator = {};
                                if(fieldFormDataExp.exp_classify_id
                                    && typeof fieldFormDataExp.exp_classify_id.id !== "undefined"
                                    && fieldFormDataExp.exp_classify_id.id === 0
                                ){
                                    validator['exp_classify_name'] = 'required|max:100||How do you classify this';
                                }
                                if(fieldFormDataExp.exp_company_type_id
                                    && typeof fieldFormDataExp.exp_company_type_id.id !== "undefined"
                                    && fieldFormDataExp.exp_company_type_id.id === 0
                                ){
                                    validator['exp_firm_name'] = 'required|max:100||Institution/Firm Type';
                                }
                                validation = ValidationHelper.validation(fieldFormDataExp, validator).error;
                                if(validation.length){
                                    errorDetails[form_type] = validation;
                                }else{
                                    if(fieldFormDataExp.exp_classify_id
                                        && typeof fieldFormDataExp.exp_classify_id.id !== "undefined"
                                        && fieldFormDataExp.exp_classify_id.id === 0
                                        && excludedTerm.includes(fieldFormDataExp.exp_classify_name.toLowerCase())
                                    ){
                                        validation.push("'How do you classify this' is Invalid");
                                    }
                                    if(fieldFormDataExp.exp_company_type_id
                                        && typeof fieldFormDataExp.exp_company_type_id.id !== "undefined"
                                        && fieldFormDataExp.exp_company_type_id.id === 0
                                        && excludedTerm.includes(fieldFormDataExp.exp_firm_name.toLowerCase())
                                    ){
                                        validation.push('Institution/Firm Type is Invalid');
                                    }
                                }
                            }
                            if(validation.length){
                                errorDetails[form_type] = validation;
                            }else{
                                //Send Data
                                if(active_form !== 'ExpDoc'){
                                    apiFormData.append(`experience[exp_classify_id]`, fieldFormDataExp.exp_classify_id.id);
                                    apiFormData.append(`experience[exp_work_type_id]`, fieldFormDataExp.exp_work_type_id.id);
                                    apiFormData.append(`experience[exp_company_type_id]`, fieldFormDataExp.exp_company_type_id.id);
                                    apiFormData.append(`experience[exp_classify_name]`, fieldFormDataExp.exp_classify_id.id === 0 ? fieldFormDataExp.exp_classify_name : '');
                                    apiFormData.append(`experience[exp_firm_name]`, fieldFormDataExp.exp_company_type_id.id === 0 ? fieldFormDataExp.exp_firm_name : '');

                                    const basicDataKeys = ['designation',
                                                            'organization',
                                                            'achievement',
                                                            'start_year',
                                                            'end_year',
                                                            'is_present'
                                                        ];
                                    if(active_form === 'ExpEdit'){
                                        basicDataKeys.push('id');
                                    }
                                    for (var keyExp in basicDataKeys) {
                                        if (fieldFormDataExp.hasOwnProperty(basicDataKeys[keyExp])) {
                                            apiFormData.append(`experience[${basicDataKeys[keyExp]}]`, fieldFormDataExp[basicDataKeys[keyExp]]);
                                        }
                                    }
                                }
                                //Image or Pdf Upload
                                if(active_form !== 'ExpEdit' && previewDocData.length > 0){
                                    previewDocData.forEach(edu_cert => {
                                        apiFormData.append('exp_cert[]', edu_cert.data, edu_cert.title);
                                    });
                                    apiFormData.append('id', fieldFormDataExp.id);
                                }
                            }
                        }
                    }
                }else if(active_form === 'ResearchNew'
                    || active_form === 'ResearchEdit'
                    || active_form === 'ResearchDoc'
                ){
                    const fieldFormDataResearch = {...editFormData,
                        year: editFormData.year.id
                    };
                    if(active_form !== 'ResearchDoc'){
                        validationRules = {...fieldValidationNewResearch};
                        validation = ValidationHelper.validation(fieldFormDataResearch, validationRules).error;
                    }
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        if(fieldFormDataResearch.link && !isValidUrl(fieldFormDataResearch.link)){
                            errorDetails[form_type].push(`Link is Invalid`);
                        }else if(active_form !== 'ResearchEdit' && previewDocData.length > process.env.REACT_APP_UPLOAD_FILE_QTY){
                            errorDetails[form_type].push(`Maximum ${process.env.REACT_APP_UPLOAD_FILE_QTY} Images/pdf Allowed`);
                        }else if(active_form === 'ResearchDoc' && previewDocData.length === 0){
                            errorDetails[form_type].push(`Upload atleast 1 Image/pdf`);
                        }else{
                            //Validate Classify
                            if(active_form !== 'ResearchDoc'){
                                validator = {};
                                if(fieldFormDataResearch.research_classify_id
                                    && typeof fieldFormDataResearch.research_classify_id.id !== "undefined"
                                    && fieldFormDataResearch.research_classify_id.id === 0
                                ){
                                    validator['research_classify_name'] = 'required|max:100||How do you classify this';
                                }
                                if(fieldFormDataResearch.research_subject_id
                                    && typeof fieldFormDataResearch.research_subject_id.id !== "undefined"
                                    && fieldFormDataResearch.research_subject_id.id === 0
                                ){
                                    validator['research_subject_name'] = 'required|max:100||Subject/Domain';
                                }
                                validation = ValidationHelper.validation(fieldFormDataResearch, validator).error;
                                if(validation.length){
                                    errorDetails[form_type] = validation;
                                }else{
                                    if(fieldFormDataResearch.research_classify_id
                                        && typeof fieldFormDataResearch.research_classify_id.id !== "undefined"
                                        && fieldFormDataResearch.research_classify_id.id === 0
                                        && excludedTerm.includes(fieldFormDataResearch.research_classify_name.toLowerCase())
                                    ){
                                        validation.push("'How do you classify this' is Invalid");
                                    }
                                    if(fieldFormDataResearch.research_subject_id
                                        && typeof fieldFormDataResearch.research_subject_id.id !== "undefined"
                                        && fieldFormDataResearch.research_subject_id.id === 0
                                        && excludedTerm.includes(fieldFormDataResearch.research_subject_name.toLowerCase())
                                    ){
                                        validation.push('Subject/Domain is Invalid');
                                    }
                                    if(fieldFormDataResearch.research_rank_id
                                        && typeof fieldFormDataResearch.research_rank_id.id !== "undefined"
                                        && fieldFormDataResearch.research_rank_id.id > 0
                                        && (active_form === 'ResearchNew'
                                            || (active_form === 'ResearchEdit' && fieldFormDataResearch.rank_id !== fieldFormDataResearch.research_rank_id.id))
                                        && rankDocData.length === 0
                                    ){
                                        validation.push("Please upload Achievement Certificate or Select option 'None' under 'Any Achievement'");
                                    }
                                }
                            }
                                
                            if(validation.length){
                                errorDetails[form_type] = validation;
                            }else{
                                //Send Data
                                if(active_form !== 'ResearchDoc'){
                                    apiFormData.append(`research[research_classify_id]`, fieldFormDataResearch.research_classify_id.id);
                                    apiFormData.append(`research[research_subject_id]`, fieldFormDataResearch.research_subject_id.id);
                                    apiFormData.append(`research[research_rank_id]`, fieldFormDataResearch.research_rank_id ? fieldFormDataResearch.research_rank_id.id : '');
                                    apiFormData.append(`research[research_classify_name]`, fieldFormDataResearch.research_classify_id.id === 0 ? fieldFormDataResearch.research_classify_name : '');
                                    apiFormData.append(`research[research_subject_name]`, fieldFormDataResearch.research_subject_id.id === 0 ? fieldFormDataResearch.research_subject_name : '');
                                    apiFormData.append(`research[research_work_type_id]`, fieldFormDataResearch.research_work_type_id.id);
                                    apiFormData.append(`research[research_com_level_id]`, fieldFormDataResearch.research_com_level_id.id);

                                    const basicDataKeys = ['title',
                                                            'domain',
                                                            'achievement',
                                                            'year',
                                                            'link'
                                                        ];

                                    if(active_form === 'ResearchEdit'){
                                        basicDataKeys.push('id');
                                    }
                                    for (var keyResearch in basicDataKeys) {
                                        if (fieldFormDataResearch.hasOwnProperty(basicDataKeys[keyResearch])) {
                                            apiFormData.append(`research[${basicDataKeys[keyResearch]}]`, fieldFormDataResearch[basicDataKeys[keyResearch]]);
                                        }
                                    }
                                    rankDocData.forEach(rank_cert => {
                                        apiFormData.append('rank_cert[]', rank_cert.data, rank_cert.title);
                                    });
                                }
                                //Image or Pdf Upload
                                if(active_form !== 'ResearchEdit' && previewDocData.length > 0){
                                    previewDocData.forEach(research_cert => {
                                        apiFormData.append('research_cert[]', research_cert.data, research_cert.title);
                                    });
                                    apiFormData.append('id', fieldFormDataResearch.id);
                                }
                            }
                        }
                    }
                }else if(active_form === 'SportNew'
                    || active_form === 'SportEdit'
                    || active_form === 'SportDoc'
                ){
                    const fieldFormDataSport = {...editFormData,
                        start_year: editFormData.start_year ? editFormData.start_year.id : '',
                        end_year: editFormData.end_year ? editFormData.end_year.id : ''
                    };
                    if(active_form !== 'SportDoc'){
                        validationRules = {...fieldValidationNewSport};
                        if(fieldFormDataSport.is_present !== true){
                            validationRules.end_year = `required`;
                        }
                        validation = ValidationHelper.validation(fieldFormDataSport, validationRules).error;
                    }
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        if (active_form !== 'SportDoc' && fieldFormDataSport.is_present === false && fieldFormDataSport.start_year > fieldFormDataSport.end_year){
                            errorDetails[form_type].push(`Start Year cannot be greater than End Year`);
                        }else if(active_form !== 'SportEdit' && previewDocData.length > process.env.REACT_APP_UPLOAD_FILE_QTY){
                            errorDetails[form_type].push(`Maximum ${process.env.REACT_APP_UPLOAD_FILE_QTY} Images/pdf Allowed`);
                        }else if(active_form === 'SportDoc' && previewDocData.length === 0){
                            errorDetails[form_type].push(`Upload atleast 1 Image/pdf`);
                        }else{
                            //Validate Classify
                            if(active_form !== 'SportDoc'){
                                validator = {};
                                if(fieldFormDataSport.sport_classify_id
                                    && typeof fieldFormDataSport.sport_classify_id.id !== "undefined"
                                    && fieldFormDataSport.sport_classify_id.id === 0
                                ){
                                    validator['sport_classify_name'] = 'required|max:100||How do you classify this';
                                }
                                if(fieldFormDataSport.sport_domain_id
                                    && typeof fieldFormDataSport.sport_domain_id.id !== "undefined"
                                    && fieldFormDataSport.sport_domain_id.id === 0
                                ){
                                    validator['sport_domain_name'] = 'required|max:100||Field/Category/Domain';
                                }
                                validation = ValidationHelper.validation(fieldFormDataSport, validator).error;
                                if(validation.length){
                                    errorDetails[form_type] = validation;
                                }else{
                                    if(fieldFormDataSport.sport_classify_id
                                        && typeof fieldFormDataSport.sport_classify_id.id !== "undefined"
                                        && fieldFormDataSport.sport_classify_id.id === 0
                                        && excludedTerm.includes(fieldFormDataSport.sport_classify_name.toLowerCase())
                                    ){
                                        validation.push("'How do you classify this' is Invalid");
                                    }
                                    if(fieldFormDataSport.sport_domain_id
                                        && typeof fieldFormDataSport.sport_domain_id.id !== "undefined"
                                        && fieldFormDataSport.sport_domain_id.id === 0
                                        && excludedTerm.includes(fieldFormDataSport.sport_domain_name.toLowerCase())
                                    ){
                                        validation.push('Field/Category/Domain is Invalid');
                                    }
                                    if(fieldFormDataSport.sport_rank_id
                                        && typeof fieldFormDataSport.sport_rank_id.id !== "undefined"
                                        && fieldFormDataSport.sport_rank_id.id > 0
                                        && (active_form === 'SportNew'
                                            || (active_form === 'SportEdit' && fieldFormDataSport.rank_id !== fieldFormDataSport.sport_rank_id.id))
                                        && rankDocData.length === 0
                                    ){
                                        validation.push("Please upload Achievement Certificate or Select option 'None' under 'Any Achievement'");
                                    }
                                }
                            }
                                
                            if(validation.length){
                                errorDetails[form_type] = validation;
                            }else{
                                //Send Data
                                if(active_form !== 'SportDoc'){
                                    apiFormData.append(`sport[sport_classify_id]`, fieldFormDataSport.sport_classify_id.id);
                                    // apiFormData.append(`sport[sport_select_type_id]`, fieldFormDataSport.sport_classify_id.select_type);
                                    apiFormData.append(`sport[sport_work_type_id]`, fieldFormDataSport.sport_work_type_id.id);
                                    apiFormData.append(`sport[sport_rank_id]`, fieldFormDataSport.sport_rank_id ? fieldFormDataSport.sport_rank_id.id : '');
                                    apiFormData.append(`sport[sport_domain_id]`, fieldFormDataSport.sport_domain_id.id);
                                    apiFormData.append(`sport[sport_level_id]`, fieldFormDataSport.sport_level_id.id);
                                    apiFormData.append(`sport[sport_classify_name]`, fieldFormDataSport.sport_classify_id.id === 0 ? fieldFormDataSport.sport_classify_name : '');
                                    apiFormData.append(`sport[sport_domain_name]`, fieldFormDataSport.sport_domain_id.id === 0 ? fieldFormDataSport.sport_domain_name : '');

                                    const basicDataKeys = ['name',
                                                            'venue',
                                                            'achievement',
                                                            'start_year',
                                                            'end_year',
                                                            'is_present'
                                                        ];
                                    if(active_form === 'SportEdit'){
                                        basicDataKeys.push('id');
                                    }
                                    for (var keySport in basicDataKeys) {
                                        if (fieldFormDataSport.hasOwnProperty(basicDataKeys[keySport])) {
                                            apiFormData.append(`sport[${basicDataKeys[keySport]}]`, fieldFormDataSport[basicDataKeys[keySport]]);
                                        }
                                    }
                                    rankDocData.forEach(rank_cert => {
                                        apiFormData.append('rank_cert[]', rank_cert.data, rank_cert.title);
                                    });
                                }
                                //Image or Pdf Upload
                                if(active_form !== 'SportEdit' && previewDocData.length > 0){
                                    previewDocData.forEach(sport_cert => {
                                        apiFormData.append('sport_cert[]', sport_cert.data, sport_cert.title);
                                    });
                                    apiFormData.append('id', fieldFormDataSport.id);
                                }
                            }
                        }
                    }
                }else if(active_form === 'AwardNew'
                    || active_form === 'AwardEdit'
                    || active_form === 'AwardDoc'
                ){
                    const fieldFormDataAward = {...editFormData,
                        year: editFormData.year ? editFormData.year.id : '',
                    };
                    if(active_form !== 'AwardDoc'){
                        validationRules = {...fieldValidationNewAward};
                        validation = ValidationHelper.validation(fieldFormDataAward, validationRules).error;
                    }
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        if(active_form !== 'AwardEdit' && previewDocData.length > process.env.REACT_APP_UPLOAD_FILE_QTY){
                            errorDetails[form_type].push(`Maximum ${process.env.REACT_APP_UPLOAD_FILE_QTY} Images/pdf Allowed`);
                        }else if(active_form === 'AwardDoc' && previewDocData.length === 0){
                            errorDetails[form_type].push(`Upload atleast 1 Image/pdf`);
                        }else{
                            //Validate Classify
                            if(active_form !== 'AwardDoc'){
                                validator = {};
                                if(fieldFormDataAward.award_domain_id
                                    && typeof fieldFormDataAward.award_domain_id.id !== "undefined"
                                    && fieldFormDataAward.award_domain_id.id === 0
                                ){
                                    validator['award_domain_name'] = 'required|max:100||Field/Domain';
                                }
                                validation = ValidationHelper.validation(fieldFormDataAward, validator).error;
                                if(validation.length){
                                    errorDetails[form_type] = validation;
                                }else{
                                    if(fieldFormDataAward.award_domain_id
                                        && typeof fieldFormDataAward.award_domain_id.id !== "undefined"
                                        && fieldFormDataAward.award_domain_id.id === 0
                                        && excludedTerm.includes(fieldFormDataAward.award_domain_name.toLowerCase())
                                    ){
                                        validation.push('Field/Domain is Invalid');
                                    }
                                    if(fieldFormDataAward.award_rank_id
                                        && typeof fieldFormDataAward.award_rank_id.id !== "undefined"
                                        && fieldFormDataAward.award_rank_id.id > 0
                                        && (active_form === 'AwardNew'
                                            || (active_form === 'AwardEdit' && fieldFormDataAward.rank_id !== fieldFormDataAward.award_rank_id.id))
                                        && rankDocData.length === 0
                                    ){
                                        validation.push("Please upload Achievement Certificate or Select option 'None' under 'Any Achievement'");
                                    }
                                }
                            }
                                
                            if(validation.length){
                                errorDetails[form_type] = validation;
                            }else{
                                //Send Data
                                if(active_form !== 'AwardDoc'){
                                    apiFormData.append(`award[award_classify_id]`, fieldFormDataAward.award_classify_id.id);
                                    apiFormData.append(`award[award_domain_id]`, fieldFormDataAward.award_domain_id.id);
                                    apiFormData.append(`award[award_level_id]`, fieldFormDataAward.award_level_id.id);
                                    apiFormData.append(`award[award_rank_id]`, fieldFormDataAward.award_rank_id ? fieldFormDataAward.award_rank_id.id : '');
                                    apiFormData.append(`award[award_domain_name]`, fieldFormDataAward.award_domain_id.id === 0 ? fieldFormDataAward.award_domain_name : '');
                                        
                                    const basicDataKeys = ['institute_name',
                                                            'award_name',
                                                            'why_awarded',
                                                            'year',
                                                        ];
                                    if(active_form === 'AwardEdit'){
                                        basicDataKeys.push('id');
                                    }
                                    for (var keyAward in basicDataKeys) {
                                        if (fieldFormDataAward.hasOwnProperty(basicDataKeys[keyAward])) {
                                            apiFormData.append(`award[${basicDataKeys[keyAward]}]`, fieldFormDataAward[basicDataKeys[keyAward]]);
                                        }
                                    }
                                    rankDocData.forEach(rank_cert => {
                                        apiFormData.append('rank_cert[]', rank_cert.data, rank_cert.title);
                                    });
                                }
                                //Image or Pdf Upload
                                if(active_form !== 'AwardEdit' && previewDocData.length > 0){
                                    previewDocData.forEach(award_cert => {
                                        apiFormData.append('award_cert[]', award_cert.data, award_cert.title);
                                    });
                                    apiFormData.append('id', fieldFormDataAward.id);
                                }
                            }
                        }
                    }
                }else if(active_form === 'LifeExpNew'
                    || active_form === 'LifeExpEdit'
                    || active_form === 'LifeExpDoc'
                ){
                    const fieldFormDataLifeExp = {...editFormData};
                    if(active_form !== 'LifeExpDoc'){
                        validationRules = active_form === 'LifeExpEdit' ? {...fieldValidationEditLifeExp} : {...fieldValidationNewLifeExp};
                        validation = ValidationHelper.validation(fieldFormDataLifeExp, validationRules).error;
                    }
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        if(active_form !== 'LifeExpDoc'){
                            if(previewDocData.length > process.env.REACT_APP_UPLOAD_FILE_QTY){
                                errorDetails[form_type].push(`Maximum ${process.env.REACT_APP_UPLOAD_FILE_QTY} Images/Photos Allowed`);
                            }
                            //Theme
                            const themeIds = [];
                            if(fieldFormDataLifeExp.theme_id
                                && fieldFormDataLifeExp.theme_id.length
                            ){
                                fieldFormDataLifeExp.theme_id.forEach(theme => {
                                    themeIds.push(theme.id);
                                });
                                apiFormData.append(`theme_ids`, themeIds);
                            }
                            if(themeIds.length > 2){
                                errorDetails[form_type].push(`Maximum 2 Theme experience selection allowed`);
                            }
                            //Emotion
                            const emotionIds = [];
                            if(fieldFormDataLifeExp.emotion_id
                                && fieldFormDataLifeExp.emotion_id.length
                            ){
                                fieldFormDataLifeExp.emotion_id.forEach(emotion => {
                                    emotionIds.push(emotion.id);
                                });
                                apiFormData.append(`emotion_ids`, emotionIds);
                            }
                            if(emotionIds.length > 2){
                                errorDetails[form_type].push(`Maximum 2 Emotion selection allowed`);
                            }
                        }else{
                            if(previewDocData.length === 0){
                                errorDetails[form_type].push(`Upload atleast 1 Image/Photo`);
                            }
                        }
                        if(!errorDetails[form_type].length){
                            if(active_form !== 'LifeExpDoc'){
                                const basicDataKeys = ['experience',
                                                        'impact',
                                                        'learning'
                                                    ];
                                if(active_form === 'LifeExpEdit'){
                                    basicDataKeys.push('id');
                                }
                                for (var keyLifeExp in basicDataKeys) {
                                    if (fieldFormDataLifeExp.hasOwnProperty(basicDataKeys[keyLifeExp])) {
                                        apiFormData.append(`life_exp[${basicDataKeys[keyLifeExp]}]`, fieldFormDataLifeExp[basicDataKeys[keyLifeExp]]);
                                    }
                                }
                            }
                            if(active_form === 'LifeExpNew'
                                && fieldFormDataLifeExp.competition_id
                            ){
                                apiFormData.append(`competition_id`, fieldFormDataLifeExp.competition_id.id);
                            }
                            //Image Upload
                            if(active_form !== 'LifeExpEdit' && previewDocData.length > 0){
                                previewDocData.forEach(life_cert => {
                                    apiFormData.append('lifeexp_cert[]', life_cert.data, life_cert.title);
                                });
                                apiFormData.append('id', fieldFormDataLifeExp.id);
                            }
                        }
                    }
                }else if(active_form === 'SocialNew'
                    || active_form === 'SocialEdit'
                ){
                    const fieldFormDataSocial = {...editFormData};
                    validationRules = {...fieldValidationNewSocial};
                    validation = ValidationHelper.validation(fieldFormDataSocial, validationRules).error;
                    
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else if(!(/\d/.test(fieldFormDataSocial.kpi))){
                        errorDetails[form_type] = ['Please revisit your KPI and ensure that it has a specific number that you are measuring your success.'];
                    }else if(fieldFormDataSocial.date  === "Invalid date"){
                        errorDetails[form_type].push(`Date is Required`);
                    }else{
                        const basicDataKeys = ['name',
                                                'kpi',
                                                'achievement',
                                                'learning',
                                                'date'
                                            ];

                        if(active_form === 'SocialEdit'){
                            basicDataKeys.push('id');
                        }
                        for (var keySocial in basicDataKeys) {
                            if (fieldFormDataSocial.hasOwnProperty(basicDataKeys[keySocial])) {
                                apiFormData.append(`social[${basicDataKeys[keySocial]}]`, fieldFormDataSocial[basicDataKeys[keySocial]]);
                            }
                        }
                    }
                }else if(active_form === 'General'){
                    const fieldFormDataGen = {...editFormData};
                    validationRules = {...fieldValidationGen};
                    validation = ValidationHelper.validation(fieldFormDataGen, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else if(fieldFormDataGen.dob
                            && (fieldFormDataGen.dob < '1924-01-01'
                                || moment().diff(fieldFormDataGen.dob, 'years',false) < 5
                                || fieldFormDataGen.date  === "Invalid date"
                            )){
                        errorDetails[form_type] = ['Please check and re-enter DOB (Date of Birth)'];
                    }else{
                        // const fieldFormDataGeneral = {...formDataGeneral,
                        //     country_id: formDataGeneral.country_id.id,
                        //     phone_code_id: formDataGeneral.phone_code_id.id,
                        //     // school_name_id: formDataGeneral.school_name_id.id,
                        // };
                        const basicDataKeys = ['first_name',
                                                'last_name',
                                                // 'country_id',
                                                // 'school_name_id',
                                                'dob',
                                                'language'
                                            ];
                        for (var key in basicDataKeys) {
                            if (fieldFormDataGen.hasOwnProperty(basicDataKeys[key])) {
                                apiFormData.append(`general_data[${basicDataKeys[key]}]`, fieldFormDataGen[basicDataKeys[key]]);
                            }
                        }
                    }
                }else if(active_form === 'School'){
                    fieldFormDataScl = {...editFormData};
                    validationRules = (fieldFormDataScl.institute_type_id
                                        && fieldFormDataScl.institute_type_id.id
                                        )
                                            ? fieldValidationShl[fieldFormDataScl.institute_type_id.id]
                                            : fieldFormDataScl[0]
                                        ;
                    validation = ValidationHelper.validation(fieldFormDataScl, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        validator = {};
                        if(fieldFormDataScl.institute_type_id.id === 1){//School
                            if(fieldFormDataScl.school_id
                                && typeof fieldFormDataScl.school_id.id !== "undefined"
                                && fieldFormDataScl.school_id.id === 0
                            ){
                                validator['school_name'] = 'required|max:100';
                            }
                            if(fieldFormDataScl.branch_id
                                && typeof fieldFormDataScl.branch_id.id !== "undefined"
                                && fieldFormDataScl.branch_id.id === 0
                            ){
                                validator['branch_name'] = 'required|max:100';
                            }

                        }else if(fieldFormDataScl.institute_type_id.id === 2){
                            if(fieldFormDataScl.course_id
                                && typeof fieldFormDataScl.course_id.id !== "undefined"
                                && fieldFormDataScl.course_id.id === 0
                            ){
                                validator['course_name'] = 'required|max:100';
                            }
                        }
                        validation = ValidationHelper.validation(fieldFormDataScl, validator).error;
                        if(validation.length){
                            errorDetails[form_type] = validation;
                        }else{
                            if(fieldFormDataScl.institute_type_id.id === 1){
                                if(fieldFormDataScl.school_id
                                    && typeof fieldFormDataScl.school_id.id !== "undefined"
                                    && fieldFormDataScl.school_id.id === 0
                                    && excludedTerm.includes(fieldFormDataScl.school_name.toLowerCase())
                                ){
                                    validation.push('School Name is Invalid');
                                }
                                if(fieldFormDataScl.branch_id
                                    && typeof fieldFormDataScl.branch_id.id !== "undefined"
                                    && fieldFormDataScl.branch_id.id === 0
                                    && fieldFormDataScl.branch_name
                                    && excludedTerm.includes(fieldFormDataScl.branch_name.toLowerCase())
                                ){
                                    validation.push('Branch is Invalid');
                                }
                            }else if(fieldFormDataScl.institute_type_id.id === 2){
                                if(excludedTerm.includes(fieldFormDataScl.college_name.toLowerCase())){
                                    validation.push('College Name is Invalid');
                                }
                                if(fieldFormDataScl.course_id
                                    && typeof fieldFormDataScl.course_id.id !== "undefined"
                                    && fieldFormDataScl.course_id.id === 0
                                    && excludedTerm.includes(fieldFormDataScl.course_name.toLowerCase())
                                ){
                                    validation.push('Course is Invalid');
                                }
                            }else if(fieldFormDataScl.institute_type_id.id === 3){
                                if(excludedTerm.includes(fieldFormDataScl.org_name.toLowerCase())){
                                    validation.push('Organisation Name is Invalid');
                                }
                            }
                            if(validation.length){
                                errorDetails[form_type] = validation;
                            }else{
                                //Send Data
                                apiFormData.append(`school_data[institute_type_id]`, fieldFormDataScl.institute_type_id.id);
                                if(fieldFormDataScl.institute_type_id.id === 1){
                                    apiFormData.append(`school_data[school_id]`, fieldFormDataScl.school_id.id);
                                    apiFormData.append(`school_data[branch_id]`, fieldFormDataScl.branch_id ? fieldFormDataScl.branch_id.id : '');
                                    apiFormData.append(`school_data[school_name]`, fieldFormDataScl.school_id.id === 0 ? fieldFormDataScl.school_name : '');
                                    apiFormData.append(`school_data[branch_name]`, fieldFormDataScl.branch_id.id === 0 ? fieldFormDataScl.branch_name : '');
                                    apiFormData.append(`school_data[class_id]`, fieldFormDataScl.class_id.id);
                                }else if(fieldFormDataScl.institute_type_id.id === 2){
                                    apiFormData.append(`school_data[college_name]`, fieldFormDataScl.college_name);
                                    apiFormData.append(`school_data[course_id]`, fieldFormDataScl.course_id.id);
                                    apiFormData.append(`school_data[course_name]`, fieldFormDataScl.course_id.id === 0 ? fieldFormDataScl.course_name : '');
                                }else if(fieldFormDataScl.institute_type_id.id === 3){
                                    apiFormData.append(`school_data[org_name]`, fieldFormDataScl.org_name);
                                }
                            }
                        }
                    }
                }
            }
            // console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Send Data to Server
                if(form_type === 'Master'
                    || form_type === 'UpdateDoc'
                ){
                    // console.log(active_form, 'ssssssssssssssssssssssssssssssss')
                    apiFormData.append('update_type', active_form.toLowerCase());
                }else{
                    apiFormData.append('update_type', form_type.toLowerCase());
                }                
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`portfolio/update`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    if(form_type === 'Pic'
                        || active_form === 'General'
                    ){
                        if(form_type === 'Pic'){
                            setPreviewPicImg(null);
                            setFormDataGeneral({...formDataGeneral, logo: updateFellow.data.image})
                        }
                        // Dispatch
                        dispatch(update_user_info(updateFellow.data, async cb_data => {
                            // console.log('cb_data', cb_data)
                        }));
                        if(active_form === 'General'){
                            toggleModal(e, 'Master', false);
                            setFormDataGeneral({...formDataGeneral,
                                                first_name: updateFellow.data.first_name,
                                                last_name: updateFellow.data.last_name,
                                                dob: updateFellow.data.dob,
                                                language: updateFellow.data.language
                                            });
                        }
                    }else if(
                        // active_form === 'EduNew'
                        // || active_form === 'EduEdit'
                        // || active_form === 'EduDoc'
                        // || active_form === 'ExpNew'
                        // || active_form === 'ExpEdit'
                        // || active_form === 'ExpDoc'
                        // || active_form === 'ResearchNew'
                        // || active_form === 'ResearchEdit'
                        // || active_form === 'AwardNew'
                        // || active_form === 'AwardEdit'
                        // || active_form === 'AwardDoc'
                        // || active_form === 'SportNew'
                        // || active_form === 'SportEdit'
                        // || active_form === 'SportDoc'
                        // || active_form === 'LifeExpNew'
                        // || active_form === 'LifeExpEdit'
                        // || active_form === 'LifeExpNew'
                        // || active_form === 'LifeExpEdit'
                        form_type === 'Master'
                    ){
                        toggleModal(e, 'Master', false);
                        if(active_form !== "General"){
                            if(active_form === "School"){
                                updateInpState(active_form, fieldFormDataScl);
                            }else{
                                updateInpState(active_form, updateFellow.data.data);
                            }
                        }
                    }else if(form_type === 'Unique'){
                        toggleModal(e, 'passion', false);
                        setPassionFetchData([]);
                        var uniqueData = [];
                        passionFetchData.forEach(skill => {
                            // uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
                            uniqueData.push({passion_id : {id: skill.passion_id, title: skill.title, desc: skill.unique_ability}});
                        });
                        setFormDataUnique(uniqueData);
                    }else if(form_type === 'Recom'){
                        setFormDataRecom({...fieldValuesRecom});
                        // toggleModal(e, 'Recom', false);
                        updateInpState(form_type, updateFellow.data.data);
                    }else if(form_type === 'UpdateDoc'){
                        setPreviewDoc([]);
                        updateInpState(active_form, updateFellow.data.data);
                    }else if(form_type === 'Achieve'){
                        // const sessionId = formDataAchieve.session_id.id;
                        toggleModal(e, 'glosco', false);
                        if(updateFellow.data.data.show_alert
                            && updateFellow.data.data.show_alert === 1
                        ){
                            // alertResponseMessage({alert_type: 3, message: "Once your inputs are approved by your counsellor, it will show up in your portfolio."});
                            alert("Once your inputs are approved by your counsellor, it will show up in your portfolio.");
                        }
                        setGloscoData(updateFellow.data.data.glosco_data);
                        //Update the session Options
                        if(updateFellow.data.data.glosco_session.length){
                            const sessionIds = updateFellow.data.data.glosco_session[0].session_ids;
                            generateSessionOption('remove', sessionIds);
                        }
                    }
                                                
                    if(typeof updateFellow.data.section_ids !== "undefined"
                        && updateFellow.data.section_ids
                    ){
                        const sectArray = updateFellow.data.section_ids.split(",");
                        // setCompletedSec(sectArray.length);
                        setCompletedSec(sectArray);
                    }
                    // if(typeof updateFellow.data.referral_code !== "undefined"
                    //     && updateFellow.data.referral_code
                    // ){
                    //     setReferralCode(updateFellow.data.referral_code);
                    // }
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(error){
            console.error(error);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `portfolio|edit|editPortfolio|${error}`});
        }
    }
    //Format School Data according to selected Institute Type
    const formatSchoolFields = (data) => {
        try{
            if(data.institute_type_id){
                if(data.institute_type_id.id === 1){//School
                    if(!schoolData.length){
                        fetchSelectList('school', 'schools');
                    }
                    if(!branchData.length){
                        fetchSelectList('branch', 'branches');
                    }
                    if(!classData.length){
                        fetchSelectList('classes', 'classes');
                    }
                }else if(data.institute_type_id.id === 2){//College
                    if(!courseData.length){
                        fetchSelectList('classes', 'classes');
                    }
                }
            }
            return data;
        }catch(error){
            console.error(error);
            error_api_call({error: `portfolio|edit|formatSchoolFields|${error}`});
            return;
        }
    };
    //Load dropdown Data
    const loadDropdownData = (section_type) => {
        try{
            switch (section_type) {
                case 'Edu':
                    if(!classifyDataEdu.classify.length){
                        fetchSelectList('edu_classify', 'custom/form-options/edu_classify', true);
                    }
                    break;
                case 'Exp':
                    if(!classifyDataExp.classify.length){
                        fetchSelectList('exp_classify', 'custom/form-options/exp_classify', true);
                    }
                    break;
                case 'Research':
                    if(!classifyDataResearch.classify.length){
                        fetchSelectList('research_classify', 'custom/form-options/research_classify', true);
                    }
                    break;
                case 'Award':
                    if(!classifyDataAward.classify.length){
                        fetchSelectList('award_classify', 'custom/form-options/award_classify', true);
                    }
                    break;
                case 'Sport':
                    if(!classifyDataSport.classify.length){
                        fetchSelectList('sport_classify', 'custom/form-options/sport_classify', true);
                    }
                    break;
                case 'LifeExp':
                    //Get Emotion and theme Data
                    // var emoData = emotionData;
                    if(!emotionData.length){
                        fetchSelectList('emotions', 'form-options/2', true);
                        // console.log('fetchOpData', 7)
                        // if(fetchOpData.data.length){
                        //     emoData = fetchOpData.data;
                        //     setEmotionData([...fetchOpData.data]);
                        // }
                    }
                    //Theme
                    // var themeDetails = themeData;
                    if(!themeData.length){
                        fetchSelectList('themes', 'form-options/1', true);
                    //     if(fetchOpTheme.data.length){
                    //         themeDetails = fetchOpTheme.data;
                    //         setThemeData([...fetchOpTheme.data]);
                    //     }
                    }
                    // setFormDataOption({emotion_id: {value: 'id', label: 'emotion', data: [...emotionData]},
                                        // theme_id: {value: 'id', label: 'emotion', data: [...themeData]}});
                    break;
                default: 
            }
        }catch(error){
            console.error(error)
        }
    }
    //Load Sport Domain Data based on classification selected
    const loadSportDomain = (sport_classify_id) => {
        try{
            const domainList = [...classifyDataSport.domain];
            var domainArrMatch = [];
            if(sport_classify_id
                && sport_classify_id > 0
            ){
                domainList.forEach(domain_data => {
                    if(domain_data.select_type === sport_classify_id){
                        domainArrMatch.push(domain_data);
                    }
                });
                
            }
            setSportDomain([...domainArrMatch, {id: 0, label: "Other"}]);
        }catch(error){
            console.error(error)
        }
    }
    // Toggle Modal
    const toggleModal = async(e, modal_type, toggleState, index='', form_type='', prevent_def=true) => {
        try{
            if(toggleState
                && prevent_def
            ){
                e.preventDefault();
            }
            // setModalToggle(!modalToggle);
            if(modal_type === 'Master'
                && toggleState
            ){
                var data = {};
                switch (form_type) {
                    case 'EduNew':
                        data = {...fieldValuesNewEdu};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewEdu]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'new'});
                        loadDropdownData('Edu');
                        break;
                    case 'EduEdit':
                        data = {...formDataEdu[index]};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewEdu]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'edit'});
                        loadDropdownData('Edu');
                        break;
                    case 'EduDoc':
                        data = {...formDataEdu[index]};
                        // setPreviewDoc([]);
                        setModalSection({...modalSecShow, form: false, ...commSecCertData, type: 'doc_view'});
                        break;
                    case 'ExpNew':
                        data = {...fieldValuesNewExp};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewExp]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'new'});
                        loadDropdownData('Exp');
                        break;
                    case 'ExpEdit':
                        data = {...formDataExp[index]};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewExp]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'edit'});
                        loadDropdownData('Exp');
                        break;
                    case 'ExpDoc':
                        data = {...formDataExp[index]};
                        // setPreviewDoc([]);
                        setModalSection({...modalSecShow, form: false, ...commSecCertData, type: 'doc_view'});
                        break;
                    case 'ResearchNew':
                        data = {...fieldValuesNewResearch};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewResearch]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'new'});
                        loadDropdownData('Research');
                        break;
                    case 'ResearchEdit':
                        data = {...formDataResearch[index]};
                        setMasterFieldData([...fieldNewResearch]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'edit'});
                        loadDropdownData('Research');
                        break;
                    case 'ResearchDoc':
                        data = {...formDataResearch[index]};
                        // setPreviewDoc([]);
                        setModalSection({...modalSecShow, form: false, ...commSecCertData, type: 'doc_view'});
                        break;
                    case 'AwardNew':
                        data = {...fieldValuesNewAward};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewAward]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'new'});
                        loadDropdownData('Award');
                        break;
                    case 'AwardEdit':
                        data = {...formDataAward[index]};
                        setMasterFieldData([...fieldNewAward]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'edit'});
                        loadDropdownData('Award');
                        break;
                    case 'AwardDoc':
                        data = {...formDataAward[index]};
                        // setPreviewDoc([]);
                        setModalSection({...modalSecShow, form: false, ...commSecCertData, type: 'doc_view'});
                        break;
                    case 'SportNew':
                        data = {...fieldValuesNewSport};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewSport]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'new'});
                        loadDropdownData('Sport');
                        break;
                    case 'SportEdit':
                        data = {...formDataSport[index]};
                        setMasterFieldData([...fieldNewSport]);
                        setModalSection({...modalSecShow, form: true, ...commSecCertData, type: 'edit'});
                        loadDropdownData('Sport');
                        break;
                    case 'SportDoc':
                        data = {...formDataSport[index]};
                        // setPreviewDoc([]);
                        setModalSection({...modalSecShow, form: false, ...commSecCertData, type: 'doc_view'});
                        break;
                    case 'LifeExpNew':
                        data = {...fieldValuesNewLifeExp};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewLifeExp]);
                        setModalSection({...modalSecShow, form: true, cert: true, type: 'new', doc_type: 'image'});
                        if(!competeData.length){
                            fetchSelectList('competitions', 'competitions');
                        }
                        loadDropdownData('LifeExp');
                        break;
                    case 'LifeExpEdit':
                        data = {...formDataLifeExp[index]};
                        setMasterFieldData([...fieldEditLifeExp]);
                        setModalSection({...modalSecShow, form: true, cert: true, type: 'edit', doc_type: 'image'});
                        loadDropdownData('LifeExp');
                        break;
                    case 'LifeExpDoc':
                        data = {...formDataLifeExp[index]};
                        // setPreviewDoc([]);
                        setModalSection({...modalSecShow, form: false, cert: true, type: 'doc_view', doc_type: 'image'});
                        break;
                    case 'SocialNew':
                        data = {...fieldValuesNewSocial};
                        // setPreviewDoc([]);
                        setMasterFieldData([...fieldNewSocial]);
                        setModalSection({...modalSecShow, form: true, cert: false, type: 'new'});
                        break;
                    case 'SocialEdit':
                        data = {...formDataSocial[index]};
                        setMasterFieldData([...fieldNewSocial]);
                        setModalSection({...modalSecShow, form: true, cert: false, type: 'edit'});
                        break;
                    case 'General':
                        data = {...formDataGeneral};
                        setMasterFieldData([...fieldEditGen]);
                        setModalSection({...modalSecShow, form: true, cert: false, type: 'edit'});
                        break;
                    case 'School':
                        data = {...formDataSchool};
                        // console.log(fieldEditSchool, 'fieldEditSchoolfieldEditSchool')
                        if(data.institute_type_id
                            && data.institute_type_id.id
                        ){
                            setMasterFieldData(fieldEditSchool[data.institute_type_id.id]);
                        }else{
                            setMasterFieldData(fieldEditSchool[0]);
                        }
                        formatSchoolFields(data);
                        // await fetchSelectList('emotions', 'form-options/2');
                        // setFormDataOption({
                        //     institute_type_id: {value: 'id', label: 'type', data: [...instituteType]},
                        //     school_id: {value: 'id', label: 'school_name', data: [...schoolData]},
                        //     branch_id: {value: 'id', label: 'branch_name', data: [...branchData]},
                        //     class_id: {value: 'id', label: 'class_name', data: [...classData]},
                        //     course_id: {value: 'id', label: 'class_name', data: [...courseData]},
                        // });
                        setModalSection({...modalSecShow, form: true, cert: false, type: 'edit'});
                        break;
                    default: 
                        break;
                }
                setPreviewDoc([]);
                setRankDocData([]);
                setErrorData({...errorData, Master: [], UpdateDoc: []});
                setActiveForm(form_type);
                setEditFormData({...data});
            }else if(modal_type === 'glosco'){
                setFormDataAchieve({...initialGloscoData});
                setErrorData({...errorData, Achieve: []});
                // Get Cohort Number List
                if(!cohortData.length){
                    const numList = [];
                    for (let i = 1; i <= 100; i++) {
                        numList.push({id: i, number: i});
                    }
                    setCohortData(numList);
                }
            }
            if((modal_type === 'glosco'
                    && !prevent_def)
                || (form_type === 'LifeExpNew'
                    && !prevent_def)
            ){
                setTimeout(() => {
                    setModalToggle({...modalToggle, [modal_type]: toggleState});
                }, 300);
            }else{
                setModalToggle({...modalToggle, [modal_type]: toggleState});
            }
            //Remove Context
            if(!toggleState
                && form_type !== 'General'
                && form_type !== 'School'
            ){
                setEvaPayload({...askevaPayload, context: initialContext});
            }
        }catch(error){
            console.error(error)
        }
    }
    //Fetch Mapping Data
    const fetchMappingData = async(e) => {
        try{
            e.preventDefault();
            setErrorData({...errorData, 'Unique': []});
            setIsProcess({...isProcess, 'Unique': true});
            // Validation
            const email = formDataPassion.email;
            const validation = ValidationHelper.validation({...formDataPassion}, {email: 'required|email|max:150'}).error;
            if(validation.length){
                // errorDetails['Unique'] = validation;
                setErrorData({...errorData, 'Unique': validation});
            }else{
                const mappingApiData = await post_api_call(`port/passion-scores`, {email});
                if(mappingApiData.status === 200){
                    setPassionFetchData([...mappingApiData.data]);
                }else if(mappingApiData.status === 404){
                    alertResponseMessage({alert_type: 2, message: "Mapping Data not Found"});
                    setErrorData({...errorData, 'Unique': ["Mapping Data not Found"]});
                }else{
                    alertResponseMessage({alert_type: 2, message: "Error fetching Data, Please try after some time"});
                }
            }
            setIsProcess({...isProcess, 'Unique': false});
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error fetching Data, Please try after some time"});
        }
    }
    //Remove Uploaded Image/Doc
    const removeUploadedDoc = (event, img_indx, form_type='') => {
        try{
            event.preventDefault();
            switch (form_type) {
                case 'Master':
                    setErrorData({...errorData, Master : []});
                    var uploadedPics = [...previewDocData];
                    uploadedPics.splice(img_indx, 1);
                    setPreviewDoc([...uploadedPics]);
                    if(uploadedPics.length === 0
                        && (activeEditForm === 'EduDoc'
                            || activeEditForm === 'ExpDoc')
                    ){
                        setBtnDisabled({...isBtnDisabled, UpdateDoc: true});
                    }
                    break;
                default: 
            }
        }catch(e){
            error_api_call({error: `portfolio/edit|removeUploadedDoc|${e}`});
        }
    }
    //Preview Uploaded Doc
    //Upload Profile Image Preview
    const previewDoc = (event, form_type, file_type) => {
        try{
            const files = event.target.files;
            if(files.length > 0){
                const uploadedImg = [];
                const documetAllowedExt = file_type === 'image' ?  ["png", "jpg", "jpeg"] : ["png", "jpg", "jpeg", "pdf"];
                const imageExt = ["image/png", "image/jpg", "image/jpeg"];
                const allowedSize = process.env.REACT_APP_UPLOAD_FILE_SIZE;//2MB-200000
                const allowedQty = form_type === 'Pic' ? 1 : process.env.REACT_APP_UPLOAD_FILE_QTY - previewDocData.length;
                if(allowedQty){
                    for(let i=0; i<files.length; i++){
                        const imgFile = files[i];
                        var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                        //Check for file size and extension
                        // const size = Math.round((imgFile.size / 1024));
                        // console.log(size, 'size')
                        if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                            const imgData = {data: imgFile,
                                            src: URL.createObjectURL(imgFile),
                                            title: imgFile.name,
                                            is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                            ext
                                        };
                            uploadedImg.push(imgData);
                        }else{
                            alertResponseMessage({alert_type: 2,
                                                message: `${file_type === 'image'
                                                            ? `Photo/Image allowed size is ${process.env.REACT_APP_FILE_SIZE} and it must be an image.`
                                                            : `Image/Doc allowed size is ${process.env.REACT_APP_FILE_SIZE} and it must be an image/pdf.`
                                                }`});
                        }
                        // if(form_type !== 'Pic' && uploadedImg.length >= 2){
                        if(uploadedImg.length >= allowedQty){
                            break;
                        }
                    }
                }
                
                if(form_type === 'Pic'){
                    if(uploadedImg.length > 0){
                        setPreviewPicImg({...uploadedImg[0]});
                        setBtnDisabled({...isBtnDisabled, Pic: false});
                    }else{
                        setPreviewPicImg(null);
                        setBtnDisabled({...isBtnDisabled, Pic: true});
                    }
                }else if(form_type === 'rank_doc_name'){
                    if(uploadedImg.length > 0){
                        setRankDocData([...uploadedImg]);
                        setEditFormData({...editFormData, rank_doc_name : uploadedImg[0].title});
                        setBtnDisabled({...isBtnDisabled, Master: false});
                    }else{
                        setRankDocData([]);
                        setEditFormData({...editFormData, rank_doc_name : ''});
                    }
                    setErrorData({...errorData, Master: []});
                }else{
                    const attemptQty = previewDocData.length + files.length;
                    if(attemptQty > process.env.REACT_APP_UPLOAD_FILE_QTY){
                        alertResponseMessage({alert_type: 2,
                                            message: `Maximum 2 Images${file_type === 'image' ? '' : '/pdf'} Allowed`});
                    }
                    const newImgData = previewDocData.concat(uploadedImg);
                    setPreviewDoc([...newImgData]);
                    if(newImgData.length > 0){
                        setBtnDisabled({...isBtnDisabled, [form_type]: false});
                    }
                }
            }
        }catch(e){
            console.log(e)
            form_type === 'Pic' ? setPreviewPicImg(null) : setPreviewDoc([]);
            error_api_call({error: `portfolio|edit|previewDoc|${e}`});
        }
    }
    //Remove uploaded Rank Doc
    const removeRankDoc = (event, form_type) => {
        try{
            event.preventDefault();
            setRankDocData([]);
            setEditFormData({...editFormData, [form_type] : ''});
        }catch(error){
            error_api_call({error: `portfolio/edit|removeRankDoc|${error}`});
        }
    }
    //Confirm Deletion
    const confirmDeletion = async (event, form_type, column_id) => {
        try{
            event.preventDefault();
            // if(deleteImgData.length > 0){
                if (window.confirm("Are you sure you want to Delete? Once deleted information cannot be Retrieved.")) {
                    //Call API to delete
                    toggleBodyLoader('load');
                    const apiFormData = {
                        id: column_id,
                        form_type: form_type.toLowerCase()
                    };
                    const deleteEntry = await post_api_call(`port/delete`, apiFormData);
				    if(deleteEntry.status === 200){
                        alertResponseMessage({alert_type: 1, message: "Information Deleted Successfully"});
                        switch (form_type) {
                            case 'DelEdu':
                                updateInpState('Edu', deleteEntry.data.data);
                                break;
                            case 'DelExp':
                                updateInpState('Exp', deleteEntry.data.data);
                                break;
                            case 'DelResearch':
                                updateInpState('Research', deleteEntry.data.data);
                                break;
                            case 'DelAward':
                                updateInpState('Award', deleteEntry.data.data);
                                break;
                            case 'DelSport':
                                updateInpState('Sport', deleteEntry.data.data);
                                break;
                            case 'DelLifeExp':
                                updateInpState('LifeExp', deleteEntry.data.data);
                                break;
                            case 'DelSocial':
                                updateInpState('Social', deleteEntry.data.data);
                                break;
                            default:
                                break;
                        }
                        if(typeof deleteEntry.data.section_ids !== "undefined"
                            && deleteEntry.data.section_ids
                        ){
                            const sectArray = deleteEntry.data.section_ids.split(",");
                            setCompletedSec(sectArray);

                        }
                    }else{
                        alertResponseMessage({alert_type: 2, message: "Error deleting! Please try after some time."});
                    }
                    toggleBodyLoader('remove');
                }
            // }else{
            //     setErrorData({...errorData, [form_type]: ['Please select atleast one Picture to delete.']});
            //     alertResponseMessage({alert_type: 2, message: "Please select atleast one Picture to delete."});
            // }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error deleting! Please try after some time."});
            error_api_call({error: `portfolio/edit|confirmDeletion|${e}`});
        }
    }
    //Navigate to Specific Section
    // const navigateSec = (id) => {
    //     try{
    //         console.log(id)
    //         // document.querySelector(`#${id}`).click();
    //         SetToggleAccor({...toggleAccor, [id]: 1})
    //     }catch(e){
    //         console.log(e)
    //     }
    // }
    //Glosco Option: Session
    const generateSessionOption = (type, session_arr) => {
        try{
            var sessionOpArray = [];
            const sessionData = [
                {id: 1, label: "Induction 1"},
                {id: 2, label: "Induction 2"},
                {id: 3, label: "Speaker’s Council 1"},
                {id: 4, label: "Speaker’s Council 2"},
                {id: 5, label: "Speaker’s Council 3"},
                {id: 6, label: "Speaker’s Council 4"},
                {id: 7, label: "Speaker’s Council 5"},
                {id: 8, label: "Speaker’s Council 6"},
                {id: 9, label: "Team Induction 1"},
                {id: 10, label: "Team Induction 2"},
                {id: 11, label: "Team Council"},
                {id: 12, label: "Diplomatic Council"},
                {id: 13, label: "Debrief"},
                {id: 14, label: "Excellence Summit"}
            ];
            sessionData.forEach(session_d => {
                if(!session_arr.length || session_arr.indexOf(session_d.id) === -1){
                    sessionOpArray.push(session_d);
                }
            });

            setSessionOption([...sessionOpArray]);
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error loading data."});
            error_api_call({error: `portfolio/edit|generateSessionOption|${e}`});
        }
    }
    //Glosco Options
    const getGloscoRole = (session_id) => {
        try{
            var optionData = [];
            var optionAwardData = [];
            const roleOne = [1, 2, 9, 10, 13];
            if(roleOne.indexOf(session_id.id) !== -1){
                optionData = [
                    {id: 15, label: "Absent"},
                    {id: 16, label: "Member"}
                ];
            }else if(session_id.id >= 3 && session_id.id <= 8){
                optionData = [
                    {id: 15, label: "Absent"},
                    {id: 17, label: "Chair"},
                    {id: 18, label: "Presenter"},
                    {id: 19, label: "Challenger"},
                    {id: 20, label: "Mentor"},
                    {id: 16, label: "Member"}
                ];
            }else if(session_id.id === 11 || session_id.id === 14){
                optionData = [
                    {id: 15, label: "Absent"},
                    {id: 21, label: "Diplomat"},
                    {id: 22, label: "Strategist"},
                    {id: 23, label: "Envoy"},
                    {id: 24, label: "Jurist"},
                    {id: 16, label: "Member"}
                ];
            }else if(session_id.id === 12){
                optionData = [
                    {id: 21, label: "Diplomat"},
                    {id: 16, label: "Member"}
                ];
            }
            setRoleOption([...optionData]);
            //Award
            if((session_id.id >= 4 && session_id.id <= 8) || session_id.id === 9){
                optionAwardData = [
                    {id: 9, label: "Best Presenter"},
                    {id: 10, label: "Best Challenger"},
                    {id: 11, label: "Best Mentor"},
                ];
            }else if(session_id.id === 12){
                optionAwardData = [
                    {id: 12, label: "Best Envoy"},
                    {id: 13, label: "Best Jurist"}
                ];
            }else if(session_id.id === 13){
                optionAwardData = [
                    {id: 14, label: "Best Diplomat"}
                ];
            }else if(session_id.id === 14){
                optionAwardData = [
                    {id: 12, label: "Best Envoy"},
                    {id: 13, label: "Best Jurist"},
                    {id: 14, label: "Best Diplomat"},
                ];
            }
            setAwardOption([{id: 0, label: "No Award"}, ...optionAwardData]);
            // return optionData;
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error loading data."});
            error_api_call({error: `portfolio/edit|getGloscoRole|${e}`});
        }
    }
    // const responsiveSlide = {
    //     superLargeDesktop: {
    //       // the naming can be any, depends on you.
    //       breakpoint: { max: 4000, min: 3000 },
    //       items: 4,
    //       slidesToSlide: 4,
    //       partialVisibilityGutter: hubMasterList.length === 4 ? 0 : 20
    //     },
    //     desktop: {
    //       breakpoint: { max: 3000, min: 1400 },
    //       items: 3,
    //       slidesToSlide: 3,
    //       partialVisibilityGutter: hubMasterList.length === 3 ? 0 : 20
    //     },
    //     tablet: {
    //       breakpoint: { max: 1400, min: 850 },
    //       items: 3,
    //       slidesToSlide: 3,
    //       partialVisibilityGutter: hubMasterList.length === 3 ? 0 : 15
    //     },
    //     bigMobile: {
    //         breakpoint: { max: 850, min: 464 },
    //         items: 2,
    //         slidesToSlide: 2,
    //         partialVisibilityGutter: hubMasterList.length === 2 ? 0 : 15
    //     },
    //     mobile: {
    //       breakpoint: { max: 464, min: 0 },
    //       items: 1,
    //       slidesToSlide: 1,
    //     //   partialVisibilityGutter: hubMasterList.length === 1 ? 0 : 15
    //         partialVisibilityGutter: 0
    //     }
    //   };
    //Show Opportunity Details
    // const openHubModal = async(e, oppertunity_id) => {
    //     try{
    //         e.preventDefault();
    //         // Show Loader
    //         setHubInfoLoader(true);
    //         //Open Modal
    //         toggleModal(e, 'hub_info', true);
    //         //Fetch Data
    //         if(typeof hubInfoDetails[oppertunity_id] !== "undefined"){
    //             setHubModalData({...hubInfoDetails[oppertunity_id]});
    //         }else{
    //             const hubApiData = await post_api_call(`opportunity/details/${oppertunity_id}`, {});
    //             if(hubApiData.status === 200){
    //                 setHubModalData({...hubApiData.data});
    //                 setHubInfoData({...hubInfoDetails, [oppertunity_id]: hubApiData.data});
    //             }else{
    //                 alertResponseMessage({alert_type: 2, message: "Error loading data."});
    //             }
    //         }
    //         setHubInfoLoader(false);
    //     }catch(error){
    //         console.log(error)
    //         alertResponseMessage({alert_type: 2, message: "Error loading data."});
    //         error_api_call({error: `portfolio/edit|openHubModal|${error}`});
    //     }
    // }
    //AskEVA set Context
    const askEvaHelp = (e, section) => {
        try{
            e.preventDefault();
            setEvaProcess(true);
            var evaData = {...askevaPayload};
            var formDetails = {
                name: masterTitle[section],
                // description: formDescription[section]
            };
            //Description
            if(typeof formDescription[section] !== "undefined"
                && formDescription[section]
            ){
                formDetails.description = formDescription[section];
            }
            var formFields = {};
            evaData.context.currentContext.currentlyViewing = askEvaContext[section];
            evaData.call_direct_api = evaData.call_direct_api === 2 ? 1 : 2;
            if(section === 'Achieve'){
                const selectOptions = {session_id: {label: 'label',
                                                data: [...sessionOptions]},
                                        role_id: {label: 'label',
                                            data: [...roleOptions]},
                                        award_id: {label: 'label',
                                            data: [...awardOptions]},
                                        is_team_award: {label: 'title',
                                            data: [...yesNoOption]},
                                        // award_id: {label: 'label',
                                            // data: [...awardOptions]},
                                    };
                fieldNewAchieve.forEach(field_ach  => {
                    if(typeof field_ach.is_role_required === "undefined"
                        || formDataAchieve.role_id
                    ){
                        if(typeof field_ach.session_ids === "undefined"
                            || (formDataAchieve.session_id && field_ach.session_ids.includes(formDataAchieve.session_id.id))
                        ){
                            if(field_ach.name !== 'award_id'
                                || (field_ach.name === 'award_id' && awardOptions.length)
                            ){
                                var fieldD = {};
                                if(field_ach.type === 'select'){
                                    fieldD.value = formDataAchieve[field_ach.name] ? formDataAchieve[field_ach.name][selectOptions[field_ach.name].label] : '';
                                    //Options
                                    var achieveOpt = [];
                                    selectOptions[field_ach.name].data.forEach(opt_d => {
                                        achieveOpt.push(opt_d[selectOptions[field_ach.name].label]);
                                    });
                                    fieldD.options = achieveOpt;
                                }else if(field_ach.type === 'cohort_number'
                                    || field_ach.type === 'year'
                                ){
                                    fieldD.value = formDataAchieve[field_ach.name] ? formDataAchieve[field_ach.name].id : '';
                                }else{
                                    fieldD.value = formDataAchieve[field_ach.name];
                                }
                                formFields[field_ach.label] = fieldD;
                            }
                        }
                    }
                });
                //Team Award
                if(formDataAchieve.session_id
                    && (formDataAchieve.session_id.id === 12
                        || formDataAchieve.session_id.id === 14)
                    && formDataAchieve.role_id
                    && (formDataAchieve.is_team_award && formDataAchieve.is_team_award.id === 1)
                ){
                    //Options
                    var achieveOpt = [];
                    teamAwardOptions.forEach(opt_d => {
                        achieveOpt.push(opt_d.label);
                    });
                    formFields['Team Award'] = {
                        value: formDataAchieve.team_award_id ? formDataAchieve.team_award_id.label : "",
                        options: achieveOpt,
                    }
                }
            }else if(section === 'Headline'){
                var exampleHead = {};
                for (var j = 0; j < fieldNewHeadline.placeholderData.length; j++) {
                    if(j === 3){
                        break;
                    }
                    exampleHead[`eg${j+1}`] = fieldNewHeadline.placeholderData[j];
                }
                formFields['Who you are'] = {
                    value: formDataHeadline,
                    ...exampleHead
                }
            }else if(section === 'PersonalSkills'
                || section === 'CareerSkills'
            ){
                var formTitle = 'Your Key Career Skills';
                var optData = careerSkillData;
                var skillValue = formDataCareer;
                if(section === 'PersonalSkills'){
                    formTitle = 'Your Key Personal Skills';
                    optData = personalSkillData;
                    skillValue = formDataPersonal;
                }
                //Filled Data
                var skillVal = [];
                skillValue.forEach(opt_d => {
                    skillVal.push(opt_d.skill_name);
                });
                //Options
                var skillOpt = [];
                optData.forEach(opt_d => {
                    skillOpt.push(opt_d.skill_name);
                });
                formFields[formTitle] = {
                    value: skillVal,
                    options: skillOpt,
                    instructions: 'Click and Select top three.'
                }
            }else{
                const selectOptions = {emotion_id: 
                                            {
                                            // label: 'emotion',
                                            data: [...emotionData]},
                                        theme_id: 
                                            {data: [...themeData]},
                                        competition_id: 
                                            {data: [...competeData]},
                                        edu_classify_id:
                                            {data: [...classifyDataEdu.classify]},
                                        edu_subject_id:
                                            {data: [...classifyDataEdu.subject]},
                                        edu_rank_id:
                                            {data: [...classifyDataEdu.rank]},
                                        exp_classify_id:
                                            {data: [...classifyDataExp.classify]},
                                        exp_work_type_id:
                                            {data: [...classifyDataExp.work_type]},
                                        exp_company_type_id:
                                            {data: [...classifyDataExp.company_type]},
                                         
                                        research_classify_id: 
                                            {data: [...classifyDataResearch.classify]},
                                        research_work_type_id: 
                                            {data: [...classifyDataResearch.work_type]},
                                        research_subject_id: 
                                            {data: [...classifyDataResearch.subject]},
                                        research_com_level_id: 
                                            {data: [...classifyDataResearch.com_level]},
                                        research_rank_id: 
                                            {data: [...classifyDataResearch.rank]},
                                        award_classify_id: 
                                            {data: [...classifyDataAward.classify]},
                                        award_domain_id: 
                                            {data: [...classifyDataAward.domain]},
                                        award_level_id: 
                                            {data: [...classifyDataAward.com_level]},
                                        award_rank_id: 
                                            {data: [...classifyDataAward.rank]},
                                        sport_classify_id: 
                                            {data: [...classifyDataSport.classify]},
                                        sport_work_type_id: 
                                            {data: [...classifyDataSport.work_type]},
                                        sport_domain_id: 
                                            {data: [...classifyDataSport.domain]},
                                        sport_level_id: 
                                            {data: [...classifyDataSport.com_level]},
                                        sport_rank_id: 
                                            {data: [...classifyDataSport.rank]},
                                    };
                const formKey = section.replace("New", "");
                const fieldData = FormDataPortfolio[`fieldNew${formKey}`];
                var filledData = {};
                if(section === 'Profile'){
                    filledData = formDataDesc;
                }else{
                    filledData = editFormData;
                }
                
                if(section === 'EduNew'
                    || section === 'ExpNew'
                    || section === 'SportNew'
                ){
                    formFields['is_present'] = {
                        value: filledData.is_present
                    }
                }
                fieldData.forEach(form_data => {
                    var fieldD = {};
                    if(form_data.type === 'year'
                        || form_data.type === 'year_present'
                    ){
                        fieldD.value = filledData[form_data.name] ? filledData[form_data.name].year : '';
                    }else if(form_data.type === 'multi-select'
                        || form_data.type === 'select'
                        || form_data.type === 'other_input'
                        || form_data.type === 'rank_input'
                    ){
                        //Options
                        if(typeof selectOptions[form_data.name] !== "undefined"){
                            const optData = selectOptions[form_data.name];
                            var options = [];
                            optData.data.forEach(opt_d => {
                                options.push(opt_d.label);
                            });
                            fieldD.options = options;
                            if(filledData[form_data.name]){
                                if(form_data.type === 'select'){
                                    if(typeof filledData[form_data.name].id !== "undefined"
                                        && typeof filledData[form_data.name].label !== "undefined"
                                    ){
                                        fieldD.value = filledData[form_data.name].label;
                                    }else{
                                        fieldD.value = "";
                                    }
                                }else if(form_data.type === 'other_input'
                                    || form_data.type === 'rank_input'
                                ){
                                    if(typeof filledData[form_data.name].id !== "undefined"
                                        && typeof filledData[form_data.name].label !== "undefined"
                                    ){
                                        fieldD.value = filledData[form_data.name].id > 0
                                                    ? filledData[form_data.name].label
                                                    : (typeof filledData[form_data.other_name] !== "undefined"
                                                        ? filledData[form_data.other_name]
                                                        : ""
                                                    )
                                    }else{
                                        fieldD.value = "";
                                    }
                                }else if(form_data.type === 'multi-select'){
                                    var selectedVal = [];
                                    filledData[form_data.name].forEach(select_d => {
                                        selectedVal.push(select_d.label);
                                    });
                                    fieldD.value = selectedVal;
                                }
                            }
                        }else{
                            console.log(form_data.name, selectOptions[form_data.name], 'optData')
                        }
                    }else{
                        fieldD.value = filledData[form_data.name];
                    }
                    //Example
                    if(typeof form_data.placeholderData !== "undefined"
                        && Array.isArray(form_data.placeholderData)
                    ){
                        var placeholderData = form_data.placeholderData;
                        for (var i = 0; i < placeholderData.length; i++) {
                            if(i === 3){
                                break;
                            }
                            fieldD[`eg${i+1}`] = placeholderData[i];
                        }
                    }
                    //Tagline
                    if(typeof form_data.tagline !== "undefined"
                        && form_data.tagline
                    ){
                        fieldD.instructions = form_data.tagline;
                    }
                    formFields[form_data.label] = fieldD;
                });
                if(section !== 'ResearchNew'){
                    //Add Certification
                    if(section === 'LifeExpNew'){
                        formFields['Images/Photos'] = {
                            value: previewDocData.length > 0 ? `${previewDocData.length} Images/Photos uploaded` : '',
                            instructions: 'Add photos and images, if you have taken any, from these life altering experiences. Maximum 2 Images/Photos Upload Allowed. Images/Photos allowed size is 1MB and it must be an image.'
                        }
                    }else{
                        formFields['Certificates'] = {
                            value: previewDocData.length > 0 ? `${previewDocData.length} Certificate uploaded` : '',
                            instructions: 'Upload a certificate, if you have one, since that adds credibility to your achievement. Maximum 2 Certificates Upload Allowed. Certificates allowed size is 1MB and it must be an image/pdf.'
                        }
                    }
                    // EduDoc: 'program_name',
                    // ExpDoc: 'organization',
                    // SportDoc: 'name',
                    // AwardDoc: 'award_name',
                    // LifeExpDoc: 'experience'
                }
                // console.log(fieldData, 'fieldDatafieldDatafieldData')
            }
            formDetails.fields = formFields;
            evaData.context.currentContext.form = formDetails;
            setEvaPayload({...evaData});
            // console.log(evaData, section)
            // setEvaProcess(false);
        }catch(error){
            setEvaProcess(false);
            // alertResponseMessage({alert_type: 2, message: "Error connecting AskEVA"});
            alertResponseMessage({alert_type: 2, message: "Error processing your request, please try again later."});
            error_api_call({error: `portfolio/edit|askEvaHelp|${error}`});
            console.error(error);
        }
    }
    //Set AskEVA Data
    const updateAskevaData = (section, data) => {
        try{
            var evaData = {...askevaPayload};
            evaData[section] = data;
            setEvaPayload({...evaData});
        }catch(error){
            // alertResponseMessage({alert_type: 2, message: "Error connecting AskEVA"});
            error_api_call({error: `portfolio/edit|askEvaHelp|${error}`});
        }
    }
    return (
        <>
        <main id="main">
            <section className="team-sec pt-0 portfolio-edit">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    {/* Opportunities List */}
                    <HubListScrollComp/>
                    {/* {hubLoading
                    ?
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                        <div className="row">
                        <div className="hub-content mb-5">
                        <Carousel
                            containerClass="carousel-container"
                            partialVisible={true}
                            responsive={responsiveSlide}
                            ssr={true} // means to render carousel on server-side.
                            // centerMode={true}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            infinite={false}
                            renderDotsOutside={true}
                            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                            // autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            // removeArrowOnDeviceType={["tablet", "mobile"]}
                            // deviceType={this.props.deviceType}
                            // dotListClass="custom-dot-list-style"
                            itemClass="item"
                        >
                            {hubMasterList.map((hub_data, hub_index) => (
                                <div key={`hub_${hub_index}`} className="caro-content">
                                    <HubListComp
                                        data={hub_data}
                                        openHubModal={(e, hub_id) => openHubModal(e, hub_id)}
                                    />
                                </div>
                            ))}
                        </Carousel>
                        </div>
                        </div>
                    } */}
                    <div className="section-header section-header-1">
                        <h1 className="title">Edit Portfolio</h1>
                    </div>
                    
                    <div className="row justify-content-center team-detail m-1">
                    {pageLoading 
                    ? 
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                    // !isSuccess
                    // ?
                        <>
                        {(formDataEnroll
                            && formDataEnroll.length
                        ) && (
                            <div className="col-md-10 col-12 col-sm-12 pe-0">
                                <div className="float-end">
                                {formDataEnroll.map((subs_plan, subs_plan_index) => (
                                    <p key={`subs_plan${subs_plan_index}`} className="mb-0"><b>{subs_plan.name} Validity: <span className="color-blue">{subs_plan.days_left} Day{subs_plan.days_left > 1 ? 's' : ''} left</span></b></p>
                                ))}
                                </div>
                            </div>
                        )}
                        {/* {formDataEnroll && formDataEnroll.days_left && (
                            <div className="col-md-10 col-12 col-sm-12 pe-0">
                                <p className="mb-2 float-end"><b>Port Validity: <span className="color-blue">{formDataEnroll.days_left} Day{formDataEnroll.days_left > 1 ? 's' : ''} left</span></b></p>
                            </div>
                        )} */}
                        <div className="col-md-10 col-12 col-sm-12 card p-3">
                            <p className="mb-3 text-center">
                            Ready to unlock bigger opportunities? PORT transforms your achievements and certificates into a captivating digital portfolio—while EVA expertly guides your future—career choices, college applications and personal growth. <b>Start now!</b>
                            </p>
                            <div className="breadcrumb-link mb-3 mt-1 text-end">
                                <a href={`/port/${userUrl}`}><button className="btn btn-primary bg-color-blue me-2" type="button">View Portfolio</button></a>
                                {/* <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={<Tooltip>Update Social Start-ups</Tooltip>}
                                > 
                                    <a href="#socialSS"><img src={require('../../assets/images/icon-SDG.png')} alt="Update Social Start-ups" /></a>
                                </OverlayTrigger> */}
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={<Tooltip>Update Glosco</Tooltip>}
                                > 
                                    <a href="#gloscoS" onClick={(e) => toggleModal(e, 'glosco', true, '', '', false)}>
                                        <img src={require('../../assets/images/icon-SDG.png')} alt="Update Glosco" />
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={<Tooltip>Update Life Altering Experience (LAX)</Tooltip>}
                                > 
                                    <a className="ms-2" href="#lifeExp" onClick={(e) => toggleModal(e, 'Master', true, '', 'LifeExpNew', false)}>
                                        <img src={require('../../assets/images/icon-life.png')} alt="Update Life Altering Experience (LAX)"/>
                                    </a>
                                </OverlayTrigger>
                            </div>
                            {/* <div className={`alert alert-primary btn-glow ${!referralCode ? 'overlay-main' : ''}`} role="alert">
                                <div>
                                    <p><b>Refer/Invite Your Friends</b>: 
                                    {referralCode
                                        ?
                                            <span><b className="color-red"> Congrats! You've unlocked 3 free invites!</b>  You've completed 6 sections, and you're eligible to invite 3 friends to try Port free for 1 month. 
                                            Be a hero. Share the free invite!</span>
                                        :
                                            completedSection.length > 2
                                                ?
                                                <span> You've completed {completedSection.length} sections. Finish {6-completedSection.length} more to unlock 3 free invites for your friends to try Port free for 1 month.<br/>Keep going!</span>
                                                :
                                                <span> Port is by invite-only. But complete 6 sections, and you can invite 3 friends to try Port free for 1 month.<br/>
                                                Unlock your invites now!
                                                </span>
                                    }
                                    </p>
                                    <div className="d-md-flex">
                                        <p className="mb-md-0">Your Referral/Invite Code: <button className="btn btn-outline-primary" onClick={(e) => copyClipBoard(e, referralCode, 'Invite Code')}>{referralCode ? referralCode : 'XXXXXX'}</button></p>
                                        <p className="ps-md-2 mb-md-0">
                                        Invite via:&nbsp;
                                        <ShareIconsComp
                                            title="Don’t Let Your Growth Go Unnoticed—Build Your Portfolio Now! 
                                                    Without a strong portfolio, students miss out on showcasing their growth path to top colleges, and scholarship committees. IFSPD.eu/port helps you highlight your growth, achievements, and experiences in a powerful, organized way. 
                                                    I've already created mine, and it's made a real difference. Hence, I’ve referred your name for a free 1-month trial—to help you build your standout portfolio! Create Yours Now: "
                                            link={`https://${window.location.hostname}/register?redirect_uri=port&referral_code=${referralCode}`}
                                        />
                                        </p>
                                    </div>
                                </div>
                                {!referralCode && (
                                    <div className="div-overlay-center">
                                        <p className="alert alert-info mb-0">
                                            {completedSection.length > 2
                                                ?
                                                <span>Almost There!</span>
                                                :
                                                <span>Unlock Free Invites!</span>
                                            }
                                        </p>
                                    </div>
                                )}
                            </div> */}
                            <div className="row">
                                <div className="col-md-9 col-12 col-sm-8">
                                    <div className="float-end float-sm-start toggle-button pb-3 pt-md-1">
                                        <PortPublicBtn
                                            isPublic={allApiData ?  allApiData.visibility : true}
                                            // updateVisibility={(state) => setIsPortPublic(state)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-12 col-sm-4">
                                    <p className={`fw-bold text-end color-blue pt-1`}>{completedSection.length}/{totalSections} Completed</p>
                                </div>
                            </div>
                            {/* General Details */}
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'1'}
                                            data = {completedSection}
                                            title={'General Details'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                            <div className="table-responsive">
                                                <p className="float-end mb-1 ">
                                                <WrapTooltip title="Edit Entry">
                                                    <button className="btn btn-edit"  onClick={(e) => toggleModal(e, 'Master', true, '', 'General')}><i className="fa fa-edit"></i></button>
                                                </WrapTooltip>
                                                </p>
                                                <table className="border-0 table-bordered table-res">
                                                    <tbody>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <td>{formDataGeneral.first_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Last Name</th>
                                                            <td>{formDataGeneral.last_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Date of Birth</th>
                                                            <td>{moment(formDataGeneral.dob).format("YYYY-MM-DD")}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Languages Known</th>
                                                            <td>{formDataGeneral.language}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                            <div className="table-responsive">
                                                <p className="float-end mb-1 ">
                                                <WrapTooltip title="Edit Entry">
                                                    <button className="btn btn-edit"  onClick={(e) => toggleModal(e, 'Master', true, '', 'School')}><i className="fa fa-edit"></i></button>
                                                </WrapTooltip>
                                                </p>
                                                <table className="border-0 table-bordered table-res">
                                                    <tbody>
                                                        <tr>
                                                            <th>Institution Type</th>
                                                            <td>{formDataSchool.institute_type_id.type}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{formDataSchool.institute_type_id.id === 0
                                                                ?
                                                                    'School/College/Organisation Name'
                                                                    : `${instituteTypeObj[formDataSchool.institute_type_id.id]} Name`
                                                                }
                                                            </th>
                                                            <td>
                                                                {formDataSchool.institute_type_id.id === 1 && (
                                                                    formDataSchool.school_id.id === 0
                                                                    ? formDataSchool.school_name
                                                                    : formDataSchool.school_id.school_name
                                                                )}
                                                                
                                                                {formDataSchool.institute_type_id.id === 2
                                                                    ? formDataSchool.college_name
                                                                    : (formDataSchool.institute_type_id.id === 3
                                                                        ? formDataSchool.org_name
                                                                        : '')
                                                                }
                                                            </td>
                                                        </tr>
                                                        {formDataSchool.institute_type_id.id < 2 && (
                                                            <tr>
                                                                <th>Branch</th>
                                                                <td>{formDataSchool.institute_type_id.id === 1
                                                                    ? formDataSchool.branch_id.id !== -1
                                                                        ? formDataSchool.branch_id.id === 0
                                                                            ? formDataSchool.branch_name
                                                                            : formDataSchool.branch_id.branch_name
                                                                        : ""
                                                                    : ""}</td>
                                                            </tr>
                                                        )}
                                                        {formDataSchool.institute_type_id.id < 3 && (
                                                            <tr>
                                                                <th>{formDataSchool.institute_type_id.id === 0
                                                                    ?   'Class/Course'
                                                                    :   formDataSchool.institute_type_id.id === 1
                                                                        ?   'Class'
                                                                        :   'Course'
                                                                    }
                                                                </th>
                                                                <td>
                                                                    {formDataSchool.institute_type_id.id === 1 && (
                                                                        (formDataSchool.class_id
                                                                            // && formDataSchool.class_id.id === 0
                                                                        )
                                                                        ? formDataSchool.class_id.class_name
                                                                        : formDataSchool.class_name
                                                                    )}
                                                                    
                                                                    {formDataSchool.institute_type_id.id === 2 && (
                                                                        formDataSchool.course_id.id === 0
                                                                        ? formDataSchool.course_name
                                                                        : formDataSchool.course_id.class_name
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* profile photo */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <CompletedSecUI
                                        form_id = {'2'}
                                        data = {completedSection}
                                        title={'Photograph'}
                                    />
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <form autoComplete="off">
                                        <b>Upload your Photograph</b> (Passport size, with your face making 70-80% of the photo against a plain or white background. Max 1MB):<br/>
                                        <div className="justify-content-center d-grid mb-5 mt-3">
                                        {previewPicImg !== null &&
                                            <>
                                            <div className="d-flex">
                                            <Cropper
                                                src={previewPicImg.src}
                                                style={{ height: 200, width: 200 }}
                                                initialAspectRatio={1 / 1}
                                                aspectRatio={1/1}
                                                guides={false}
                                                crop={onCrop}
                                                ref={cropperRef}
                                                dragMode="move"
                                                // cropBoxResizable={false}
                                                background={false}
                                                // minContainerWidth={"100%"}
                                                minContainerWidth={200}
                                                zoomable={false}
                                                highlight={false}
                                                checkOrientation={false}
                                            />
                                            <div className="d-none d-sm-flex ms-4">
                                                <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={formDataGeneral.first_name} />
                                            </div>
                                            </div>
                                            </>
                                        }
                                        <div className="upload-img text-center">
                                            <div className="m-3">
                                                {previewPicImg !== null
                                                    ?
                                                    <></>
                                                    :
                                                    <img src={formDataGeneral.logo} alt={formDataGeneral.first_name} className="img-thumbnail cropper-preview-img" />
                                                }
                                            </div>
                                            
                                            <div className="btn btn-light btn-outline-primary">
                                                <span>Upload Your photo</span>
                                                <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewDoc(e, 'Pic', 'image')} accept="image/x-png,image/jpg,image/jpeg" />
                                            </div>
                                        </div>
                                        </div>
                                        <p className="text-center mb-2">
                                            {!isProcess['Pic']
                                                ?
                                                    isBtnDisabled['Pic']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Pic')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                            }
                                        
                                            <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                        </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Pic'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Pic'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* short_description */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'3'}
                                            data = {completedSection}
                                            title={'Headline'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            <b>Your Portfolio Headline:</b> {formDescription['Headline']}<br/><br/>
                                            {fieldNewHeadline.placeholderData.map((head_ex, head_indx) => (
                                                <p key={`ex_${head_indx}`} className="m-0"><i>Example {head_indx+1}: {head_ex}</i></p>
                                            ))}
                                            <br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table-res">
                                                <tbody>
                                                    <tr>
                                                        <th className="align-baseline">Who you are<em>*</em>:</th>
                                                        <td>
                                                            <textarea className="form-control" name="position" value={formDataHeadline} onChange={(e) => updateInput('Headline', 'single_value', 'textarea', 'position', e.target.value)} rows={3}/>
                                                            <CharacterCount
                                                                max={150}
                                                                string={formDataHeadline}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                <EvaPortButton
                                                    askEvaHelp={(e, section) => askEvaHelp(e, section)}
                                                    modKey="Headline"
                                                    isEvaProcess={isEvaProcess}
                                                />
                                                {!isProcess['Headline']
                                                ?
                                                    isBtnDisabled['Headline']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled mb-2 mb-sm-0" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" type="submit" onClick={(e) => editPortfolio(e, 'Headline')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue mb-2 mb-sm-0 ms-2 ms-sm-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Headline'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Headline'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Description: short profile */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'4'}
                                            data = {completedSection}
                                            title={'Short Profile'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            <b>Your Short Profile:</b> {formDescription['Profile']}:<br/><br/>
                                            <span><i>Example 1: <b>Your name, grade and school:</b> Jane Doe. 10th grade. Bucharest Global School.
                                            <b>Your academic and extracurricular achievements, leadership, awards, research and any internships:</b> Ace debater. Won prizes in several interschool competitions. Passionate about sustainable development. Has conducted a primary research on the topic ‘Improving Sustainable Living in Romanian cities’.
                                            <b>Your passion and future goal:</b> Working in an NGO dedicated to sustainable practices.</i></span><br/><br/>
                                            <span><i>Example 2: <b>Your name, grade and school:</b> John Doe. 8th grade. International Links School.
                                            <b>Your academic and extracurricular achievements, leadership, awards, research and any internships:</b> Top 1% percentile in school for Mathematics and Science. Selected for the first cohort of Glosco in India. Awarded the Ace Speaker and Ace Chair badges. Completed 3 months of internship in an NGO on ‘Empowering village women’.
                                            <b>Your passion and future goal:</b> Coding enthusiast with a future goal to create a successful start-up.</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table-res">
                                                <tbody>
                                                    {fieldNewProfile.map((pro_d, pro_indx) => (
                                                        <tr key={`pro_field_${pro_indx}`}>
                                                            <th>{pro_d.label}{pro_d.required && (<em>*</em>)}:</th>
                                                            <td className="w-50">
                                                                <textarea className="form-control" name={pro_d.name} value={formDataDesc[pro_d.name]} onChange={(e) => updateInput('Desc', 'object', 'textarea', pro_d.name, e.target.value)} rows={3}/>
                                                                <CharacterCount
                                                                    max={250}
                                                                    string={formDataDesc[pro_d.name]}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {/* <tr>
                                                        <th>Your name, grade and school<em>*</em>:</th>
                                                        <td className="w-50">
                                                            <textarea className="form-control" name="short_description" value={formDataDesc.short_description} onChange={(e) => updateInput('Desc', 'object', 'textarea', 'short_description', e.target.value)} rows={3}/>
                                                            <CharacterCount
                                                                max={250}
                                                                string={formDataDesc.short_description}
                                                            />
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <th>Your academic and extracurricular achievements, leadership, awards, research and any internships:</th>
                                                        <td>
                                                            <textarea className="form-control" name="imp_exp" value={formDataDesc.basic_achievement} onChange={(e) => updateInput('Desc', 'object', 'textarea', 'basic_achievement', e.target.value)} rows={3}/>
                                                            <CharacterCount
                                                                max={250}
                                                                string={formDataDesc.basic_achievement}
                                                            />
                                                        </td>
                                                    </tr> */}
                                                    {/* <tr>
                                                        <th>Your passion and future goal<em>*</em>:</th>
                                                        <td>
                                                            <textarea className="form-control" name="imp_edu" value={formDataDesc.basic_passion} onChange={(e) => updateInput('Desc', 'object', 'textarea', 'basic_passion', e.target.value)} rows={3}/>
                                                            <CharacterCount
                                                                max={250}
                                                                string={formDataDesc.basic_passion}
                                                            />
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                <EvaPortButton
                                                    askEvaHelp={(e, section) => askEvaHelp(e, section)}
                                                    modKey="Profile"
                                                    isEvaProcess={isEvaProcess}
                                                />
                                                {!isProcess['Desc']
                                                ?
                                                    isBtnDisabled['Desc']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled mb-2 mb-sm-0" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" type="submit" onClick={(e) => editPortfolio(e, 'Desc')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                           
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue mb-2 mb-sm-0 ms-2 ms-sm-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Desc'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Desc'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Life Experience */}
                            <Accordion className="pt-3 scroll-div" id="lifeExp">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'11'}
                                            data = {completedSection}
                                            title={`Life Altering Experiences and Defining Moments`}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                        <b>Life Altering Experiences and Defining Moments:</b> List all of your Life Altering Experiences and Defining Moments. &nbsp;<button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'LifeExpNew')}>Add++</button><br/><br/>
                                            <div className="port-scroll">
                                            {formDataLifeExp.map((life_exp, life_index) => (
                                                <div key={`exp_${life_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p>{life_index+1}.&nbsp;</p>
                                                        <div>
                                                            <p className="mb-1"><b>Experience</b>: {life_exp.experience}</p>
                                                            <p className="mb-1 t-light"><b>Impact on Life</b>: {life_exp.impact}</p>
                                                            {life_exp.learning && (<p className="mb-1 t-light"><b>Learnings</b>: {life_exp.learning}</p>)}
                                                            {life_exp.theme_id.length > 0 && (
                                                                <span className="d-flex t-light">
                                                                    <b>Experience Themes:&nbsp;</b>
                                                                    {life_exp.theme_id.map((theme_d, theme_index) => (
                                                                        <span key={`theme_${theme_index}`}>
                                                                            {theme_d.label}
                                                                            {theme_index !== life_exp.theme_id.length - 1 && (<>,&nbsp;</>)}
                                                                        </span>
                                                                    ))}    
                                                                </span>
                                                            )}
                                                            {life_exp.emotion_id.length > 0 && (
                                                                <span className="d-flex t-light">
                                                                    <b>Emotions:&nbsp;</b>
                                                                    {life_exp.emotion_id.map((emo_d, emo_index) => (
                                                                        <span key={`emo_${emo_index}`}>
                                                                            {emo_d.label}
                                                                            {emo_index !== life_exp.emotion_id.length - 1 && (<>,&nbsp;</>)}
                                                                        </span>
                                                                    ))}    
                                                                </span>
                                                            )}
                                                            {/* Entry Type */}
                                                            {/* <p className="mb-1 t-light"><b>Entry Type</b>: {life_exp.comp_details ? life_exp.comp_details.comp_name : 'Normal Portfolio Submission'}</p> */}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 d-none d-sm-flex">
                                                            <WrapTooltip title="Edit Entry">
                                                                <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, life_index, 'LifeExpEdit')}><i className="fa fa-edit"></i></button>
                                                            </WrapTooltip>
                                                            {life_exp.doc_data.length > 0 && (
                                                                <WrapTooltip title="View Images">
                                                                    <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, life_index, 'LifeExpDoc')}><i className="fa fa-file fas"></i></button>
                                                                </WrapTooltip>
                                                            )}
                                                            <WrapTooltip title="Delete Entry">
                                                                <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelLifeExp', life_exp.id)}><i className="fas fa-trash"></i></button>
                                                            </WrapTooltip>
                                                        </p>
                                                        <Dropdown className="drop-dot d-flex d-sm-none">
                                                            <Dropdown.Toggle>
                                                                <i className="fa fa-pencil"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, life_index, 'LifeExpEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                {life_exp.doc_data.length > 0 && (
                                                                    <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, life_index, 'LifeExpDoc')}><i className="fa fa-file fas"></i>&nbsp;View Images</button></Dropdown.Item>
                                                                )}
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelLifeExp', life_exp.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* {life_index !== formDataSport.length-1 && ( */}
                                                    <hr className="mb-2 mt-3"/>
                                                {/* )} */}
                                                </div>
                                            ))}
                                            </div>
                                            {formDataLifeExp.length > 0 && (
                                                <p className="text-end mt-3"><button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'LifeExpNew')}>Add More++</button></p>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Unique Skills */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'5'}
                                            data = {completedSection}
                                            title={'Unique Competencies and Career Mapping'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            <b>Unique Competencies and Career Mapping:</b>&nbsp;
                                            Complete your IFSPD Europe Competency & Career Mapping in 2 steps. First, complete Mapping by clicking the first button, and second, fetch results to your portfolio by clicking the second button.
                                            {/* Complete your IFSPD Europe Competency & Career Mapping at <a href="https://admin.gosurvey.in/survey/CC" title="Unique Skills" target="_blank" rel="noreferrer">https://admin.gosurvey.in/survey/CC</a>. */}
                                            <br/><br/>
                                            <p className="text-center"><a href="https://admin.gosurvey.in/survey/CC" title="Unique Skills" target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue" type="button">Click to Complete Competency & Career Mapping</button></a></p>
                                            <p className="text-center"><button className="btn btn-primary bg-color-blue" type="button" onClick={(e) => toggleModal(e, 'passion', true)}>Click to Auto-Fetch Results to Your Portfolio</button></p>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table-res">
                                                <tbody>
                                                    {formDataUnique.map((passion_d, passion_index) => (
                                                    <tr key={`passion${passion_index}`}>
                                                        <th className="align-baseline w-200p">Competency Score Top {passion_index + 1}</th>
                                                        <td>{(passion_d.passion_id && passion_d.passion_id.title) && (<span><b>{passion_d.passion_id.title}</b>: {passion_d.passion_id.desc}</span>)}
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Education */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'6'}
                                            data = {completedSection}
                                            title={'Education, Diplomas and Certifications'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <b>Education, Diplomas and Certifications:</b> List all your educational qualifications, diplomas, short programs, certifications, along with the grades you achieved. &nbsp;<button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'EduNew')}>Add++</button><br/><br/>
                                            <div className="port-scroll">
                                            {[...formDataEdu].map((edu_d, edu_index) => (
                                                // edu_d.id !== 0 && (
                                                <div key={`education${edu_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p>{edu_index+1}.&nbsp;</p>
                                                        <p className="mb-0">
                                                            <span>
                                                                <b>{edu_d.program_name}</b>&nbsp;{edu_d.institute_name !== 
                                                            '' && 'from'}&nbsp;<b>{edu_d.institute_name}</b>
                                                            </span>
                                                            <span className="t-light d-flex">{edu_d.achievement}</span>
                                                            <span className="d-flex t-light">{edu_d.start_year.year}-{edu_d.is_present ? 'Present' : edu_d.end_year.year}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 d-none d-sm-flex">
                                                            <WrapTooltip title="Edit Entry">
                                                                <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, edu_index, 'EduEdit')}><i className="fa fa-edit"></i></button>
                                                            </WrapTooltip>
                                                            {edu_d.doc_data.length > 0 && (
                                                                <WrapTooltip title="View Certificate">
                                                                    <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, edu_index, 'EduDoc')}><i className="fa fa-file fas"></i></button>
                                                                </WrapTooltip>
                                                            )}
                                                            <WrapTooltip title="Delete Entry">
                                                                <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelEdu', edu_d.id)}><i className="fas fa-trash"></i></button>
                                                            </WrapTooltip>
                                                        </p>
                                                        <Dropdown className="drop-dot d-flex d-sm-none">
                                                            <Dropdown.Toggle>
                                                                {/* <i className="fas fa-ellipsis-v"></i> */}
                                                                <i className="fa fa-pencil"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, edu_index, 'EduEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                {edu_d.doc_data.length > 0 && (
                                                                    <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, edu_index, 'EduDoc')}><i className="fa fa-file fas"></i>&nbsp;View Certificate</button></Dropdown.Item>
                                                                )}
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelEdu', edu_d.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* {edu_index !== formDataEdu.length-1 && ( */}
                                                    <hr className="mb-2 mt-3"/>
                                                {/* )} */}
                                                </div>
                                                // )
                                            ))}
                                            </div>
                                            {formDataEdu.length > 0 && (
                                                <p className="text-end mt-3"><button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'EduNew')}>Add More++</button></p>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Experience */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'8'}
                                            data = {completedSection}
                                            title={'Work Experience, Volunteering, Clubs, Organisations, Internships, Training and Passion/Personal Projects'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                        <b>Work Experience, Volunteering, Clubs, Organisations, Internships, Training and Passion/Personal Projects:</b> List all formal, informal, volunteering and even personal work experiences that you have. &nbsp;<button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'ExpNew')}>Add++</button><br/><br/>
                                            <div className="port-scroll">
                                            {formDataExp.map((exp_d, exp_index) => (
                                                <div key={`exp_${exp_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p>{exp_index+1}.&nbsp;</p>
                                                        <p className="mb-0">
                                                            <span>
                                                                <b>{exp_d.designation}</b>&nbsp;{exp_d.organization !== 
                                                            '' && 'at'}&nbsp;<b>{exp_d.organization}</b>
                                                            </span>
                                                            <span className="t-light d-flex">{exp_d.achievement}</span>
                                                            <span className="d-flex t-light">{moment(exp_d.start_year).format("MMM YYYY")} - {exp_d.is_present ? 'Present' : moment(exp_d.end_year).format("MMM YYYY")}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 d-none d-sm-flex">
                                                            <WrapTooltip title="Edit Entry">
                                                                <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, exp_index, 'ExpEdit')}><i className="fa fa-edit"></i></button>
                                                            </WrapTooltip>
                                                            {exp_d.doc_data.length > 0 && (
                                                                <WrapTooltip title="View Certificate">
                                                                    <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, exp_index, 'ExpDoc')}><i className="fa fa-file fas"></i></button>
                                                                </WrapTooltip>
                                                            )}
                                                            <WrapTooltip title="Delete Entry">
                                                                <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelExp', exp_d.id)}><i className="fas fa-trash"></i></button>
                                                            </WrapTooltip>
                                                        </p>
                                                        <Dropdown className="drop-dot d-flex d-sm-none">
                                                            <Dropdown.Toggle>
                                                                <i className="fa fa-pencil"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, exp_index, 'ExpEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                {exp_d.doc_data.length > 0 && (
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, exp_index, 'ExpDoc')}><i className="fa fa-file fas"></i>&nbsp;View Certificate</button></Dropdown.Item>)}
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelExp', exp_d.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* {exp_index !== formDataEdu.length-1 && ( */}
                                                    <hr className="mb-2 mt-3"/>
                                                {/* )} */}
                                                </div>
                                            ))}
                                            </div>
                                            {formDataExp.length > 0 && (
                                                <p className="text-end mt-3"><button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'ExpNew')}>Add More++</button></p>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Papers */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'9'}
                                            data = {completedSection}
                                            title={'Projects, Assignments, Papers, Essays, Researches, Stories, Poems, Videos, Podcasts etc'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                        <b>Projects, Assignments, Papers, Essays, Researches, Stories, Poems, Videos, Podcasts etc:</b> List all significant papers, researches, audios, videos, podcasts etc that you have published somewhere. &nbsp;<button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'ResearchNew')}>Add++</button><br/><br/>
                                            <div className="port-scroll">
                                                {formDataResearch.map((research_d, research_index) => (
                                                    <div key={`exp_${research_index}`}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <p>{research_index+1}.&nbsp;</p>
                                                            <p className="mb-0">
                                                                <span>
                                                                    <b>{research_d.title}</b>&nbsp;{research_d.domain !== 
                                                                '' && 'published at'}&nbsp;<b>{research_d.domain}</b>
                                                                </span>
                                                                <span className="t-light d-flex">{research_d.achievement}</span>
                                                                <span className="t-light d-flex">{research_d.link}</span>
                                                                <span className="d-flex t-light">{research_d.year.year}</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="mb-0 d-none d-sm-flex">
                                                                <WrapTooltip title="Edit Entry">
                                                                    <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, research_index, 'ResearchEdit')}><i className="fa fa-edit"></i></button>
                                                                </WrapTooltip>
                                                                {research_d.doc_data.length > 0 && (
                                                                    <WrapTooltip title="View Certificate">
                                                                        <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, research_index, 'ResearchDoc')}><i className="fa fa-file fas"></i></button>
                                                                    </WrapTooltip>
                                                                )}
                                                                <WrapTooltip title="Delete Entry">
                                                                    <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelResearch', research_d.id)}><i className="fas fa-trash"></i></button>
                                                                </WrapTooltip>
                                                            </p>
                                                            <Dropdown className="drop-dot d-flex d-sm-none">
                                                                <Dropdown.Toggle>
                                                                    <i className="fa fa-pencil"></i>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, research_index, 'ResearchEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                    {research_d.doc_data.length > 0 && (
                                                                    <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, research_index, 'ResearchDoc')}><i className="fa fa-file fas"></i>&nbsp;View Certificate</button></Dropdown.Item>)}
                                                                    <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelResearch', research_d.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    {/* {research_index !== formDataResearch.length-1 && ( */}
                                                        <hr className="mb-2 mt-3"/>
                                                    {/* )} */}
                                                    </div>
                                                ))}
                                            </div>
                                            {formDataResearch.length > 0 && (
                                                <p className="text-end mt-3"><button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'ResearchNew')}>Add More++</button></p>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Sports */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'10'}
                                            data = {completedSection}
                                            title={'Sports, Games, Art, Design, Tech, Science, Humanities, and all other Competitions'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                        <b>Sports, Games, Art, Design, Tech, Science, Humanities, and all other Competitions:</b> List all of your participation and achievements in any sports and games and in any art, design, science, humanities competitions. &nbsp;<button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'SportNew')}>Add++</button><br/><br/>
                                            <div className="port-scroll">
                                            {formDataSport.map((sport_d, sport_index) => (
                                                <div key={`exp_${sport_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p>{sport_index+1}.&nbsp;</p>
                                                        <p className="mb-0">
                                                            <span>
                                                                <b>{sport_d.name}</b>&nbsp;{sport_d.venue !== 
                                                            '' && 'participated at'}&nbsp;<b>{sport_d.venue}</b>
                                                            </span>
                                                            <span className="t-light d-flex">{sport_d.achievement}</span>
                                                            <span className="d-flex t-light">{sport_d.start_year.id} - {sport_d.is_present ? 'Present' : sport_d.end_year.id}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 d-none d-sm-flex">
                                                            <WrapTooltip title="Edit Entry">
                                                                <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, sport_index, 'SportEdit')}><i className="fa fa-edit"></i></button>
                                                            </WrapTooltip>
                                                            {sport_d.doc_data.length > 0 && (
                                                                <WrapTooltip title="View Certificate">
                                                                    <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, sport_index, 'SportDoc')}><i className="fa fa-file fas"></i></button>
                                                                </WrapTooltip>
                                                            )}
                                                            <WrapTooltip title="Delete Entry">
                                                                <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelSport', sport_d.id)}><i className="fas fa-trash"></i></button>
                                                            </WrapTooltip>
                                                        </p>
                                                        <Dropdown className="drop-dot d-flex d-sm-none">
                                                            <Dropdown.Toggle>
                                                                <i className="fa fa-pencil"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, sport_index, 'SportEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                {sport_d.doc_data.length > 0 && (
                                                                    <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, sport_index, 'SportDoc')}><i className="fa fa-file fas"></i>&nbsp;View Certificate</button></Dropdown.Item>
                                                                )}
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelSport', sport_d.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* {sport_index !== formDataSport.length-1 && ( */}
                                                    <hr className="mb-2 mt-3"/>
                                                {/* )} */}
                                                </div>
                                            ))}
                                            </div>
                                            {formDataSport.length > 0 && (
                                                <p className="text-end mt-3"><button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'SportNew')}>Add More++</button></p>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Leadership */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'12'}
                                            data = {completedSection}
                                            title={'Leadership, Honours and Awards'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                        <b>Leadership, Honours and Awards:</b> Showcase all your key and most important awards and provide all the details. &nbsp;<button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'AwardNew')}>Add++</button><br/><br/>
                                            <div className="port-scroll">
                                            {formDataAward.map((award_d, award_index) => (
                                                <div key={`award_${award_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p>{award_index+1}.&nbsp;</p>
                                                        <p className="mb-0">
                                                            <span>
                                                                <b>{award_d.award_name}</b>&nbsp;{award_d.institute_name !== 
                                                            '' && 'awarded by'}&nbsp;<b>{award_d.institute_name}</b>
                                                            </span>
                                                            <span className="t-light d-flex">{award_d.why_awarded}</span>
                                                            <span className="t-light d-flex">{award_d.year.id}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 d-none d-sm-flex">
                                                            <WrapTooltip title="Edit Entry">
                                                                <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, award_index, 'AwardEdit')}><i className="fa fa-edit"></i></button>
                                                            </WrapTooltip>
                                                            {award_d.doc_data.length > 0 && (
                                                                <WrapTooltip title="View Certificate">
                                                                    <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, award_index, 'AwardDoc')}><i className="fa fa-file fas"></i></button>
                                                                </WrapTooltip>
                                                            )}
                                                            <WrapTooltip title="Delete Entry">
                                                                <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelAward', award_d.id)}><i className="fas fa-trash"></i></button>
                                                            </WrapTooltip>
                                                        </p>
                                                        <Dropdown className="drop-dot d-flex d-sm-none">
                                                            <Dropdown.Toggle>
                                                                <i className="fa fa-pencil"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, award_index, 'AwardEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                {award_d.doc_data.length > 0 && (
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, award_index, 'AwardDoc')}><i className="fa fa-file fas"></i>&nbsp;View Certificate</button></Dropdown.Item>)}
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelAward', award_d.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                {/* {award_index !== formDataAward.length-1 && ( */}
                                                    <hr className="mb-2 mt-3"/>
                                                {/* )} */}
                                                </div>
                                            ))}
                                            </div>
                                            {formDataAward.length > 0 && (
                                                <p className="text-end mt-3"><button className="btn btn-outline-primary me-1" onClick={(e) => toggleModal(e, 'Master', true, '', 'AwardNew')}>Add More++</button></p>
                                            )}
                                        </div>                                        
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Social Projects */}
                            {/* <Accordion className="pt-3" id="socialSS">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'16'}
                                            data = {completedSection}
                                            title={'Social Start-ups'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <b>Social Start-Ups:</b> Enables you to gain real-world problem-solving and leadership skills. A social start-up solves a local issue within 1 km of your community in just 1 day of action, complying to the United Nation Sustainable Development Goals (SDG) and Key Performance Indicators (KPI) using an easy 6-steps process.<br/><br/>
                                            Every time you complete a social start-up, fetch your report by clicking on the link below.<br/><br/>
                                            <p className="text-center"><button className="btn btn-primary bg-color-blue" type="button" disabled >Click to Auto-Fetch Social Start-up to Your Portfolio</button><br/>
                                            <span className="color-blue"><b>Coming Soon</b></span>
                                            </p>
                                            <div className="port-scroll">
                                            {formDataSocial.map((social_d, social_index) => (
                                                <div key={`social_${social_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p className="mb-0">{social_index+1}.&nbsp;</p>
                                                        <div>
                                                            <p className="mb-0"><b>{social_d.name}</b></p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0 d-none d-sm-flex">
                                                            <WrapTooltip title="Edit Entry">
                                                                <button className="btn btn-edit" onClick={(e) => toggleModal(e, 'Master', true, social_index, 'SocialEdit')}><i className="fa fa-edit"></i></button>
                                                            </WrapTooltip>
                                                            <WrapTooltip title="Delete Entry">
                                                                <button className="btn btn-edit" onClick={(e) => confirmDeletion(e, 'DelSocial', social_d.id)}><i className="fas fa-trash"></i></button>
                                                            </WrapTooltip>
                                                        </p>
                                                        <Dropdown className="drop-dot d-flex d-sm-none">
                                                            <Dropdown.Toggle>
                                                                <i className="fa fa-pencil"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => toggleModal(e, 'Master', true, social_index, 'SocialEdit')}><i className="fa fa-edit"></i>&nbsp;Edit Entry</button></Dropdown.Item>
                                                                <Dropdown.Item><button className="btn p-0" onClick={(e) => confirmDeletion(e, 'DelSocial', social_d.id)}><i className="fas fa-trash"></i>&nbsp;Delete Entry</button></Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <hr className="mb-2 mt-3"/>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> */}
                            {/* Participation and Achievements */}
                            <Accordion className="pt-3 scroll-div" id="gloscoS">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'7'}
                                            data = {completedSection}
                                            title={'IFSPD Europe Global Sustainability Council Participation and Achievements'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {/* <form autoComplete="off"> */}
                                            <p>The Global Sustainability Council (Glosco), a flagship youth initiative by IFSPD Europe, integrates into school curricula to build students’ global perspectives, diplomatic skills, and leadership through case studies, research, and conclaves. Participants enhance profiles and contribute to sustainability—a valued quality for top colleges. 
                                            For details, ask your school authorities to contact <a href="mailto:director@ifspd.eu" title="Director Email Address">director@ifpsd.eu</a></p>
                                            <p className="text-center">
                                                <button
                                                    className="btn btn-primary bg-color-blue"
                                                    type="button"
                                                    onClick={(e) => toggleModal(e, 'glosco', true)}
                                                    disabled={serviceAccessData.includes(5) ? false : true}
                                                >Add Participation and Achievements</button>
                                                {!serviceAccessData.includes(5) && (
                                                    allApiData.is_glosco === 1
                                                    ?
                                                        <p className="text-danger">To access, please subscribe to Glosco. <a href="/pay">Click here</a></p>
                                                    :
                                                        <p><i>Glosco, exclusive by-invite-only program.</i></p>
                                                )}
                                            </p>
                                            {gloscoUserData.length > 0 && (
                                                <div className="table-responsive">
                                                <table className="border-0 table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Glosco Level Completed</th>
                                                            <th>Year of completion</th>
                                                            <th>Individual Awards</th>
                                                            <th>Team Awards</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="port-scroll">
                                                        {gloscoUserData.map((achieve_d, achieve_index) => (
                                                            <tr key={`achieve${achieve_index}`}>
                                                                <td><b>{achieve_d.level}</b></td>
                                                                <td>{achieve_d.year}</td>
                                                                <td>
                                                                {achieve_d.indi_award.map((indi_d, indi_index) => (
                                                                    <span key={`indi_award${indi_index}`}>
                                                                        {indi_d.award_name}
                                                                        {indi_index !== achieve_d.indi_award.length - 1 && (<>, &nbsp;</>)}
                                                                    </span>
                                                                ))}   
                                                                </td>
                                                                <td>
                                                                    {achieve_d.team_award.map((team_d, team_index) => (
                                                                        <span key={`team_award${team_index}`}>
                                                                            {team_d.award_name}
                                                                            {team_index !== achieve_d.team_award.length - 1 && (<>, &nbsp;</>)}
                                                                        </span>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {/* {fieldAchieve.map((achieve_d, achieve_index) => (
                                                        <tr key={`achieve${achieve_index}`}>
                                                            <td><b>{achieve_d.level}</b></td>
                                                            <td className="w-110p">
                                                                <div className="w-110p">
                                                                    <Select
                                                                        options={yearData} 
                                                                        defaultValue={formDataAchieve[achieve_index].year}
                                                                        label={"Select Year"}
                                                                        onChange={(value) => updateInput('Achieve', 'array', 'select-react', 'year', value, 'array', 'achievement', achieve_index)}
                                                                        getOptionLabel ={(option) => option.year}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                        isDisabled={formDataAchieve[achieve_index].disabled}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="w-350p">
                                                                    <Select
                                                                        options={indiAwardData} 
                                                                        defaultValue={formDataAchieve[achieve_index].indi_award}
                                                                        label={"Select Individual Award"}
                                                                        onChange={(value) => updateInput('Achieve', 'array', 'select-react', 'indi_award', value, 'array', 'achievement', achieve_index)}
                                                                        getOptionLabel ={(option) => option.award_name}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                        isMulti
                                                                        isDisabled={formDataAchieve[achieve_index].disabled}
                                                                        closeMenuOnSelect={false}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="w-350p">
                                                                    <Select
                                                                        options={teamAwardData} 
                                                                        defaultValue={formDataAchieve[achieve_index].team_award}
                                                                        label={"Select Team Award"}
                                                                        onChange={(value) => updateInput('Achieve', 'array', 'select-react', 'team_award', value, 'array', 'achievement', achieve_index)}
                                                                        getOptionLabel ={(option) => option.award_name}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                        isMulti
                                                                        isDisabled={formDataAchieve[achieve_index].disabled}
                                                                        closeMenuOnSelect={false}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        ))} */}
                                                    </tbody>
                                                </table>
                                                </div>
                                            )}
                                            {/* <p className="text-center mb-2">
                                                {!isProcess['Achieve']
                                                ?
                                                    isBtnDisabled['Achieve']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Achieve')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p> */}
                                        {/* </form> */}
                                        {/* Error */}
                                        {/* <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Achieve'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Achieve'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div> */}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Career Skills */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {['13', '14']}
                                            data = {completedSection}
                                            title={'Key Skills'}
                                            section={"skill"}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> Click and Select top three.</p> */}
                                        <form autoComplete="off">
                                            <b>Your Key Career Skills:</b> Click and Select top three.
                                            {/* <span className="highlight">To be filled only by the Counsellor after referring to Glosco Notes.</span> */}
                                            <br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="w-100">
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                // components={animatedComponents}
                                                                options={careerSkillData} 
                                                                defaultValue={formDataCareer}
                                                                value={formDataCareer}
                                                                label={"Select Key Career Skills"}
                                                                onChange={(value) => updateInput('Career', 'single_value', 'select-react', 'career_skills', value, 'multi_select')}
                                                                getOptionLabel ={(option) => option.skill_name}
                                                                getOptionValue ={(option) => option.id}
                                                                // menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                                isMulti
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                <EvaPortButton
                                                    askEvaHelp={(e, section) => askEvaHelp(e, section)}
                                                    modKey="CareerSkills"
                                                    isEvaProcess={isEvaProcess}
                                                />
                                                {!isProcess['Career']
                                                ?
                                                    isBtnDisabled['Career']
                                                        ?
                                                            <button className="btn btn-primary btnn-disabled mb-2 mb-sm-0" type="submit" disabled>Submit</button>
                                                        :
                                                            <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" type="submit" onClick={(e) => editPortfolio(e, 'Career')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue mb-2 mb-sm-0 ms-2 ms-sm-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Career'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Career'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                        {/* Personal Skills */}
                                        <form autoComplete="off">
                                            <b>Your Key Personal Skills:</b> Click and Select top three. 
                                            <br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="w-100">
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                options={personalSkillData} 
                                                                defaultValue={formDataPersonal}
                                                                label={"Select Key Personal Skills"}
                                                                onChange={(value) => updateInput('Personal', 'single_value', 'select-react', 'personal_skills', value, 'multi_select')}
                                                                getOptionLabel ={(option) => option.skill_name}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                                isMulti
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                <EvaPortButton
                                                    askEvaHelp={(e, section) => askEvaHelp(e, section)}
                                                    modKey="PersonalSkills"
                                                    isEvaProcess={isEvaProcess}
                                                />
                                                {!isProcess['Personal']
                                                ?
                                                    isBtnDisabled['Personal']
                                                        ?
                                                            <button className="btn btn-primary btnn-disabled mb-2 mb-sm-0" type="submit" disabled>Submit</button>
                                                        :
                                                            <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" type="submit" onClick={(e) => editPortfolio(e, 'Personal')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue mb-2 mb-sm-0" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                           
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue mb-2 mb-sm-0 ms-2 ms-sm-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Personal'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Personal'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                             {/* Personal Skills */}
                            {/* <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'14'}
                                            data = {completedSection}
                                            title={'Key Personal Skills'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            <b>Your Key Personal Skills:</b> Click and Select top three. 
                                            <br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="w-100">
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                options={personalSkillData} 
                                                                defaultValue={formDataPersonal}
                                                                label={"Select Key Personal Skills"}
                                                                onChange={(value) => updateInput('Personal', 'single_value', 'select-react', 'personal_skills', value, 'multi_select')}
                                                                getOptionLabel ={(option) => option.skill_name}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                                isMulti
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Personal']
                                                ?
                                                    isBtnDisabled['Personal']
                                                        ?
                                                            <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                        :
                                                            <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Personal')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                           
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Personal'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Personal'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> */}
                            {/* Recommendation */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <CompletedSecUI
                                            form_id = {'15'}
                                            data = {completedSection}
                                            title={'Letter of Recommendation'}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            {/* <b>Your Portfolio Headline:</b> Maximum 15 words, that will capture who you are.<br/><br/> */}
                                            <span>Please input the name, and email of the person you want to seek a recommendation letter from. Then, a recommendation form will be sent to this person from us on your behalf. This recommendation form will seek details of your personal qualities, observations on your interactions with others, and insights on your skills and contributions. Once the recommendation comes in, it would reflect in your portfolio as the last section.</span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table-res">
                                                <tbody>
                                                    {fieldRecom.map((recom_fr, recom_idx) => (
                                                        <tr key={`recomm_${recom_idx}`}>
                                                            <th className="align-baseline">{recom_fr.label}<em>*</em>:</th>
                                                            <td><input className="form-control" name={recom_fr.name} value={formDataRecom[recom_fr.name]} onChange={(e) => updateInput('Recom', 'object', 'input', recom_fr.name, e.target.value)}/></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Recom']
                                                ?
                                                    isBtnDisabled['Recom']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled mb-4 mb-sm-0" type="submit" disabled>Click to Request for Recommendation</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue mb-4 mb-sm-0" type="submit" onClick={(e) => editPortfolio(e, 'Recom')}>Click to Request for Recommendation</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Requesting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Recom'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Recom'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                        {recomFetchData.length > 0 && (
                                        <div>
                                            <hr/>
                                            <p className="mb-1">Requested From:</p>
                                            <div className="port-scroll">
                                            {recomFetchData.map((recomm_d, recomm_index) => (
                                                <div key={`recom_${recomm_index}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex">
                                                        <p className="mb-1">{recomm_index+1}.&nbsp;</p>
                                                        <p className="mb-0">
                                                            <span>
                                                                <b>{recomm_d.name}</b>, {recomm_d.email}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        </>
                        
                    // :
                    //     <div className="col-md-10 col-12 col-sm-12 card p-3">
                    //         <div className="alert alert-success text-center mb-0">
                    //             Your details have been updated successfully.<br/>
                    //             <a href="/portfolio"><button className="btn btn-primary bg-color-blue mt-3">View Your Portfolio</button></a>
                    //         </div>
                    //     </div>
                    }
                    </div>
                </div>
            </section>
        </main>
        {/* For Passion Score */}
        <Modal show={modalToggle.passion} onHide={(e) => toggleModal(e, 'passion', false)} size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Competencies & Career Mapping</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Have you completed the mapping? If No, then complete your IFSPD Europe Competency & Career Mapping at <a href="https://admin.gosurvey.in/survey/CC" title="Unique Skills" target="_blank" rel="noreferrer">https://admin.gosurvey.in/survey/CC</a> and enter the Email below which you provided during the Career Mapping.</p>
                <div className="alert alert-secondary mb-0 team-detail">
                    <table className="table table-res">
                        <tbody>
                        <tr>
                            <th className="w-350p">Email provided by you to attempt the mapping:</th>
                            <td><input className="form-control" name="email" autoComplete="off" value={formDataPassion.email} onChange={(e) => updateInput('Passion', 'object', 'input', 'email', e.target.value)}/></td>
                        </tr>
                        {passionFetchData.length > 0 && (
                        <tr>
                            <td colSpan={2}>
                                <b>Your Competency Score:</b>
                                <div className="table-responsive">
                                <table className="border-0 table-bordered">
                                    {/* <thead>
                                        <tr>
                                            <th className="w-90p">Rank</th>
                                            <th>Passion Score</th>
                                            <th>Scores</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                    {passionFetchData.map((passion, passion_key) => (
                                        <tr key={`mod-passion_${passion_key}`}>
                                            <th>Competency Score Top {passion_key+1}</th>
                                            <td><b>{passion.title}</b>: {passion.unique_ability}</td>
                                            {/* <td><b>{passion.score*10}%</b></td> */}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {passionFetchData.length > 2
                                    ?
                                    <p className="mb-0 text-center">
                                    {!isProcess['Unique']
                                        ?
                                            // isBtnDisabled['Unique']
                                            // ?
                                            //     <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                            // :
                                            <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Unique')}>Confirm & Submit Mapping</button>
                                        :
                                            <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                        }
                                    </p>
                                    :
                                    <p className="mb-0 text-center color-red">Atleast 3 Competency Scores needed, Please Re-attempt IFSPD Europe Competency & Career Mapping at <a href="https://admin.gosurvey.in/survey/CC" title="Unique Skills" target="_blank" rel="noreferrer">https://admin.gosurvey.in/survey/CC</a></p>
                                }
                                </div>
                            </td>
                        </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 col-12 col-sm-12">
                    {errorData['Unique'].length > 0 && (
                        <div className="alert alert-danger mt-4" role="alert">
                            <p className="mb-0">{errorData['Unique'][0]}</p>
                            {/* <p>Please enter/modify following Data:</p>
                            <ol>
                            {errorData['Unique'].map((error, error_index) => (
                                <li key={`error_${error_index}`}>{error}</li>
                            ))}
                            </ol> */}
                        </div>
                    )}
                    </div>
            </Modal.Body>
            <Modal.Footer className="team-detail">
                {passionFetchData.length === 0 && (
                !isProcess['Unique']
                    ?
                        // isBtnDisabled['Unique']
                        // ?
                        //     <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                        // :
                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => fetchMappingData(e)}>Fetch Mapping</button>
                        // <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Unique')}>Fetch Mapping</button>
                    :
                        <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Fetching...</button>
                    
                )}
                <button className="btn btn-secondary" onClick={(e) => toggleModal(e, 'passion', false)}>Close</button>
            </Modal.Footer>
        </Modal>
        {/* For Glosco */}
        <Modal show={modalToggle.glosco} onHide={(e) => toggleModal(e, 'glosco', false)} size="lg" className="team-detail mod-custom" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>IFSPD Europe Global Sustainability Council Participation and Achievements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                <fieldset>
                <legend>Details</legend>
                    <div className="row">
                        <Col xs={12} md={12}>
                            <Form.Label className="mb-3">
                                <b>Glosco Level</b>: Beginner
                            </Form.Label>
                        </Col>
                        {/* Session */}
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor={"glo_session"}>
                                    <b>Session</b><em className="required">*</em>
                                </Form.Label>
                                <div className="w-100">
                                    <Select
                                        id={"glo_session"}
                                        options={sessionOptions} 
                                        defaultValue={formDataAchieve.session_id}
                                        value={formDataAchieve.session_id}
                                        placeholder={'Select your session'}
                                        onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'session_id', value)}
                                        getOptionLabel ={(option) => option.label}
                                        getOptionValue ={(option) => option.id}
                                        menuPosition={'fixed'}
                                        styles={RSstandardStyle}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        {/* Role */}
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor={"glo_role"}>
                                    <b>What was your role in this council?</b><em className="required">*</em>
                                </Form.Label>
                                <div className="w-100">
                                    <Select
                                        id={"glo_role"}
                                        options={roleOptions} 
                                        defaultValue={formDataAchieve.role_id}
                                        value={formDataAchieve.role_id}
                                        placeholder={"Select Role"}
                                        onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'role_id', value)}
                                        getOptionLabel ={(option) => option.label}
                                        getOptionValue ={(option) => option.id}
                                        menuPosition={'fixed'}
                                        styles={RSstandardStyle}
                                        isDisabled={roleOptions.length ? false : true}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        {/* Award */}
                        {(formDataAchieve.role_id
                            && formDataAchieve.session_id
                            // && formDataAchieve.role_id.id !== 15
                            && ((formDataAchieve.session_id.id >= 4 && formDataAchieve.session_id.id <= 9)
                                || (formDataAchieve.session_id.id >= 12 && formDataAchieve.session_id.id <= 14)
                            )
                            && awardOptions.length > 0
                        ) && (
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor={"glo_award"}>
                                    <b>Did you win any award for the LAST COUNCIL?</b><em className="required">*</em>
                                </Form.Label>
                                <div className="w-100">
                                    <Select
                                        id={"glo_award"}
                                        options={awardOptions} 
                                        defaultValue={formDataAchieve.award_id}
                                        value={formDataAchieve.award_id}
                                        placeholder={"Select Award"}
                                        onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'award_id', value)}
                                        getOptionLabel ={(option) => option.label}
                                        getOptionValue ={(option) => option.id}
                                        menuPosition={'fixed'}
                                        styles={RSstandardStyle}
                                        // isDisabled={awardOptions.length ? false : true}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        )}
                        {(formDataAchieve.session_id
                            && (formDataAchieve.session_id.id === 12
                                || formDataAchieve.session_id.id === 14))
                            && formDataAchieve.role_id
                            // && formDataAchieve.role_id.id !== 15
                            && (
                            <>
                                {/* Team Award Confirmation*/}
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label htmlFor={"glo_team_confr"}>
                                            <b>Did your team win any award?</b><em className="required">*</em>
                                        </Form.Label>
                                        <div className="w-100">
                                            <Select
                                                id={"glo_team_confr"}
                                                options={yesNoOption} 
                                                defaultValue={formDataAchieve.is_team_award}
                                                value={formDataAchieve.is_team_award}
                                                placeholder={"Select"}
                                                onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'is_team_award', value)}
                                                getOptionLabel ={(option) => option.title}
                                                getOptionValue ={(option) => option.id}
                                                menuPosition={'fixed'}
                                                styles={RSstandardStyle}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                {/* Team Award */}
                                {(formDataAchieve.is_team_award && formDataAchieve.is_team_award.id === 1) && (
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label htmlFor={"glo_team"}>
                                            <b>Team Award</b><em className="required">*</em>
                                        </Form.Label>
                                        <div className="w-100">
                                            <Select
                                                id={"glo_team"}
                                                options={teamAwardOptions} 
                                                defaultValue={formDataAchieve.team_award_id}
                                                value={formDataAchieve.team_award_id}
                                                placeholder={"Select Team Award"}
                                                onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'team_award_id', value)}
                                                getOptionLabel ={(option) => option.label}
                                                getOptionValue ={(option) => option.id}
                                                menuPosition={'fixed'}
                                                styles={RSstandardStyle}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                )}
                            </>
                        )}
                        {(formDataAchieve.session_id
                            && formDataAchieve.session_id.id === 13)
                            && (
                            <>
                            {/* Date */}
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-4">
                                    <Form.Label htmlFor={"glo_date"}>
                                        <b>Year of completion</b><em className="required">*</em>
                                    </Form.Label>
                                    <div className="w-100">
                                        <Select
                                            id={"glo_date"}
                                            options={yearData} 
                                            defaultValue={formDataAchieve.year}
                                            value={formDataAchieve.year}
                                            placeholder={"Select Year"}
                                            onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'year', value)}
                                            getOptionLabel ={(option) => option.year}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            {/* Cohort Number */}
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-4">
                                    <Form.Label htmlFor={"glo_number"}>
                                        <b>Cohort Number</b><em className="required">*</em>
                                    </Form.Label>
                                    <div className="w-100">
                                        <Select
                                            id={"glo_number"}
                                            options={cohortData} 
                                            defaultValue={formDataAchieve.cohort_number}
                                            value={formDataAchieve.cohort_number}
                                            placeholder={"Select Cohort Number"}
                                            onChange={(value) => updateInput('Achieve', 'object', 'select-react', 'cohort_number', value)}
                                            getOptionLabel ={(option) => option.number}
                                            getOptionValue ={(option) => option.id}
                                            menuPlacement="auto"
                                            menuPosition={'fixed'}
                                            styles={RSstandardStyle}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            </>
                        )}
                        {/* Rate */}
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor={"glo_rate"}>
                                    <b>How interesting was this council?</b><em className="required">*</em>{formDataAchieve.rating && <span>: &nbsp;<b>{formDataAchieve.rating}</b></span>}
                                </Form.Label>
                                {/* <div className="w-100"> */}
                                <div className="rating-content">
                                <div className="d-sm-flex rating">
                                    {ratingOptions.map((rating_d, rating_index) => (
                                        <button
                                            key={`rating_${rating_index}`}
                                            className={`${(rating_d > 6 && rating_d < 9)
                                                            ? 'yellow'
                                                            : rating_d > 8
                                                                ? 'green'
                                                                : 'red'
                                                        } ${rating_d === formDataAchieve.rating ? 'active' : ''}`}
                                            onClick={(e) => { e.preventDefault(); updateInput('Achieve', 'object', 'select-react', 'rating', rating_d)}}
                                            // onClick={(e) => updateInput('Achieve', 'object', 'select-react', 'rating', 1, e)}
                                        >{rating_d}</button>
                                    ))}
                                </div>
                                <p className="d-none d-sm-flex justify-content-between me-2 mb-0">
                                    <span>Not Interesting</span>
                                    <span>Interesting</span>
                                </p>
                                </div>
                            </Form.Group>
                        </Col>
                        {/* Learning */}
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-4">
                                <Form.Label htmlFor={"glo_learn"}>
                                    <b>What new learning or skill or insight did you gain from this Council?</b><em className="required">*</em>
                                </Form.Label>
                                <textarea
                                    className="form-control"
                                    id={"glo_learn"}
                                    name={"learning"}
                                    rows={5}
                                    value={formDataAchieve.learning}
                                    onChange={(e) => updateInput("Achieve", 'object', 'textarea', "learning", e.target.value)}
                                    placeholder={""}
                                    // readOnly={props.isProcess}
                                />
                                <CharacterCount
                                    max={250}
                                    string={formDataAchieve.learning}
                                />
                            </Form.Group>
                        </Col>
                    </div>
                </fieldset>
            </Form>
            <div className="col-md-12 col-12 col-sm-12">
                {errorData['Achieve'].length > 0 && (
                    <div className="alert alert-danger mt-3" role="alert">
                        <p>Please enter/modify following Data:</p>
                        <ol>
                        {errorData['Achieve'].map((error, error_index) => (
                            <li key={`error_${error_index}`}>{error}</li>
                        ))}
                        </ol>
                    </div>
                )}
            </div>
            </Modal.Body>
            <Modal.Footer className="team-detail">
                <EvaPortButton
                    askEvaHelp={(e, section) => askEvaHelp(e, section)}
                    modKey={'Achieve'}
                    isEvaProcess={isEvaProcess}
                />
                {!isProcess['Achieve']
                    ?
                        // isBtnDisabled['Achieve']
                        // ?
                        //     <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                        // :
                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Achieve')}>Submit</button>
                    :
                        <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                }
                <button className="btn btn-secondary ms-2" onClick={(e) => toggleModal(e, 'glosco', false)}>Close</button>
            </Modal.Footer>
        </Modal>
        {/* For All Similar/Common Form */}
        <ModalMaster
            isShow={modalToggle.Master}
            toggleModal={(e, state) => toggleModal(e, 'Master', state)}
            modKey={activeEditForm}
            formType={'Master'}
            data={editFormData}
            certLabelKey={certLabelKeys[activeEditForm]}
            doc={previewDocData}
            title={masterTitle[activeEditForm]}
            removeUploadedDoc = {(e, index) => removeUploadedDoc(e, index, 'Master')}
            previewDoc = {(e, form_type, file_type) => previewDoc(e, form_type, file_type)}
            removeRankDoc = {(e, form_type) => removeRankDoc(e, form_type)}
            errorData={errorData.Master}
            yearData={yearData}
            isProcess={isProcess.Master}
            isBtnDisabled={isBtnDisabled.Master}
            updateInput= {(identifier_key, identifier_type, input_type, field, value) => updateInput(identifier_key, identifier_type, input_type, field, value)}
            userUrl={userUrl}
            editPortfolio={(e, form_type, active_form) => editPortfolio(e, form_type, active_form)}
            fields={masterFieldData}
            section={modalSecShow}
            errorDataUp={errorData.UpdateDoc}
            isProcessUp={isProcess.UpdateDoc}
            isBtnDisabledUp={isBtnDisabled.UpdateDoc}
            // selectOptions={{...formDataOption}}
            selectOptions={{emotion_id: 
                                {value: 'id',
                                label: 'emotion',
                                data: [...emotionData],
                                max: 2,
                                format: {req: true, key: 'label', subkey: 'description'}},
                            theme_id: 
                                {value: 'id',
                                label: 'theme',
                                data: [...themeData],
                                max: 2,
                                format: {req: true, key: 'label', subkey: 'description'}},
                            competition_id: 
                                {value: 'id',
                                label: 'label',
                                data: [...competeData],
                                format: {req: false}},
                            institute_type_id: {value: 'id', label: 'type', data: [...instituteType]},
                            school_id: {value: 'id', label: 'school_name', data: [...schoolData]},
                            branch_id: {value: 'id', label: 'branch_name', data: [...branchData]},
                            class_id: {value: 'id', label: 'class_name', data: [...classData]},
                            course_id: {value: 'id', label: 'class_name', data: [...courseData]},
                            edu_classify_id: {value: 'id', label: 'label', data: [...classifyDataEdu.classify]},
                            edu_subject_id: {value: 'id', label: 'label', data: [...classifyDataEdu.subject]},
                            edu_rank_id: {value: 'id', label: 'label', data: [...classifyDataEdu.rank]},
                            exp_classify_id: {value: 'id', label: 'label', data: [...classifyDataExp.classify]},
                            exp_work_type_id: {value: 'id', label: 'label', data: [...classifyDataExp.work_type]},
                            exp_company_type_id: {value: 'id', label: 'label', data: [...classifyDataExp.company_type]},
                            research_classify_id: {value: 'id', label: 'label', data: [...classifyDataResearch.classify]},
                            research_work_type_id: {value: 'id', label: 'label', data: [...classifyDataResearch.work_type]},
                            research_subject_id: {value: 'id', label: 'label', data: [...classifyDataResearch.subject]},
                            research_com_level_id: {value: 'id', label: 'label', data: [...classifyDataResearch.com_level]},
                            research_rank_id: {value: 'id', label: 'label', data: [...classifyDataResearch.rank]},
                            award_classify_id: {value: 'id', label: 'label', data: [...classifyDataAward.classify]},
                            award_domain_id: {value: 'id', label: 'label', data: [...classifyDataAward.domain]},
                            award_level_id: {value: 'id', label: 'label', data: [...classifyDataAward.com_level]},
                            award_rank_id: {value: 'id', label: 'label', data: [...classifyDataAward.rank]},
                            sport_classify_id: {value: 'id', label: 'label', data: [...classifyDataSport.classify]},
                            sport_work_type_id: {value: 'id', label: 'label', data: [...classifyDataSport.work_type]},
                            sport_domain_id: {value: 'id', label: 'label', data: [...sportDomainData]},
                            sport_level_id: {value: 'id', label: 'label', data: [...classifyDataSport.com_level]},
                            sport_rank_id: {value: 'id', label: 'label', data: [...classifyDataSport.rank]}
                        }}
            askEvaHelp={(e, section) => askEvaHelp(e, section)}
            isEvaProcess={isEvaProcess}
            // doc_url={docUrlData}
        />
        {/* For Showing Hub Info */}
        {/* <ModalHubInfo
             isShow={modalToggle.hub_info}
             toggleModal={(e, state) => toggleModal(e, 'hub_info', state)}
             loader={hubInfoLoading}
             data={hubModalDetails}
        /> */}
        {/* AskEva Chatbot */}
        {/* {userUrl} */}
        {/* {(userUrl === 'jagannath-girisaballa'
        || userUrl === 'akshay-panchavati'
        || userUrl === 'zac-sangeeth'
        || userUrl === 'anna-jacob'
        || userUrl === 'test-change'
        || userUrl === 'ritika-kriti-singh')
            && ( */}
            {!pageLoading && (
                <Chatbot
                    completed_sec={completedSection}
                    chat_user_data={askevaPayload}
                    activateEvaBtn={() => setEvaProcess(false)}
                    isCustom={true}
                />
            )}
        {/* )} */}
     </>
    )    
}

export default PortfolioEditForm;