import React, {useState, useEffect} from 'react';
// import {useLocation} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../../assets/css/pages/form.css';
import { alertResponseMessage } from "../../actions/action-creators/response";
// import { SpinnerRoundFilled } from 'spinners-react';
import {LoaderMedium} from './../../components/loader';
//Axios Call
import {error_api_call, post_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';
const initialSelectData = {
  validity: "12",
  auto_renew: true
};

const PaymentPage = () => {
  SetMetaData("Payment", "", "");
  // const {state} = useLocation();
  // const { redirect_uri } = state;
  // const location = useLocation();
  const [pageLoading, setPageLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [planData, setPlanData] = useState({});
  const [planApiData, setPlanApiData] = useState({});
  const [priceData, setPriceData] = useState({});
  const [expiryData, setExpiryData] = useState({});
  const [selectedPlanData, setSelectedData] = useState(initialSelectData);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  // const [redirectUri, setRedirectUri] = useState('');
  //Redirection Url
  // useEffect(() => {
  //   const urlHandling = () => {
  //     try{
  //       // const urlQuryString = window.location.search;
  //       const urlQuryString = location.search;
  //       const urlParam = new URLSearchParams(urlQuryString);
  //       const urlRedirect = urlParam.get('redirect_uri');
  //       if(urlRedirect !== '' && urlRedirect !== null){
  //         setRedirectUri(urlRedirect);
  //       }
  //     }catch(e){
  //       error_api_call({error: `payment/landing|urlHandling|${e}`});
  //     }
  //   }
  //   urlHandling();
  // }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Initial Data
  useEffect(() => {
    const fetchPlanDetails = async() => {
      try{
        const planApiData = await post_api_call(`payment-plan`, {});
        if(planApiData.status === 200){
          var planDetails = {};
          planApiData.data.plan_details.forEach(plan_d => {
            planDetails[plan_d.service_id] = {...plan_d,
                              price_display: `${plan_d.currency_type === 1 ? `₹` : `$`} ${_formatCurrency(plan_d.price, plan_d.currency_type)}`
                            };
          });
          //Expiry Details
          // var expiryDetails = {};
          // planApiData.data.plan_details.forEach(plan_d => {
          //   planDetails[plan_d.service_id] = {...plan_d,
          //                                     price_display: `${plan_d.currency_type === 1 ? `₹` : `$`} ${plan_d.price}`
          //                                     }
          // });
          setPlanData({...planDetails});
          setExpiryData({...planApiData.data.expiration_date});
          setPlanApiData({...planApiData.data});
          setPageLoader(false);
        }
      }catch(e){
        error_api_call({error: `payment/landing|fetchPlanDetails|${e}`});
      }
    }
    fetchPlanDetails();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Open Checkout Modal
  const showCheckoutModal = (event, plan_data, state=true) => {
    try{
      if(state !== false){
        event.preventDefault();
      }
      setShowModal(state);
      setSelectedData(state ? {...selectedPlanData, ...plan_data} : {...initialSelectData});
      if(state){
        // _priceCalculation();
      }else{
        setPriceData({});
      }
    }catch(error){
      console.error(error)
      error_api_call({error: `payment/landing|showCheckoutModal|${error}`});
    }
  }
  //Update Selected Plan
  const _updateSelectedPlan = (input, field) => {
    try{
      if(field === 'service_id'){
        if(input === 4
          || input === '4'
        ){
          setSelectedData({...selectedPlanData, ...planData[input], validity: 12});
        }else{
          setSelectedData({...selectedPlanData, ...planData[input]});
        }
      }else{
        setSelectedData({...selectedPlanData, [field]: input});
      }
      //Price Calculation
      // _priceCalculation();
    }catch(e){
      console.log(e)
      error_api_call({error: `payment/landing|_updateSelectedPlan|${e}`});
    }
  }
  //Calculate Price
  // const _priceCalculation = () => {
  //   try{
  //     //Price Calculation
  //     console.log(selectedPlanData.validity/12, 'lllllllllllllllllllllllllllllllll')
  //     const price = (parseInt(selectedPlanData.validity)/12)*selectedPlanData.price;
  //     const gst = planApiData.price_details.gst;
  //     const platformCharge = planApiData.price_details.platform_fee;
  //     const gstCal = gst > 0 ? (gst/100)*price : 0;
  //     const platformChargeCal = platformCharge > 0 ? (platformCharge/100)*price : 0;
  //     const total = parseInt(price) + parseInt(gstCal) + parseInt(platformChargeCal);
  //     setPriceData({gst: gstCal, platform_fee: platformChargeCal, total, price});
  //   }catch(e){
  //     console.log(e)
  //     error_api_call({error: `payment/landing|_priceCalculation|${e}`});
  //   }
  // }
  
  useEffect(() => {
		//Get Filtered SStartup
    const _priceCalculation = () => {
      try{
        //Price Calculation
        const price = ((parseInt(selectedPlanData.validity)/12)*selectedPlanData.price).toFixed(2);
        const gst = selectedPlanData.gst;
        const platformCharge = selectedPlanData.platform_fee;
        const gstCal = gst > 0 ? parseFloat((gst/100)*price).toFixed(2) : 0;
        const payAmount = parseFloat(price) + parseFloat(gstCal);
        const platformChargeCal = platformCharge > 0 ? parseFloat((platformCharge/100)*payAmount).toFixed(2) : 0;
        const total = parseFloat(price) + parseFloat(gstCal) + parseFloat(platformChargeCal);
        setPriceData({gst: _formatCurrency(gstCal),
                    platform_fee: _formatCurrency(platformChargeCal),
                    total: _formatCurrency(Math.round(total).toFixed(2)),
                    price: _formatCurrency(price)
                  });
                  // price: price.toLocaleString("en-IN", {maximumSignificantDigits: 0, style: 'decimal', currency: 'USD' }
      }catch(e){
        alertResponseMessage({alert_type: 2, message: "Error Loading Details! please try again"});
        console.log(e)
        error_api_call({error: `payment/landing|_priceCalculation|${e}`});
      }
    }
		if(selectedPlanData.service_id){
			_priceCalculation();
		}
	}, [selectedPlanData]);// eslint-disable-line react-hooks/exhaustive-deps
  //To check date
  const isDate = (val) => {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]';
  }
  //To check Object
  const isObj = (val) => {
    return typeof val === 'object';
  }
  //Stringify Data
  const stringifyValue = (val) => {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }
  //Build Paytm Payment Form
  const buildForm = ({ action, params }) => {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)
  
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
  
    return form;
  }
  //Redirect to Paytm Gateway
  const payuPageRedirection = (details) => {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }
  //checkout
  const _checkout = async(e) => {
      try{
        e.preventDefault();
        setCheckoutLoader(true);
        // return;
        const placeOrderData = await post_api_call(`payment/initiate`, {...selectedPlanData,
                                                                        amount: priceData.total,
                                                                        // expiry_date: expiryData[`port_${selectedPlanData.validity}`]
                                                                      });
        // console.log(placeOrderData, 'placeOrderData.status')
        // return;
        if(placeOrderData.status === 200){
          payuPageRedirection({
            action: process.env.REACT_APP_PAYU_URL,
            // action: 'https://test.payu.in/_payment',
            // action: 'https://secure.payu.in/_payment',
            params: placeOrderData.data
          });
        }else{
          setCheckoutLoader(false);
        }
      }catch(error){
        setCheckoutLoader(false);
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
        error_api_call({error: `payment/landing|_checkout|${error}`});
      }
  }
  //Currency Type Display
  const _displayCurrency = (currency_type) => {
    return currency_type === 1 ? `₹` : `$`;
  }
  //formatCurrency
  const _formatCurrency = (amount, cur_type="") => {
    try{
      var currencyType = cur_type ? cur_type : selectedPlanData.currency_type;
      currencyType = currencyType === 1 ? 'INR' : 'USD'
      const amountDec = amount.toString().split(".");
      // console.log(amountDec)
      var amountCur = '';
      if(amountDec.length > 1){
        // amountCur = amountCur[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") + (amountCur[1] ? ("."+amountCur[1]): "");
        amountCur = new Intl.NumberFormat('en-US', {currency: currencyType}).format(amount); // '$123,456.79'
        // amountCur = (amountCur).toFixed(2);
        amountCur = amountCur.toString().split(".").length > 1 ? amountCur : amountCur+".00";
      }else{
        amountCur = amount.toLocaleString("en-IN", {currency: currencyType });
      }
      return amountCur;
    }catch(error){
      error_api_call({error: `payment/landing|_formatCurrency|${error}`});
      return amount;
    }
  }
  return (
    <>
    <main id="main">
        <section className="mt-60 pt-4">
            <div className="container-fluid container-lg">
                <div className="section-header section-header-1">
                    <h1 className="title">Subscription Plans</h1>
                </div>       
                {/* <div className="card"> */}
                    <div className="row payment-plan justify-content-center">
                    {pageLoading 
                    ? 
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                    <>
                      {/* <a href={state && state.redirect_uri ? state.redirect_uri : '/port'}><p className="skip-text">Skip for Now</p></a> */}
                      {/* <a href={redirectUri ? redirectUri : '/port'}><p className="skip-text">Skip for Now</p></a> */}
                      {(planApiData.validity
                        && planApiData.validity.services
                        && planApiData.validity.services.length
                      )
                        ? 
                          // <a href={redirectUri}><p className="skip-text">Skip for Now</p></a>
                          <a href="/port/edit"><p className="skip-text">Skip for Now</p></a>
                        : 
                          <div className="ps-2 pe-2">
                          <div className="alert alert-danger text-center p-1 mb-5">
                            <p className="fs-5 mb-0 p-1"><b>Your subscription has expired! Please renew it.</b></p>
                          </div>
                          </div>
                      }
                      {/* <p className="fs-3 mb-3 text-center fw-bold"><span className="color-blue">Pick your perfect plan</span></p> */}
                      <div className="justify-content-center row text-center">
                      <div className="col-12 col-md-8 col-sm-8">
                      {(planApiData.is_glosco
                        && planApiData.is_glosco === 1)
                        && (
                        <p><b>Global Sustainability Councils</b>, IFSPD Europe’s exclusive by-invite-only program, is reserved for top students from selected schools. Gain strategic skills, global certification, and international validation. Over 50% of students secure top college admissions. Book your seat before the deadline!</p>
                      )}
                      </div>
                      </div>
                      {Object.keys(planData).reverse().map((plan_index) => (
                        <div className="col-12 col-md-4 col-sm-8" key={`plan_${plan_index}`}>
                          <div className="card text-center p-3 mb-4">
                              <h6 className="color-blue fs-5"><b>{planData[plan_index].name}</b></h6>
                              <div className="content">
                                <div dangerouslySetInnerHTML={ { __html: planData[plan_index].description}}></div>
                                <p>12 months for <b>{planData[plan_index].price_display}</b> &nbsp;{planData[plan_index].mrp > planData[plan_index].price && (<span className="strike">${_formatCurrency(planData[plan_index].mrp_dollar, 2)}/{_displayCurrency(planData[plan_index].currency_type)} {_formatCurrency(planData[plan_index].mrp, planData[plan_index].currency_type)}</span>)}</p>
                                <button
                                  className="btn btn-primary bg-color-blue fw-bold"
                                  onClick={(e) => showCheckoutModal(e, planData[plan_index])}
                                >Subscribe Now</button>
                              </div>
                          </div>
                      </div>
                      ))}
                    </>
                  }
                  </div>
                {/* </div> */}
            </div>
        </section>
    </main>
    {/* Payment Details Modal */}
    <Modal
      show={showModal}
      onHide={(e) => showCheckoutModal(e, {}, false)}
      // size="md"
      className="pay-modal"
      dialogClassName="modal-custom-wid"
      backdrop="static"
    > 
      {!checkoutLoader && (
        <Modal.Header closeButton>
        {/* <Modal.Title> */}
          {/* Competencies & Career Mapping */}
        {/* </Modal.Title> */}
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="detail justify-content-between">
          <div className="desc">
            <h5>Subscription Plan</h5>
            <p>Review the details and proceed to checkout</p>
            <p className="d-flex"><span className="color-blue">Plan:</span>&nbsp;&nbsp;
              <select
                className="border-0 w-100"
                value={selectedPlanData.service_id}
                onChange={(e) => _updateSelectedPlan(e.target.value, 'service_id')}
                disabled={Object.keys(planData).length > 1 ? false : true}
              >
                {Object.keys(planData).reverse().map((s_plan_key) => (
                  <option key={`plan-${s_plan_key}`} value={planData[s_plan_key].service_id}>{planData[s_plan_key].name}</option>
                ))}
              </select>
            </p>
            {(selectedPlanData.service_id
              && selectedPlanData.service_id !== 4)
              && (
                <p className="mb-0 d-flex"><span className="color-blue">Validity:</span>&nbsp;&nbsp;
                  <select
                    className="border-0 w-100"
                    value={selectedPlanData.validity}
                    onChange={(e) => _updateSelectedPlan(e.target.value, 'validity')}
                  >
                    <option value="48">48 months</option>
                    <option value="36">36 months</option>
                    <option value="24">24 months</option>
                    <option value="12">12 months</option>
                  </select>
                </p>
            )}
          </div>
          <div className="">
            <p className="mb-0 price">{selectedPlanData.price_display}<small>/Year</small></p>
          </div>
        </div>
        <div className="p-2 mt-4 pay-info">
          <table className="mb-0 border-0">
              <tbody>
                <tr>
                    <th>Expiration Date**</th>
                    <td className="text-end">
                      {selectedPlanData.service_id === 1//Port
                        ?
                          <span>{moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                        :
                          selectedPlanData.service_id === 2//Social
                            ?
                              <span>{moment(expiryData[`ss_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                            :
                              selectedPlanData.service_id === 3//Port+Social
                              ?
                                // expiryData[`port_${selectedPlanData.validity}`] === expiryData[`ss_${selectedPlanData.validity}`]
                                //   ?
                                //     <span>{moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                                //   :
                                    <span>Port: {moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")} <br/>Social Start-up: {moment(expiryData[`ss_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                              : selectedPlanData.service_id === 4//Port+Glosco
                                ?
                                  // expiryData[`port_${selectedPlanData.validity}`] === expiryData[`glo_${selectedPlanData.validity}`]
                                  //   ?
                                  //     <span>{moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                                  //   :
                                      // <span>Port: {moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")} <br/>GLOSCO: {moment(expiryData[`glo_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                                      <span>Port: {moment(expiryData[`port_glosco`]).format("Do MMM YYYY")} <br/>Glosco: {moment(expiryData[`glo_12`]).format("Do MMM YYYY")}</span>
                                : <></>
                      }
                    </td>
                </tr>
                <tr>
                    <th>Fee</th>
                    <td className="text-end"><span>{_displayCurrency(selectedPlanData.currency_type)} {priceData.price}</span></td>
                  </tr>
                {(selectedPlanData.gst > 0) && (
                  <tr>
                    <th>Taxes&nbsp;
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 0, hide: 0 }}
                        overlay={<Tooltip>{`Taxes include ${selectedPlanData.gst}% GST`}</Tooltip>}
                      ><i className="fa fa-info-circle"></i></OverlayTrigger>
                    </th>
                    <td className="text-end"><span>{_displayCurrency(selectedPlanData.currency_type)} {priceData.gst}</span></td>
                  </tr>
                )}
                {(selectedPlanData.platform_fee > 0) && (
                <tr>
                    <th>Platform Charges</th>
                    <td className="text-end"><span>{_displayCurrency(selectedPlanData.currency_type)} {priceData.platform_fee}</span></td>
                  </tr>
                )}
                <tr>
                    <th><b>Total to Pay</b></th>
                    <td className="text-end"><span><b>{_displayCurrency(selectedPlanData.currency_type)} {priceData.total}</b></span></td>
                </tr>
              </tbody>
          </table>
          <div className="note p-1 mt-3">
            {/* <p className="mb-0 fw-lighter"><b>Expiration Date</b>: 1 Year + 3 days <b>FREE</b> are included with this subscription.</p> */}
            <p className="mb-0 fw-lighter">**<b>Expiration Date</b>: Existing validity added to the new Expiration Date, if any.</p>
          </div>
        </div>
        {(selectedPlanData.service_id
          && selectedPlanData.service_id !== 4)
          && (
            <p className="mb-0 mt-2 text-end">
              <input
                className="form-check-input"
                type="checkbox"
                id="auto_renew"
                value={selectedPlanData.auto_renew}
                checked={selectedPlanData.auto_renew}
                onChange={(e) => _updateSelectedPlan(e.target.checked, 'auto_renew')}
              />
              <label className="form-check-label" htmlFor="auto_renew">&nbsp;Auto Renew</label>
            </p>
          )}
      </Modal.Body>
      <Modal.Footer>
        <p className="term">By checking out, you agree with our <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms of Service</a> and confirm that you have read our <a href="/privacy-policy" target="_blank" >Privacy Policy</a>.</p>
        <div className="d-flex">
          {!checkoutLoader && (
            <div className="d-none d-sm-flex">
              <button className="btn btn-secondary me-2" onClick={(e) => showCheckoutModal(e, {}, false)}>Cancel</button>
            </div>
          )}
          {checkoutLoader
            ?
              <button className="btn btn-primary bg-color-blue" disabled type="submit"><LoaderMedium size={20} /> Redirecting to Payment Page...</button>
            :
            <button
              className="btn btn-primary bg-color-blue"
              type="submit"
              onClick={(e) => _checkout(e)}
            >Choose payment method</button>
          }
        </div>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default PaymentPage
