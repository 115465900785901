import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {useLocation, Link} from 'react-router-dom';//useNavigate
import Select, { components } from 'react-select';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
import { alertResponseMessage } from "../../actions/action-creators/response";
// import { SpinnerRoundFilled } from 'spinners-react';
import {LoaderMedium} from './../../components/loader';
import ValidationHelper from '../../helper/validation';
import { instituteType } from '../../components/select/option';
//Axios Call
import {login_register} from '../../actions/action-creators/auth';
import {error_api_call, get_api_call, post_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';
import {RSNoRadiusStyle, RSRegisterStyle, RSRegErrStyle, RSNoRadiusErrStyle } from "../../config/react-select";
import { todayDateForm } from '../../components/form/date';
import ShareIconsComp from '../../components/form/share';
import moment from 'moment';

const inputDefaultValue = {
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  phone_code_id: '',
  mobile: '',
  password: '',
  country_id: '',
  state_id: '',
  city_name: '',
  institute_type_id: '',
  school_id: '',
  // school_name: '',
  branch_id: '',
  // branch_name: '',
  class_id: '',
  // class_name: '',
  referral_code: '',
  email_preference: true
};
const initRefProcData = {
  process: false,
  success: false,
  message: ''
};
// const inputDefaultValue = {
//   first_name: 'Riti',
//   last_name: '',
//   dob: '1992-08-23',
//   email: 'riti@riti.com',
//   phone_code_id: {id: 101},
//   mobile: '9804018840',
//   password: '123456',
//   country_id: {id: 101},
//   state_id: {id: 1},
//   city_name: 'Kolkata',
//   school_id: {id: 0},
//   school_name: 'IGMS',
//   branch_id: '',
//   branch_name: '',
//   class_name: '',
//   referral_code: ''
// };
var validatorData = {//To Show error box
  first_name: 'required|max:50',
  last_name: 'optional|max:50',
  dob: 'required||Date of Birth',
  email: 'required|email|max:150',
  phone_code_id: 'required||STD Code/Mobile Number code',
  mobile: 'required|digit:|min:6|max:15||WhatsApp Number',
  password: 'required|min:8|max:15',
  country_id: 'required||Country',
  city_name: 'required|max:100||City/Town',
  institute_type_id: 'required||Institution Type',
  class_id: 'required||Grade/Class/Course',
  // class_name: 'required|max:100||Grade/Class/Course Name',
  state_id: 'required||State',
  // school_name: 'required|max:100||School/College/Organisation Name',
  school_id: 'required||School',
  // branch_name: 'required|max:100||School/College/Org Branch Name'
};

const RegisterPage = () => {
  SetMetaData("Register", "", "");
  // const navigate = useNavigate();
  const { ValueContainer, Placeholder } = components;
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const location = useLocation();
  // const isMounted = useRef(null);
  //Store Data
  // const authReduxData = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [inputFields, setFields] = useState(inputDefaultValue);
  const [securePassword, setsecurePassword] = useState(true);
  // const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isRegProcess, setIsRegProcess] = useState(false);
  const [refProcData, setRefProcData] = useState({...initRefProcData});
  const [errorData, setErrorData] = useState([]);
  const [errRefData, setErrRefData] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [validCheckData, setValidCheck] = useState({first_name: true,
                                                    last_name: true,
                                                    dob: true,
                                                    email: true,
                                                    phone_code_id: true,
                                                    mobile: true,
                                                    password: true,
                                                    country_id: true,
                                                    state_id: true,
                                                    city_name: true,
                                                    school_id: true,
                                                    school_name: true,
                                                    branch_id: true,
                                                    branch_name: true,
                                                    class_id: true,
                                                    class_name: true,
                                                    referral_code: true,
                                                    institute_type_id: true
                                                  });
  //Get Data from APIs
  const fetchSelectList = async(url, data={}) => {
    try{
      const apiUrl = (url === 'schools')
                    ? `${url}?fetch_type=is_glosco`
                    : url;
      const selectApiData = await get_api_call(apiUrl, data);
      if(selectApiData.status === 200){
        switch (url) {
          case 'countries':
            setCountryData(selectApiData.data);
            break;
          case 'states':
            setStateData(selectApiData.data);
            break;
          case 'branches':
            var branchList = [{id: -1, branch_name: "N/A (Not Applicable)"}, ...selectApiData.data];
            branchList.push({id: 0, branch_name: "Other"});
            setBranchData(branchList);
            break;
          case 'schools':
            var schoolList = [...selectApiData.data];
            schoolList.push({id: 0, school_name: "Other"});
            setSchoolData(schoolList);
            break;
          case 'classes':
            var clasList = [...selectApiData.data];
            // clasList.push({id: 0, class_name: "Other"});
            setClassData(clasList);
            var courseList = [...selectApiData.course_data];
            courseList.push({id: 0, class_name: "Other"});
            setCourseData(courseList);
            break;
          default: 
        }
      }
    }catch(e){
      error_api_call({error: `auth|register|fetchSelectList|${e}`});
    }
  }
  //Redirection Url
  useEffect(() => {
    const urlHandling = () => {
      try{
        // const urlQuryString = window.location.search;
        const urlQuryString = location.search;
        const urlParam = new URLSearchParams(urlQuryString);
        const urlRedirect = urlParam.get('redirect_uri');
        // console.log(urlRedirect, 'urlRedirect')
        if(urlRedirect !== '' && urlRedirect !== null){
          setRedirectUri(urlRedirect);
        }
      }catch(e){
        error_api_call({error: `auth/register|urlHandling|${e}`});
      }
    }
    urlHandling();
    // fetchSelectList('branches');
    fetchSelectList('schools');
    fetchSelectList('countries');
    fetchSelectList('states');
    fetchSelectList('classes');
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Update Input Box
  const _onChangeText = (attrName, value, type='') => {
    try{
      setErrorData([]);
      if(attrName === 'referral_code'){
        setErrRefData('');
      }
      let fields = {...inputFields};
      // console.log(value.length, 'value.length')
      if(attrName === 'mobile' 
        && value.length < 16
      ){
        value = value.replace(/[^0-9]/g, '');
        // fields[attrName] = value;
      }else if(attrName === 'last_name'
        || attrName === 'first_name'
      ){
        //Only letter & Space
        value = value.replace(/[^a-zA-Z. ]/g, '')
                    .replace('  ', ' ');
      }else if(attrName === 'email'
        || attrName === 'password'
        || attrName === 'referral_code'
      ){
        value = value.replace(/ /g, '');
      }else{
        // if(attrName === 'first_name'){
        //   value = value.replace(/[^a-zA-Z]/g, '');
        // }
        // fields[attrName] = value.replace(' ', '');
        
        if(type !== 'select'
          && type !== 'checkbox'
        ){
          // value = value.replace(/  /g, ' ');
          value = value.replace('  ', ' ');
        }
      }
      fields[attrName] = value;
      if(attrName === 'institute_type_id'){
        fields['class_id'] = '';
        fields['branch_id'] = '';
        fields['school_id'] = '';
      }else if(attrName === 'school_id'){
        fields['branch_id'] = '';
      }
      setFields({...fields});
      //Validation Check
      if(typeof validatorData[attrName] !== "undefined"){
        const validation = ValidationHelper.validation(fields, {[attrName]: validatorData[attrName]}).error;
        setValidCheck({...validCheckData, [attrName]: validation.length ? false : true});
      }
      //Load Branch Accordingly
      if(attrName === 'institute_type_id'){
        setBranchData([]);
        if(value.id){
          //School Selected
          if(value.id === 1){
            if(fields.school_id
              && fields.school_id.id
            ){
              fetchSelectList('branches', {school_id: fields.school_id.id});
            }
          }else if(value.id === 2){
            //College Selected
            fetchSelectList('branches');
          }
        }
      }else if(attrName === 'school_id'){
        setBranchData([]);
        if(value.id){
          fetchSelectList('branches', {school_id: value.id});
        }
      }
    }catch(e){
      console.log(e)
      error_api_call({error: `auth/register|_onChangeText|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {/* <div className={`${child && child.type !== Placeholder ? 'select-float' : ''}`}> */}
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
        {/* </div> */}
      </ValueContainer>
    );
  };
  //Input Validation:Use to Toggle Button: Disable-true|false
  // useEffect(()=>{
  //   //Validate Input
  //   const _validate = () => {
  //     // if (!didMount) return null;
  //     let fields = {...inputFields};
  //     try{
  //       var validators = {
  //         first_name: 'required|max:50',
  //         last_name: 'optional|max:50',
  //         dob: 'required||Date of Birth',
  //         email: 'required|email|max:150',
  //         phone_code_id: 'required||Mobile Number code',
  //         mobile: 'required|digit:|min:6|max:15',
  //         password: 'required|min:8|max:15',
  //         country_id: 'required||Country',
  //         city_name: 'required|max:100||City/Town',
  //         class_name: 'optional|max:100||Grade/Class/Course',
  //       };
        
  //       if(fields.country_id
  //         && typeof fields.country_id.id !== "undefined"
  //         && fields.country_id.id === 101
  //       ){
  //         validators['state_id'] = 'required||State';
  //       }
  //       //School: For Other
  //       if(fields.school_id
  //         && typeof fields.school_id.id !== "undefined"
  //         && fields.school_id.id === 0
  //       ){
  //         validators['school_name'] = 'required|max:100||School/College/Organisation Name';
  //       }else{
  //         validators['school_id'] = 'required||School/College/Organisation Name';
  //       }
  //       //Branch:
  //       if(fields.branch_id
  //         && typeof fields.branch_id.id !== "undefined"
  //         && fields.branch_id.id === 0
  //       ){
  //         validators['branch_name'] = 'required|max:100||School/College/Org Branch Name';
  //       }
  //       var validation = ValidationHelper.validation(fields, validators).error;
  //       console.log(validation, 'validation')
  //       setSubmitDisabled(validation.length ? true : false);
  //       return validation.length ? false : true;
  //     }catch(e){
  //       error_api_call({error: `auth/register|_validate|${JSON.stringify(inputFields)}|${e}`});
  //     }
  //   };
  //   _validate();
  //   isMounted.current = true;
  //   return () => {
  //     // executed when unmount
  //     isMounted.current = false;
  //   }
  // }, [inputFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Toggle Show-Hide Password
  const _showHidePass = () => {
    setsecurePassword(!securePassword)
  };
  //Validation
  const _validate = () => {
    // if (!didMount) return null;
    let fields = {...inputFields};
    try{
      var validators = {
        first_name: 'required|max:50',
        last_name: 'optional|max:50',
        dob: 'required||Date of Birth',
        email: 'required|email|max:150',
        phone_code_id: 'required||STD Code/Mobile Number code',
        mobile: 'required|digit:|min:6|max:15||WhatsApp Number',
        password: 'required|min:8|max:15',
        country_id: 'required||Country',
        city_name: 'required|max:100||City/Town',
        institute_type_id: 'required||Institution Type',
        // class_name: 'optional|max:100||Grade/Class/Course',
      };
      if(fields.country_id
        && typeof fields.country_id.id !== "undefined"
        && fields.country_id.id === 101
      ){
        validators['state_id'] = 'required||State';
      }
      //School
      if(fields.institute_type_id
        && typeof fields.institute_type_id.id !== "undefined"
      ){
        if(fields.institute_type_id.id === 1){//School
          if(fields.country_id
            && typeof fields.country_id.id !== "undefined"
            && fields.country_id.id === 101
          ){
            validators['school_id'] = 'required||School';
          }
          validators['class_id'] = 'required||Class';
          // validators['school_name'] = 'required|max:100||School';
        }else if(fields.institute_type_id.id === 2){//College
          validators['class_id'] = 'required||Course';
        }
      }
      //Branch
      // if(fields.branch_id
      //   && typeof fields.branch_id.id !== "undefined"
      //   && fields.branch_id.id === 0
      // ){
      //   validators['branch_name'] = 'required|max:100||School/College/Org Branch Name';
      // }
      //Class
      // if(fields.class_id
      //   && typeof fields.class_id.id !== "undefined"
      //   && fields.class_id.id === 0
      // ){
      //   validators['class_name'] = 'required|max:100||Grade/Class/Course Name';
      // }else{
      //   validators['class_id'] = 'required||Grade/Class/Course Name';
      // }
      //Loop through validator data
      var validCheck = {};
      var validation = [];
      for (var keyValid of Object.keys(validators)) {
        // console.log(key + " -> " + p[key])
        var validationCheck = ValidationHelper.validation(fields, {[keyValid]: validators[keyValid]}).error;
        validCheck[keyValid] = validationCheck.length ? false : true;
        if(validationCheck.length){
          validation.push(validationCheck[0]);
        }
      }
      // var validation = ValidationHelper.validation(fields, validators).error;
      // console.log(ValidationHelper.validation(fields, validators))
      //DOB
      if(validation.length === 0){
        if(fields.dob
        ){
          const yearDiff = moment().diff(fields.dob, 'years',false);
          if((fields.dob < '1924-01-01' || yearDiff < 5)){
            validation.push('Please check and re-enter DOB (Date of Birth)');
            validCheck.dob = false;
          }
        }
        // else if(fields.school_id
        //   && typeof fields.school_id.id !== "undefined"
        //   && fields.school_id.id === 0
        //   && (fields.school_name.toLowerCase() === 'other'
        //     || fields.school_name.toLowerCase() === 'others'
        //     || fields.school_name === 'NA'
        //     || fields.school_name === 'na'
        //   )
        // ){
        //   validation.push('School/College/Organisation is Invalid');
        //   validCheck.school_name = false;
        // }else if(fields.class_id
        //   && typeof fields.class_id.id !== "undefined"
        //   && fields.class_id.id === 0
        //   && (fields.class_name.toLowerCase() === 'other'
        //     || fields.class_name.toLowerCase() === 'others'
        //     || fields.class_name === 'NA'
        //     || fields.class_name === 'na'
        //   )
        // ){
        //   validation.push('Grade/Class/Course Name is Invalid');
        //   validCheck.class_name = false;
        // }
      }

      setValidCheck({...validCheckData, ...validCheck});
      // console.log(validation, 'validation')
      // setSubmitDisabled(validation.length ? true : false);
      // return validation.length ? false : true;
      return validation;
    }catch(e){
      error_api_call({error: `auth/register|_validate|${JSON.stringify(inputFields)}|${e}`});
    }
  };
  //Login-Register User
  const _loginRegister = async(e) => {
    //Validation:Already done
    //Show Loader
    try{
      e.preventDefault();
      const validCheck = _validate();
      if(validCheck.length){
        setErrorData([validCheck[0]]);
        return;
      }
      var formData = {...inputFields};
      formData.country_id = formData.country_id.id;
      formData.phone_code_id = formData.phone_code_id.id;
      formData.mobile = parseInt(formData.mobile);
      formData.institute_type_id = formData.institute_type_id.id;
      if(formData.state_id){
        formData.state_id = formData.state_id.id;
      }
      if(formData.branch_id){
        formData.branch_id = formData.branch_id.id;
      }
      if(formData.school_id){
        formData.school_id = formData.school_id.id;
      }
      if(formData.class_id){
        formData.class_id = formData.class_id.id;
      }
      setIsRegProcess(true);
      setErrorData([]);
      dispatch(login_register('register', formData, cb_login => {
        // console.log(cb_login, 'cb_login')
        // if (isMounted.current) {
          //Login-Register
          if(cb_login.status === 200){
            alertResponseMessage({alert_type: 1, message: "Registered Successfully"});
              // window.location.href = redirectUri ? `${redirectUri}` : "/port";
            // navigate('/pay', { state: { redirect_uri: redirectUri } });
            window.location.href = '/pay';
            if(cb_login.data.is_verified === 1
              || cb_login.data.is_verified === 3
            ){
              // window.location.href = redirectUri ? `/${redirectUri}` : "/";
            }else{
              // window.location.href = redirectUri ? `/verification?redirect_uri=${redirectUri}` : '/verification';
              // setCurrentStep(4);//Verification
              // window.location.href = 'port';
            }
          }else{
            setErrorData([cb_login.msg]);
          }
          setIsRegProcess(false);   
        // }
      }));
    }catch(e){
      console.log(e)
      //   setErrorData('Error Logging In/Registering! Please Try Again');
      setErrorData(['Error Registering In! Please Try Again']);
      setIsRegProcess(false);
      error_api_call({error: `auth/register|_loginRegister|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Apply Referral
  const _applyReferral = async(e) => {
    try{
      e.preventDefault();
      const formData = {...inputFields};
      setRefProcData({...refProcData, process: true});
      setErrRefData('');
      const refApiData = await post_api_call(`referral-check`, {referral_code: formData.referral_code});
      // console.log(refApiData, 'refApiData')
      if(refApiData.status === 200){
        setRefProcData({...refProcData,
                      process: false,
                      success: true,
                      message: 'Referral/Invite Code applied Successfully! You will get 1 Month free access.'
                    });
      }else{
        setRefProcData({...refProcData, process: false});
        setErrRefData(typeof refApiData.message !== "undefined"
                      ? refApiData.message
                      : 'Error Applying Referral Code! Please Try Again');
      }
    }catch(e){
      console.log(e)
      setErrRefData('Error Applying Referral Code! Please Try Again');
      setRefProcData({...refProcData, process: false});
      error_api_call({error: `auth/register|_applyReferral|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Remove Referral
  const _removeReferral = async(e) => {
    try{
      e.preventDefault();
      var formData = {...inputFields};
      formData.referral_code = '';
      setFields({...formData});
      setRefProcData({...initRefProcData});
      setErrRefData('');
    }catch(e){
      console.log(e)
      error_api_call({error: `auth/register|_removeReferral|${JSON.stringify(inputFields)}|${e}`});
    }
  }

  return (
    <main id="main">
    <div className="c-app c-default-layout align-items-center custom-form login-form">
      <Container style={{minHeight: '66vh'}}>
        <Row className="justify-content-center pt-5" style={{margin: '55px 0px'}}>
          <Col md="8" lg="5">
            <Row>
            <Col md="12" lg="12">
            <p className="float-end">
                Share:&nbsp;
                <ShareIconsComp
                    title={`Ready to unlock bigger opportunities? IFSPD Europe's PORT transforms your achievements and certificates into a captivating digital portfolio—while EVA expertly guides your future—career choices, college applications and personal growth.`}
                    link={`${window.location.hostname}/register`}
                    subject={`Unlock bigger Opportunities: Transforms your achievements and certificates into a digital portfolio`}
                    // showAsItIs={true}
                />
            </p>
            </Col>
            <Col md="12" lg="12">
            <CardGroup className="team-detail">
              <Card className="p-sm-2">
                <Card.Body>
                <form name="register" id="register">
                    <div className="section-header section-header-1">
                        <h1 className="mb-4 title">Register</h1>
                    </div>
                    {/* <p className="text-muted text-center">Sign In to your account</p> */}
                    <p className="mb-3 text-center fw-bold" style={{fontSize: 18}}>Register now to access IFSPD Europe Academy—your gateway to global opportunities, personalised growth, and a standout portfolio that helps you achieve your academic and career goals!
                      {/* <br/><span className="color-blue">Free for 14 Days. Start now!</span> */}
                    </p>
                    <>
                      <p className="float-end mb-1"><em>*</em> Indicates required info</p>
                      {/* First Name */}
                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="fa fa-user"></i>&nbsp;<em>*</em></InputGroup.Text>
                        <FloatingLabel
                          controlId="floatingFirst"
                          label="First Name"
                        >
                          <Form.Control 
                            type={"text"}
                            placeholder="First Name*"
                            autoComplete="first-name" 
                            value={inputFields.first_name}
                            onChange={(event) => _onChangeText('first_name', event.target.value)}
                            maxLength={50}
                            readOnly={isRegProcess ? true : false}
                            className={!validCheckData.first_name ? 'invalid-inp' : ''}
                          />
                        </FloatingLabel>
                      </InputGroup>
                      {/* <p className="input-info">Space Not Allowed,minimum 2 Characters</p> */}
                      {/* Last Name */}
                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="fa fa-user"></i>&nbsp;&nbsp;</InputGroup.Text>
                        <FloatingLabel
                          controlId="floatingLast"
                          label="Last Name"
                        >
                          <Form.Control 
                            type={"text"}
                            placeholder="Last Name"
                            autoComplete="last-name" 
                            value={inputFields.last_name}
                            onChange={(event) => _onChangeText('last_name', event.target.value)}
                            maxLength={50}
                            readOnly={isRegProcess ? true : false}
                            className={!validCheckData.last_name ? 'invalid-inp' : ''}
                          />
                        </FloatingLabel>
                      </InputGroup>
                      {/* <p className="input-info">Minimum 2 Characters</p> */}
                      {/* Date of Birth: */}
                      <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-calendar"></i>&nbsp;<em>*</em></InputGroup.Text>
                        <FloatingLabel
                          controlId="floatingDob"
                          label="DOB (Date of Birth)"
                        >
                          <Form.Control 
                              type={"date"}
                              placeholder="Date of Birth*"
                              autoComplete="dob-name" 
                              value={inputFields.dob}
                              onChange={(event) => _onChangeText('dob', event.target.value)}
                              // maxLength={30}
                              max={todayDateForm(5)}
                              readOnly={isRegProcess ? true : false}
                              className={!validCheckData.dob ? 'invalid-inp' : ''}
                          />
                        </FloatingLabel>
                      </InputGroup>
                      {/* <p className="input-info">Enter Date of Birth (DOB)</p> */}
                      </div>
                      {/* Email */}
                      <div  className="mb-3">
                      <InputGroup>
                          <InputGroup.Text><i className="fa fa-envelope"></i>&nbsp;<em>*</em></InputGroup.Text>
                          <FloatingLabel
                            controlId="floatingEmail"
                            label="Email address"
                          >
                            <Form.Control 
                              type="email" 
                              placeholder="Email address*" 
                              autoComplete="user_name" 
                              value={inputFields.email}
                              onChange={(event) => _onChangeText('email', event.target.value)}
                              maxLength={150}
                              //   readOnly={currentStep !== 1 || isRegProcess ? true : false}
                              readOnly={isRegProcess ? true : false}
                              className={!validCheckData.email ? 'invalid-inp' : ''}
                            />
                          </FloatingLabel>
                      </InputGroup>
                      <p className="input-info">For all access and log in</p>
                      </div>
                      {/* Mobile Number */}
                      <div  className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-whatsapp"></i>&nbsp;<em>*</em></InputGroup.Text>
                        <div className="w-110p">
                        <Select
                            // isDisabled={true}
                            options={countryData}
                            components={{
                              ValueContainer: CustomValueContainer
                            }}
                            // defaultValue={inputFields.phone_code_id}
                            value={inputFields.phone_code_id}
                            placeholder={"Code"}
                            onChange={(value) => _onChangeText('phone_code_id', value, 'select')}
                            getOptionLabel ={(option) => `+${option.phone_code}`}
                            getOptionValue ={(option) => option.id}
                            // styles={RSNoRadiusStyle}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            // classNamePrefix="reactCSel"
                            styles={!validCheckData.phone_code_id ? RSNoRadiusErrStyle : RSNoRadiusStyle}
                        />
                        </div>
                        <FloatingLabel
                            controlId="floatingMobile"
                            label="WhatsApp number"
                            className="inp-whatsapp"
                          >
                        <Form.Control 
                          type="text" 
                          placeholder="WhatsApp number*" 
                          autoComplete="whatsapp-number" 
                          value={inputFields.mobile}
                          onChange={(event) => _onChangeText('mobile', event.target.value)}
                          maxLength={16}
                          readOnly={isRegProcess ? true : false}
                          // readOnly={currentStep !== 1 ? true : false}
                          className={!validCheckData.mobile ? 'invalid-inp' : ''}
                        />
                        </FloatingLabel>
                      </InputGroup>
                      <p className="input-info">All reports will be sent here</p>
                      </div>
                      {/* Password */}
                      <div className="mb-3">
                        <InputGroup>
                          <InputGroup.Text><i className="fa fa-lock"></i>&nbsp;<em>*</em></InputGroup.Text>
                          <FloatingLabel
                            controlId="floatingPass"
                            label="Password"
                            className={inputFields.password ? "inp-password" : ""}
                          >
                            <Form.Control 
                              type={securePassword ? "password" : "text"}
                              placeholder="Password*" 
                              autoComplete="current-password" 
                              value={inputFields.password}
                              onChange={(event) => _onChangeText('password', event.target.value)}
                              maxLength={15}
                              readOnly={isRegProcess ? true : false}
                              className={!validCheckData.password ? 'invalid-inp' : ''}
                            />
                          </FloatingLabel>
                          {inputFields.password !== "" &&
                            <i className={`fa icon-eye ms-3 ${securePassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHidePass()}></i>
                          }
                        </InputGroup>
                        <p className="input-info">Minimum 8 Characters, Maximum 15 Characters</p>
                      </div>
                      {/* Country */}
                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="fa fa-globe-asia"></i>&nbsp;<em>*</em></InputGroup.Text>
                        <div className="select-cont">
                        <Select
                            // isDisabled={true}
                            placeholder={"Country"}
                            options={countryData} 
                            // defaultValue={inputFields.country_id}
                            value={inputFields.country_id}
                            onChange={(value) => _onChangeText('country_id', value, 'select')}
                            getOptionLabel ={(option) => option.name}
                            getOptionValue ={(option) => option.id}
                            // styles={RSInputStyle}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            components={{
                              ValueContainer: CustomValueContainer
                            }}
                            styles={!validCheckData.country_id ? RSRegErrStyle : RSRegisterStyle}
                            // className="is-valid"
                        />
                        </div>
                      </InputGroup>
                      {/* State */}
                      {inputFields.country_id
                        && inputFields.country_id.id === 101
                        && (
                          <InputGroup className="mb-3">
                            <InputGroup.Text><i className="fa fa-map-marked-alt"></i>&nbsp;<em>*</em></InputGroup.Text>
                            <div className="select-cont">
                            <Select
                                // isDisabled={true}
                                options={stateData} 
                                value={inputFields.state_id}
                                placeholder={"State"}
                                onChange={(value) => _onChangeText('state_id', value, 'select')}
                                getOptionLabel ={(option) => option.state}
                                getOptionValue ={(option) => option.id}
                                // styles={RSInputStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                                components={{
                                  ValueContainer: CustomValueContainer
                                }}
                                styles={!validCheckData.state_id ? RSRegErrStyle : RSRegisterStyle}
                                // styles={RSRegisterStyle}
                                // menuPortalTarget={document.querySelector('body')}
                                // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                // className="is-valid"
                            />
                            </div>
                          </InputGroup>
                        )}
                      {/* Your City/Town:  */}
                        <InputGroup className="mb-3">
                            <InputGroup.Text><i className="fa fa-map-marker"></i>&nbsp;<em>*</em></InputGroup.Text>
                            <FloatingLabel
                              controlId="floatingCity"
                              label="City/Town"
                            >
                              <Form.Control 
                                type={"text"}
                                placeholder="City/Town*"
                                autoComplete="city-name" 
                                value={inputFields.city_name}
                                onChange={(event) => _onChangeText('city_name', event.target.value)}
                                maxLength={100}
                                readOnly={isRegProcess ? true : false}
                                className={!validCheckData.city_name ? 'invalid-inp' : ''}
                              />
                            </FloatingLabel>
                        </InputGroup>
                      {/* Institute Type */}
                      <InputGroup className="mb-3">
                        <InputGroup.Text><i className="fa fa-building"></i>&nbsp;<em>*</em></InputGroup.Text>
                        <div className="select-cont">
                        <Select
                            // isDisabled={true}
                            options={instituteType} 
                            value={inputFields.institute_type_id}
                            placeholder={"Institution Type"}
                            onChange={(value) => _onChangeText('institute_type_id', value, 'select')}
                            getOptionLabel ={(option) => option.type}
                            getOptionValue ={(option) => option.id}
                            // styles={RSInputStyle}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            components={{
                              ValueContainer: CustomValueContainer
                            }}
                            styles={!validCheckData.institute_type_id ? RSRegErrStyle : RSRegisterStyle}
                            // styles={RSRegisterStyle}
                            // menuPortalTarget={document.querySelector('body')}
                            // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                            // className="is-valid"
                        />
                        </div>
                      </InputGroup>
                      {inputFields.institute_type_id
                        && inputFields.institute_type_id.id === 1
                        && inputFields.country_id
                        && inputFields.country_id.id === 101
                        && (
                          <>
                          {/* // Your School/College/Organisation:  */}
                          <InputGroup className="mb-3">
                            <InputGroup.Text><i className="fa fa-building"></i>&nbsp;<em>*</em></InputGroup.Text>
                            <div className="select-cont">
                            <Select
                                // isDisabled={true}
                                options={schoolData} 
                                value={inputFields.school_id}
                                placeholder={"School"}
                                onChange={(value) => _onChangeText('school_id', value, 'select')}
                                getOptionLabel ={(option) => option.school_name}
                                getOptionValue ={(option) => option.id}
                                // styles={RSInputStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                                components={{
                                  ValueContainer: CustomValueContainer
                                }}
                                styles={!validCheckData.school_id ? RSRegErrStyle : RSRegisterStyle}
                                // styles={RSRegisterStyle}
                                // menuPortalTarget={document.querySelector('body')}
                                // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                // className="is-valid"
                            />
                            </div>
                          </InputGroup>
                          {/* Branch */}
                          {branchData.length > 0 && (
                          <div  className="mb-3">
                          <InputGroup>
                            <InputGroup.Text><i className="fa fa-bold"></i>&nbsp;&nbsp;</InputGroup.Text>
                            <div className="select-cont">
                            <Select
                                // isDisabled={true}
                                options={branchData} 
                                value={inputFields.branch_id}
                                // placeholder={"School/College/Org Branch"}
                                placeholder={`${inputFields.institute_type_id.id === 1 ? 'School' : 'College'} Branch. Select N/A, if not applicable.`}
                                onChange={(value) => _onChangeText('branch_id', value, 'select')}
                                getOptionLabel ={(option) => option.branch_name}
                                getOptionValue ={(option) => option.id}
                                // styles={RSInputStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                                components={{
                                  ValueContainer: CustomValueContainer
                                }}
                                styles={!validCheckData.branch_id ? RSRegErrStyle : RSRegisterStyle}
                                // styles={RSRegisterStyle}
                                // menuPortalTarget={document.querySelector('body')}
                                // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                // className="is-valid"
                            />
                            </div>
                          </InputGroup>
                          {/* <p className="input-info">Mention NA if not applicable</p> */}
                          </div>
                          )}
                        {/* {inputFields.branch_id
                          && inputFields.branch_id.id === 0
                          && (
                            <InputGroup className="mb-3">
                                <InputGroup.Text><i className="fa fa-bold"></i>&nbsp;&nbsp;</InputGroup.Text>
                                <FloatingLabel
                                  controlId="floatingBranch"
                                  label="School/College/Org Branch Name"
                                >
                                  <Form.Control 
                                    type={"text"}
                                    placeholder="School/College/Org Branch Name"
                                    autoComplete="branch-name" 
                                    value={inputFields.branch_name}
                                    onChange={(event) => _onChangeText('branch_name', event.target.value)}
                                    maxLength={100}
                                    readOnly={isRegProcess ? true : false}
                                    className={!validCheckData.branch_name ? 'invalid-inp' : ''}
                                  />
                                </FloatingLabel>
                            </InputGroup>
                          )} */}
                          </>
                      )}
                      {/* {inputFields.school_id
                        && inputFields.school_id.id === 0
                        && (
                          <InputGroup className="mb-3">
                              <InputGroup.Text><i className="fa fa-building"></i>&nbsp;<em>*</em></InputGroup.Text>
                              <FloatingLabel
                                controlId="floatingSchool"
                                label="School/College/Organisation Name"
                              >
                                <Form.Control 
                                  type={"text"}
                                  placeholder="School/College/Organisation Name*"
                                  autoComplete="school-name" 
                                  value={inputFields.school_name}
                                  onChange={(event) => _onChangeText('school_name', event.target.value)}
                                  maxLength={100}
                                  readOnly={isRegProcess ? true : false}
                                  className={!validCheckData.school_name ? 'invalid-inp' : ''}
                                />
                              </FloatingLabel>
                          </InputGroup>
                        )} */}
                      {inputFields.institute_type_id
                        && (inputFields.institute_type_id.id === 1
                          || inputFields.institute_type_id.id === 2)
                      && (
                        <>
                        {/* Grade / Class / Course: */}
                        <InputGroup className="mb-3">
                          <InputGroup.Text><i className="fa fa-black-tie"></i>&nbsp;<em>*</em></InputGroup.Text>
                          <div className="select-cont">
                          <Select
                              // isDisabled={true}
                              options={inputFields.institute_type_id.id === 1 ? classData : courseData} 
                              value={inputFields.class_id}
                              placeholder={inputFields.institute_type_id
                                            ? inputFields.institute_type_id.id === 1
                                              ? 'Class'
                                              : 'Course'
                                            : `Class/Course`
                                          }
                                          // `Grade/Class/Course`
                              onChange={(value) => _onChangeText('class_id', value, 'select')}
                              getOptionLabel ={(option) => option.class_name}
                              getOptionValue ={(option) => option.id}
                              // styles={RSInputStyle}
                              menuPlacement="auto"
                              menuPosition={'fixed'}
                              components={{
                                ValueContainer: CustomValueContainer
                              }}
                              styles={!validCheckData.class_id ? RSRegErrStyle : RSRegisterStyle}
                              // styles={RSRegisterStyle}
                              // menuPortalTarget={document.querySelector('body')}
                              // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                              // className="is-valid"
                          />
                          </div>
                        </InputGroup>
                        </>
                      )}
                      {/* {inputFields.class_id
                        && inputFields.class_id.id === 0
                        && (
                        <InputGroup className="mb-3">
                            <InputGroup.Text><i className="fa fa-black-tie"></i>&nbsp;<em>*</em></InputGroup.Text>
                            <FloatingLabel
                              controlId="floatingClass"
                              label="Grade/Class/Course Name"
                            >
                              <Form.Control 
                                type={"text"}
                                placeholder="Grade/Class/Course Name"
                                autoComplete="class-name" 
                                value={inputFields.class_name}
                                onChange={(event) => _onChangeText('class_name', event.target.value)}
                                maxLength={100}
                                readOnly={isRegProcess ? true : false}
                                className={!validCheckData.class_name ? 'invalid-inp' : ''}
                              />
                            </FloatingLabel>
                        </InputGroup>
                      )} */}
                      {/* Referral Code */}
                      <div className="referral-inp mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-registered"></i>&nbsp;&nbsp;</InputGroup.Text>
                        <FloatingLabel
                          controlId="floatingRef"
                          label="Referral/Invite Code (Optional)"
                          className={refProcData.process ? 'inp-referral' : 'inp-password'}
                        >
                          <Form.Control 
                            type={"text"}
                            placeholder="Referral/Invite Code (Optional)"
                            autoComplete="class-name" 
                            value={inputFields.referral_code}
                            onChange={(event) => _onChangeText('referral_code', event.target.value)}
                            maxLength={10}
                            readOnly={isRegProcess || refProcData.success ? true : false}
                            className={!validCheckData.referral_code ? 'invalid-inp' : ''}
                          />
                        </FloatingLabel>
                        {refProcData.process ? 
                          <button
                            type="button"
                            disabled={true}
                            className="btn btn-outline-primary"
                            // color={'primary'}
                          >
                            {/* <SpinnerRoundFilled color="#fff" size={20} /> */}
                            <LoaderMedium size={20} />&nbsp;Applying...
                          </button>
                          : 
                            (inputFields.referral_code && refProcData.success)
                            ?
                            <button
                              type="button"
                              // disabled={submitDisabled}
                              onClick={(e) => _removeReferral(e)}
                              // color={"primary"}
                              className="btn btn-outline-primary"
                            >Remove</button>
                            :
                            <button
                              type="button"
                              disabled={inputFields.referral_code ? false : true}
                              onClick={(e) => _applyReferral(e)}
                              // color={"outline-primary"}
                              className="btn btn-outline-primary"
                            >Apply</button>
                        }
                      </InputGroup>
                      {errRefData 
                      ?
                        <p className="mb-3 float-end color-red">{errRefData}</p>
                      : refProcData.message
                        ?
                          <p className="mb-3 float-end text-green">{refProcData.message}</p>
                        : <></>
                      }
                      </div>
                      {/* Newsletter */}
                      <div className="mb-3 d-flex float-start">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`is_sub`}
                          checked={inputFields.email_preference}
                          onChange={(e) => _onChangeText('email_preference',  e.target.checked, 'checkbox')}
                        />
                        <label className="form-check-label" htmlFor={`is_sub`}>&nbsp;I agree to receive news on opportunities like fellowships, scholarships certifications, internships etc</label>
                      </div>
                    </>
                    
                    <Row className='mt-5'>
                      {errorData.length > 0 &&
                        (<Col xs="12">
                            <div className="alert alert-danger" role="alert">
                            <p className="m-1">{errorData[0]}</p>
                              {/* <p className="m-1">Please enter/modify following Data:</p> */}
                              {/* <ol>
                              {errorData.map((error, error_index) => (
                                  <li key={`error_${error_index}`}>{error}</li>
                              ))}
                              </ol> */}
                            </div>
                        </Col>)
                        }
                      <Col xs="12" className="text-center">
                        {isRegProcess ? 
                          <Button
                            title="Submit"
                            disabled={true}
                            color={'primary'}
                          >
                            {/* <SpinnerRoundFilled color="#fff" size={20} /> */}
                            <LoaderMedium size={20} />&nbsp;Registering...
                          </Button>
                          : 
                          <Button
                            type="submit"
                            // disabled={submitDisabled}
                            onClick={(e) => _loginRegister(e)}
                            color={"primary"}
                            className="px-4 bg-color-blue"
                          >Register</Button>
                          // Start 14 Days Free Trial
                        }
                      </Col>
                        {/* <Col xs="12" className="text-right">
                          <p className="link">
                            <button
                              className='btn p-0 link-custom'
                              onClick={(e) => _forgotPassword(e)}
                              disabled={false}
                            >Forgot your password?</button>
                          </p>
                        </Col> */}
                      <Col xs="12" className="text-right">
                        <p className="link mb-0">
                          By Registering, I agree to the <a href="/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a> and <a href="/terms-and-conditions" target="_blank" rel="noreferrer">terms and conditions</a>
                        </p>
                      </Col>
                      <Col xs="12" className="text-center">
                        <Link to={`/login${redirectUri ? `?redirect_uri=${redirectUri}` : ''}`}>
                            <p className="link link-highlight">Already have account? Login, click here</p>
                        </Link>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </CardGroup>
            </Col>
            </Row>
          </Col>
        </Row>
        {/* <div className="card"> */}
            {/* <div className="row payment-plan">
            <div className="col-md-4 col-12">
                <div className="card text-center p-3">
                    <h6><b>Port</b></h6>
                    <div className="content">
                    <p className="text-black">Create a finely curated student portfolio - tracking achievements, showcasing growth and differentiating from the crowd.</p><br/>
                    <p><i>Free Competency & Career Mapping worth $100</i></p>
                    <p className="color-red">Subsidized India Fee:</p>
                    <p>12 months for Rs.1980</p>
                    </div>
                    <button className="btn btn-primary bg-color-blue">Subscribe Now</button>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="card text-center p-3">
                    <h6><b>Social Start-up</b></h6>
                    <div className="content">
                    <p className="text-black">Enabling students to gain real world start-up skills, learn leadership and team working, create social impact and boost college applications.</p><br/><br/>
                    <p><i></i></p>
                    <p className="color-red">Subsidized India Fee:</p>
                    <p>12 months for Rs.4500</p>
                    </div>
                    <button className="btn btn-primary bg-color-blue">Subscribe Now</button>
                </div>
            </div>
            <div className="col-md-4 col-12">
                <div className="card text-center p-3">
                    <h6><b>Social Start-up + Port </b></h6>
                    <div className="content">
                    <p className="text-black">Gaining real world start-up skills & social impact, while keeping track of achievements; enabling an elevated profile.</p>
                    <p><i>Free Competency & Career Mapping worth $100</i></p>
                    <p className="color-red">Subsidized India Fee:</p>
                    <p>12 months for Rs.4950</p>
                    </div>
                    <button className="btn btn-primary bg-color-blue">Subscribe Now</button>
                </div>
            </div>
            </div> */}
        {/* </div> */}
      </Container>
    </div>
    </main>
  )
}

export default RegisterPage
